import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'

export const meetingByMeetingDate = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.MEETING_BY_MEETING_DATE_REQUEST,
	});

	await Axios(
		"post",
		end_points.meeting_by_meeting_date,
		payload,
		"application/json",
		true
	)
	.then((res) => {
		console.log(`${end_points.meeting_by_meeting_date} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.MEETING_BY_MEETING_DATE_SUCCESS,
				payload: res?.data,
			});
		}
	})
	.catch((error) => {
		console.log(`${end_points.meeting_by_meeting_date} Error response:`, error?.response?.data);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.MEETING_BY_MEETING_DATE_FAILED,
				payload: error?.response?.data,
			});
		}
	})
};

export const meetingByMeetingDateReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.MEETING_BY_MEETING_DATE_RESET,
	});
};

export const meetingByMeetingDateResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.MEETING_BY_MEETING_DATE_RESET_ALL,
	});
};
