import React from 'react'
import { getMeetingDaysWithAllTimeSlot, getMeetingDaysWithAllTimeSlotReset } from '../../store/actions/getMeetingDaysWithAllTimeSlotActions';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useState } from 'react';
import moment from 'moment';
import { meetingDay, meetingDayReset } from '../../store/actions/meetingDayActions';
import { toast } from 'react-toastify';
import FinalizeTimeSelectionView from '../../components/main/TimeSelectionView/FinalizeTimeSelectionView';
import FinalizeMeetModal from '../../components/main/CancelMeetingMeetModal/CancelMeetingMeetModal';
import FinalizeMeetingTimeModal from '../../components/main/FinalizeMeetingTimeModal/FinalizeMeetingTimeModal';

function FinalizeTimeScreen({
    meetingDay,
    meetingDayReset,
    meetingDayReducers,
    getMeetingDaysWithAllTimeSlot,
    getMeetingDaysWithAllTimeSlotReset,
    getMeetingDaysWithAllTimeSlotReducers,
}) {
    const { meetingId } = useParams();
    
    const [dates, setDates] = useState([])
    const [datesData, setDatesData] = useState([])
    const [selectedDate, setSelectedDate] = useState(dates[0]);
    const [dateIndex, setDateIndex] = useState(1);
    const [availableInvitee, setAvailableInvitee] = useState([]);
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [finalTimeData, setFinalTimeData] = useState({}) ;
    const [finalTimeDataForModal, setFinalTimeDataForModal] = useState({}) ;

    const [curr, changeCurr] = useState({
		cells: [
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
		],
	});
    const [currCreate, changeCurrCreate] = useState({
		cells: [
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
		],
	});
    const [user, changeUser] = useState({
        cells: [
            [false],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
            [''],
        ],
    });

    let hours = [
		'00:15:00',
		'00:30:00',
		'00:45:00',
		'01:00:00',
		'01:15:00',
		'01:30:00',
		'01:45:00',
		'02:00:00',
		'02:15:00',
		'02:30:00',
		'02:45:00',
		'03:00:00',
		'03:15:00',
		'03:30:00',
		'03:45:00',
		'04:00:00',
		'04:15:00',
		'04:30:00',
		'04:45:00',
		'05:00:00',
		'05:15:00',
		'05:30:00',
		'05:45:00',
		'06:00:00',
		'06:15:00',
		'06:30:00',
		'06:45:00',
		'07:00:00',
		'07:15:00',
		'07:30:00',
		'07:45:00',
		'08:00:00',
		'08:15:00',
		'08:30:00',
		'08:45:00',
		'09:00:00',
		'09:15:00',
		'09:30:00',
		'09:45:00',
		'10:00:00',
		'10:15:00',
		'10:30:00',
		'10:45:00',
		'11:00:00',
		'11:15:00',
		'11:30:00',
		'11:45:00',
		'12:00:00',
		'12:15:00',
		'12:30:00',
		'12:45:00',
		'13:00:00',
		'13:15:00',
		'13:30:00',
		'13:45:00',
		'14:00:00',
		'14:15:00',
		'14:30:00',
		'14:45:00',
		'15:00:00',
		'15:15:00',
		'15:30:00',
		'15:45:00',
		'16:00:00',
		'16:15:00',
		'16:30:00',
		'16:45:00',
		'17:00:00',
		'17:15:00',
		'17:30:00',
		'17:45:00',
		'18:00:00',
		'18:15:00',
		'18:30:00',
		'18:45:00',
		'19:00:00',
		'19:15:00',
		'19:30:00',
		'19:45:00',
		'20:00:00',
		'20:15:00',
		'20:30:00',
		'20:45:00',
		'21:00:00',
		'21:15:00',
		'21:30:00',
		'21:45:00',
		'22:00:00',
		'22:15:00',
		'22:30:00',
		'22:45:00',
		'23:00:00',
		'23:15:00',
		'23:30:00',
		'23:45:00',
	];
    let hoursAlt = [
		'false',
		'00:00:00',
		'00:15:00',
		'00:30:00',
		'00:45:00',
		'01:00:00',
		'01:15:00',
		'01:30:00',
		'01:45:00',
		'02:00:00',
		'02:15:00',
		'02:30:00',
		'02:45:00',
		'03:00:00',
		'03:15:00',
		'03:30:00',
		'03:45:00',
		'04:00:00',
		'04:15:00',
		'04:30:00',
		'04:45:00',
		'05:00:00',
		'05:15:00',
		'05:30:00',
		'05:45:00',
		'06:00:00',
		'06:15:00',
		'06:30:00',
		'06:45:00',
		'07:00:00',
		'07:15:00',
		'07:30:00',
		'07:45:00',
		'08:00:00',
		'08:15:00',
		'08:30:00',
		'08:45:00',
		'09:00:00',
		'09:15:00',
		'09:30:00',
		'09:45:00',
		'10:00:00',
		'10:15:00',
		'10:30:00',
		'10:45:00',
		'11:00:00',
		'11:15:00',
		'11:30:00',
		'11:45:00',
		'12:00:00',
		'12:15:00',
		'12:30:00',
		'12:45:00',
		'13:00:00',
		'13:15:00',
		'13:30:00',
		'13:45:00',
		'14:00:00',
		'14:15:00',
		'14:30:00',
		'14:45:00',
		'15:00:00',
		'15:15:00',
		'15:30:00',
		'15:45:00',
		'16:00:00',
		'16:15:00',
		'16:30:00',
		'16:45:00',
		'17:00:00',
		'17:15:00',
		'17:30:00',
		'17:45:00',
		'18:00:00',
		'18:15:00',
		'18:30:00',
		'18:45:00',
		'19:00:00',
		'19:15:00',
		'19:30:00',
		'19:45:00',
		'20:00:00',
		'20:15:00',
		'20:30:00',
		'20:45:00',
		'21:00:00',
		'21:15:00',
		'21:30:00',
		'21:45:00',
		'22:00:00',
		'22:15:00',
		'22:30:00',
		'22:45:00',
		'23:00:00',
		'23:15:00',
		'23:30:00',
		'23:45:00',
	];


	useEffect(() => {
		meetingDay({ meetingId: meetingId});
		getMeetingDaysWithAllTimeSlot({ meetingId: meetingId});
	}, []);

    const fetchStartAndEndIndex = (startTime, endTime, users) =>{
		const startIndex = hoursAlt.indexOf(startTime) ;
		const endIndex = hoursAlt.indexOf(endTime) - 1;
		console.log('startIndex:', startIndex , ' endIndex:', endIndex)

        for(let i = startIndex; i<=endIndex; i++){
			curr.cells[i][0] = true;
			user.cells[i][0] = users;
        }

		//  console.log('final curr after parsing the user selection array:', curr);
		//   console.log('final curr after parsing the user selection array:', user);

		 changeCurr(curr);
		 changeUser(user);
	}


    useEffect(() => {
        if (getMeetingDaysWithAllTimeSlotReducers?.status === 200) {
            let uniqueList = [... new Set(getMeetingDaysWithAllTimeSlotReducers?.data?.map(( item ) => {
                return item?.timeSlotList?.map((timeSlotItem) => {
                    return {
                        users: timeSlotItem.users.split(',')
                    }
                })
            }).flat().filter((item) =>{return item.users[0] !== "null" && item} )?.map((item) => {return item.users})?.flat())]
            setAvailableInvitee(uniqueList.map((str, index) => ({ attendee: str, id: index + 1, color: `rgba(4,134,17,${(1/uniqueList?.length )*(index+1)})` })));
            console.log("uniqueList :", uniqueList);
            if(getMeetingDaysWithAllTimeSlotReducers?.data?.length > 0){
                getMeetingDaysWithAllTimeSlotReducers?.data?.map((item) => {
                    if(item.timeSlotList?.length > 0){
                        item?.timeSlotList?.map((data) => {
                            if(data.users !== "null"){
                                fetchStartAndEndIndex(data.startTime, data.endTime, data.users);
                            }
                        })
                    }
                })
            }
            getMeetingDaysWithAllTimeSlotReset();
        }else if (getMeetingDaysWithAllTimeSlotReducers?.status === 302) {
            toast.show(getMeetingDaysWithAllTimeSlotReducers?.message, { type: 'danger' });
            getMeetingDaysWithAllTimeSlotReset();
        }else if (getMeetingDaysWithAllTimeSlotReducers?.status) {
            toast.show("Status: "+getMeetingDaysWithAllTimeSlotReducers?.status+",\n"+getMeetingDaysWithAllTimeSlotReducers?.message, { type: 'danger' });
            getMeetingDaysWithAllTimeSlotReset();
        }
    }, [getMeetingDaysWithAllTimeSlotReducers]);

    useEffect(() => {
        if (meetingDayReducers.status === 200) {
            setDates(meetingDayReducers?.data?.map((day) => { return day.meetingDate }))
            setDatesData(meetingDayReducers?.data)
            setSelectedDate(meetingDayReducers?.data?.map((day) => { return day.meetingDate })[0])
            meetingDayReset();
        }else if (meetingDayReducers.status === 302) {
            toast.error(meetingDayReducers.message);
            meetingDayReset();
        }else if (meetingDayReducers.status) {
            toast.error("Status: "+meetingDayReducers.status+",\n"+meetingDayReducers.message);
            meetingDayReset();
        }
    }, [meetingDayReducers]);

    const renderFinalTime = () => {
        return (
            <FinalizeTimeSelectionView 
                curr={curr}
                user={user}
                setUser={changeUser}
                currCreate={currCreate}
                changeCurrCreate={changeCurrCreate}
                availableInvitee={availableInvitee}
                setAvailableInvitee={setAvailableInvitee}
                isFinalizingMeet={true}
                setFinalTimeData={setFinalTimeData}
            />
        )
    }

    useEffect(() => {
        if(availableInvitee.length > 0){
            setTimeout(() => {
                renderFinalTime();
            },2000)
        }
    }, [availableInvitee])

    useEffect(() => {
        if(Object.keys(finalTimeData).length !== 0){
            let startTime = hoursAlt[finalTimeData.clickedIndex];
            let endTime = hoursAlt[finalTimeData.clickedIndex+1];

            setFinalTimeDataForModal({
                ...finalTimeData,
                startTime : startTime,
                endTime : endTime,
                finalDate: dates[dateIndex-1]
            })
            setIsPopUpOpen(true)
        }
    }, [finalTimeData])
    
    console.log('====================================');
    console.log("finalTimeDataForModal :", finalTimeDataForModal);
    console.log("dates :", dates);
    console.log('====================================');

    return (
        <div className='h-full main-background'>
            <div className='h-full relative'>
                <div className="pt-28 px-8">
                    <div className="flex justify-between align-center">
                        <div className="font-[InterRegular] text-4xl">
                            Select Available Times
                        </div>
                        <div className="text-lg">
                            2/3
                        </div>
                    </div>
                    <div className="font-[InterRegular] text-lg">
                        Coffee Meet Austin
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center px-4" style={{zIndex: 12, width: '100%', backgroundColor: '#ffffff'}}>
                    {dates?.length > 0 ?
                        dates?.map((date, i) => {
                            return (
                                <div
                                    key={i}
                                    onClick={() => {
                                        console.log("date :", date)
                                        setSelectedDate(date);
                                        setDateIndex(dates.indexOf(date));
                                    }}
                                    className={dates?.length == 1 ? "w-full" :dates?.length == 2 ? "w-[50%]" : "w-[33%]" }
                                >
                                    <div
                                        className={`bg-white py-2 w-full ${i < dates?.length ? "border-[1px] border-white" : ""}`}
                                    >
                                        <div
                                            className={`flex flex-row justify-center items-center relative   mt-2 bg-[#906DFC] py-2 ${i === 0 ? "rounded-l-full" : ""} ${i === dates?.length - 1 ? "rounded-r-full" : ""}`}
                                            style={{
                                                shadowOpacity: 1,
                                                shadowColor: '#000',
                                                // shadowRadius: 24,
                                                // elevation: 6,
                                            }}
                                        >
                                            <p
                                                className="text-white tracking-wider">{moment(date, 'DD/MM/YYYY').format('ddd')}, </p>
                                            <p
                                                className="text-white tracking-wider">{moment(date, 'DD/MM/YYYY').format('MMM DD')}</p>
                                            {selectedDate == date ? <div
                                                className="w-[54px] h-[2px] bg-[#fff] mb-[1px] absolute rounded-full"
                                                style={{bottom: 0,}}></div> : ""}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        null
                    }
                </div>
                <div className='w-[100%] h-[60%] p-5'>
                    {renderFinalTime()}
                </div>
                {isPopUpOpen ?
                    <FinalizeMeetingTimeModal 
                        setIsPopUpOpen={setIsPopUpOpen} 
                        finalTimeData={finalTimeDataForModal}
                        allInvitee={availableInvitee.length}
						meetingId={meetingId}
                    />
                    :
                    null
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        meetingDayReducers: state.meetingDayReducers,
        getMeetingDaysWithAllTimeSlotReducers: state.getMeetingDaysWithAllTimeSlotReducers,
    }
}

export default connect(mapStateToProps, {
    meetingDay,
    meetingDayReset,
    getMeetingDaysWithAllTimeSlot,
    getMeetingDaysWithAllTimeSlotReset,
})(FinalizeTimeScreen)
