import { actionTypes } from '../types';

const initialState = {
    startingLocation: {},
}

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_STARTING_LOCATION:
			return {
                ...state,
				startingLocation: action.payload
            }
		case actionTypes.RESET_STARTING_LOCATION:
			return {
                ...state,
				startingLocation: {}
            }
		default:
			return state;
	}
};

