import { actionTypes } from '../types';

export default (
	state = {
		latitude: 0,
		longitude: 0,
	},
	action,
) => {
	switch (action.type) {
		case actionTypes.REGION_COFFEE_SHOP:
			return action.payload;
		default:
			return state;
	}
};
