import axios from "axios";
import { baseUrl } from "./constant";
import { store } from './configureStore';

store.subscribe(listener)
let token;
function select(state) {
    return state.utilReducer.token;
}
function listener() {
    token = select(store.getState())
}

export const Axios = (method, endPoint, data, ContentType, isAuth) => {
    console.log('====================================');
    console.log("calling API",
        {
            "method": method,
            "url": baseUrl + endPoint,
            "body": data,
            "ContentType": ContentType,
            "isAuth": isAuth,
        }
    );
    console.log('====================================');
    if (method === 'get') {
        return axios({
            method: method,
            url: baseUrl + endPoint,
            headers: isAuth ? 
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `Bearer ${token}`,
                }
                : 
                { 'Content-Type': ContentType ? ContentType : 'application/json', },
        });
    } else if (method === 'delete') {
        return axios({
            method: method,
            url: baseUrl + endPoint,
            headers: isAuth ? 
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `Bearer ${token}`,
                }
                : 
                { 'Content-Type': ContentType ? ContentType : 'application/json', },
        });
    } else if (method === 'post') {
        return axios({
            method: method,
            url: baseUrl + endPoint,
            data: data,
            headers: isAuth ? 
                {
                    'Content-Type': ContentType ? ContentType : 'application/json',
                    Authorization: `Bearer ${token}`,
                }
                : 
                { 'Content-Type': ContentType ? ContentType : 'application/json', },
        });
    }
}