
import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setMultipleTimeSlots, setMultipleTimeSlotsReset } from '../../store/actions/setMultipleTimeSlots';
import { connect } from 'react-redux';
import { meetingDay, meetingDayReset } from '../../store/actions/meetingDayActions';
import { getMeetingDaysWithTimeSlot, getMeetingDaysWithTimeSlotReset, updateOrganiserSelectedTimeSlotArray } from '../../store/actions/getMeetingDaysWithTimeSlotActions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getMeetingDetailsByMeetingHash, getMeetingDetailsByMeetingHashReset } from '../../store/actions/getMeetingDetailsByMeetingHashActions';
import InviteeTimeSelectionView from '../../components/main/TimeSelectionView/InviteeTimeSelectionView';
import { getMeetingDaysWithAllTimeSlot, getMeetingDaysWithAllTimeSlotReset } from '../../store/actions/getMeetingDaysWithAllTimeSlotActions';
import { registerInvitees, registerInviteesReset } from '../../store/actions/registerInviteesActions';
import { ClipLoader } from 'react-spinners';
import { SlLocationPin } from 'react-icons/sl';
import { BiLeftArrowAlt } from "react-icons/bi";
import { setDashboardSelectedDate, setIsBackFromLocationScreen } from '../../store/actions/utilActions';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import AddToCalendarModal from '../../components/main/AddToCalendarModal/AddToCalendarModal';

let dateIndex = 0;

function InviteeTimeSelectionPage({
	utilReducer,
	getMeetingDaysWithTimeSlotReducers,
	getMeetingDaysWithTimeSlot,
	getMeetingDaysWithTimeSlotReset,
	updateOrganiserSelectedTimeSlotArray,
	meetingDay,
	meetingDayReducers,
	setMultipleTimeSlots,
	setMultipleTimeSlotsReset,
	setMultipleTimeSlotsReducers,
	getMeetingDetailsByMeetingHash,
	getMeetingDetailsByMeetingHashReset,
	getMeetingDetailsByMeetingHashReducers,
	getMeetingDaysWithAllTimeSlot,
	getMeetingDaysWithAllTimeSlotReset,
	getMeetingDaysWithAllTimeSlotReducers,
	registerInvitees,
	registerInviteesReset,
	registerInviteesReducers,
	setDashboardSelectedDate,
	setIsBackFromLocationScreen,
}) {
	const navigate = useNavigate();
	const { meetingHash } = useParams();

	const [dates, setDates] = useState([])
	const [datesData, setDatesData] = useState([])
	const [selectedDate, setSelectedDate] = useState(dates[0]);
	const [nextButton, setNextButton] = useState(false);
	const [isRunOnce, setIsRunOnce] = useState(false);
	const [availableInvitee, setAvailableInvitee] = useState([]);
	const [platform, setPlatform] = useState("")
	const [calendarModal, setCalendarModal] = useState(false);



	function getOS() {
		var userAgent = window.navigator.userAgent,
			platform =  window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod'],
			os = null;

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'Ios';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = 'Ios';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = 'WEB';
		} else if (/Android/.test(userAgent)) {
			os = 'Android';
		} else if (/Linux/.test(platform)) {
			os = 'WEB';
		}
		setPlatform(os)
		return os;
	}

	useEffect(() => {
		try {
			if ((getOS()+ "").toLowerCase() != "ios") {
				const link = `ecco-app://meeting/${meetingHash}`;
				const a = document.createElement("a");
				a.setAttribute('href', link);
				a.setAttribute('target', '_self');
				a.click();
			}
		} catch (e) {
			console.log("error in opening url ", e)
		}
	}, [meetingHash])

	const setMultipleTimeSlotsHandler = () => {
		setIsRunOnce(true)
		getMeetingDaysWithTimeSlotReducers?.emptyTimeSlot?.map((item, i) => {
			if (item?.timeSlotList?.length > 0) {
				console.log("setting time slots:", item)
				setMultipleTimeSlots(item);
			}
		})
	}

	/* const handleConfirm = () => {
		let text = `Do You Want To Add Event To Your Calendar`
		if (window.confirm(text) == true) {
			return (
				setCalendarModal(true)
			)
		}
		else {
			return navigate('/dashboard', { replace: true })
		}
	} */

	useEffect(() => {
		if (getMeetingDaysWithAllTimeSlotReducers?.status === 200) {
			let uniqueList = [... new Set(getMeetingDaysWithAllTimeSlotReducers?.data?.map((item) => {
				return item?.timeSlotList?.map((timeSlotItem) => {
					return {
						users: timeSlotItem.users.split(',')
					}
				})
			}).flat().filter((item) => { return item.users[0] !== "null" && item })?.map((item) => { return item.users })?.flat())]
			setAvailableInvitee(uniqueList.map((str, index) => ({ attendee: str, id: index + 1, color: `rgba(4,134,17,${(1 / uniqueList?.length) * (index + 1)})` })));
			console.log("uniqueList :", uniqueList);
			getMeetingDaysWithAllTimeSlotReset();
		} else if (getMeetingDaysWithAllTimeSlotReducers?.status === 302) {
			toast.show(getMeetingDaysWithAllTimeSlotReducers?.message, { type: 'danger' });
			getMeetingDaysWithAllTimeSlotReset();
		} else if (getMeetingDaysWithAllTimeSlotReducers?.status) {
			toast.show("Status: " + getMeetingDaysWithAllTimeSlotReducers?.status + ",\n" + getMeetingDaysWithAllTimeSlotReducers?.message, { type: 'danger' });
			getMeetingDaysWithAllTimeSlotReset();
		}
	}, [getMeetingDaysWithAllTimeSlotReducers]);

	useEffect(() => {
		getMeetingDetailsByMeetingHash({ meetingHash: meetingHash });
		getOS();
	}, []);

	useEffect(() => {
		if (getMeetingDetailsByMeetingHashReducers?.status === 200) {
			if (getMeetingDetailsByMeetingHashReducers?.data?.alreadyJoined && !utilReducer.isBackFromLocationScreen) {
				toast.warning("You have already joined the meeting")
				navigate('/dashboard', { replace: true })
			} else if (getMeetingDetailsByMeetingHashReducers?.data?.status === "CANCELLED") {
				toast.warning("Meeting has been cancelled.")
				navigate('/dashboard', { replace: true })
			} else if (getMeetingDetailsByMeetingHashReducers?.data?.status != "SCHEDULED") {
				meetingDay({ meetingId: getMeetingDetailsByMeetingHashReducers?.data?.meetingId });
				getMeetingDaysWithTimeSlot({ meetingId: getMeetingDetailsByMeetingHashReducers?.data?.meetingId });
				getMeetingDaysWithAllTimeSlot({ meetingId: getMeetingDetailsByMeetingHashReducers?.data?.meetingId });
			}
			getMeetingDetailsByMeetingHashReset();
		} else if (getMeetingDetailsByMeetingHashReducers?.status === 302) {
			toast.error(getMeetingDetailsByMeetingHashReducers?.message)
			navigate('/dashboard', { replace: true })
			getMeetingDetailsByMeetingHashReset();
		} else if (getMeetingDetailsByMeetingHashReducers?.status) {
			getMeetingDetailsByMeetingHashReset();
		}
	}, [getMeetingDetailsByMeetingHashReducers]);

	useEffect(() => {
		if (registerInviteesReducers?.status === 200) {
			toast.success("Meeting joined successfully");
			//added alert
			setCalendarModal(true);
			//add Calendar
			registerInviteesReset();
		} else if (registerInviteesReducers?.status === 302) {
			registerInviteesReset();
		} else if (registerInviteesReducers?.status) {
			registerInviteesReset();
		}
	}, [registerInviteesReducers]);

	useEffect(() => {
		if (meetingDayReducers.status === 200) {
			setDates(meetingDayReducers?.data?.map((day) => { return day.meetingDate }))
			setDatesData(meetingDayReducers?.data)
			setSelectedDate(meetingDayReducers?.data?.map((day) => { return day.meetingDate })[0])
			setDashboardSelectedDate({ date: meetingDayReducers?.data?.map((day) => { return day.meetingDate })[0], time: moment(new Date()) })
			meetingDayReset();
		} else if (meetingDayReducers.status === 302) {
			toast.error(meetingDayReducers.message);
			meetingDayReset();
		} else if (meetingDayReducers.status) {
			toast.error("Status: " + meetingDayReducers.status + ",\n" + meetingDayReducers.message);
			meetingDayReset();
		}
	}, [meetingDayReducers]);

	useEffect(() => {
		if (getMeetingDaysWithTimeSlotReducers.status === 200) {
			getMeetingDaysWithTimeSlotReset();
		} else if (getMeetingDaysWithTimeSlotReducers.status === 302) {
			toast.error(getMeetingDaysWithTimeSlotReducers.message);
			getMeetingDaysWithTimeSlotReset();
		} else if (getMeetingDaysWithTimeSlotReducers.status) {
			toast.error("Status: " + getMeetingDaysWithTimeSlotReducers.status + ",\n" + getMeetingDaysWithTimeSlotReducers.message);
			getMeetingDaysWithTimeSlotReset();
		}
	}, [getMeetingDaysWithTimeSlotReducers]);

	useEffect(() => {
		getMeetingDaysWithTimeSlotReducers?.emptyTimeSlot?.map((item, i) => {
			if (item?.timeSlotList?.length > 0) {
				setNextButton(true)
			}
		})
	}, [getMeetingDaysWithTimeSlotReducers]);

	useEffect(() => {
		if (setMultipleTimeSlotsReducers.status === 302) {
			setIsRunOnce(false)
			toast.error(setMultipleTimeSlotsReducers.message,);
			setMultipleTimeSlotsReset();
		} else if (setMultipleTimeSlotsReducers.status === 201) {
			if (isRunOnce) {
				toast.success(setMultipleTimeSlotsReducers.message);
				navigate('/your-location/' + getMeetingDetailsByMeetingHashReducers?.data?.meetingId + '/invitee')
				setIsRunOnce(false)
			}
			setMultipleTimeSlotsReset();
		} else if (setMultipleTimeSlotsReducers.status) {
			toast.error("Status: " + setMultipleTimeSlotsReducers.status + ",\n" + setMultipleTimeSlotsReducers.message, { type: 'danger' });
			setMultipleTimeSlotsReset();
		}
	}, [setMultipleTimeSlotsReducers]);

	const renderInviteeTime = () => {
		return (
			dates?.map((date, i) => {
				return dateIndex === i && (
					<InviteeTimeSelectionView
						key={dateIndex}
						dateIndex={dateIndex}
						timeSlotListDataAll={getMeetingDaysWithAllTimeSlotReducers?.data[dateIndex]?.timeSlotList}
						timeSlotList={getMeetingDaysWithTimeSlotReducers?.emptyTimeSlot[dateIndex]?.timeSlotList}
						meetingDayId={datesData[dateIndex].meetingDayId}
						availableInvitee={availableInvitee}
					/>
				)
			})
		)
	}

	useEffect(() => {
		setTimeout(() => {
			renderInviteeTime();
		}, 10000);
	}, [getMeetingDaysWithAllTimeSlotReducers?.data[dateIndex]?.timeSlotList, getMeetingDaysWithTimeSlotReducers?.emptyTimeSlot[dateIndex]?.timeSlotList]);

	console.log('====================================');
	console.log("getMeetingDetailsByMeetingHashReducers :", getMeetingDetailsByMeetingHashReducers);
	console.log('====================================');

	return (
		<div className='h-full main-background relative top-0 bottom-0 left-0 right-0'>
			<div className='pt-28 px-8 flex w-full' onClick={() => navigate('/dashboard', { replace: true })}>
				<BiLeftArrowAlt size={24} color='#D74DFF' />
			</div>
			<div className="px-8">
				{getMeetingDetailsByMeetingHashReducers?.data?.status != "SCHEDULED" ?
					<>
						<div className="font-[InterRegular] text-4xl text-[#D74DFF] font-[600]">
							{getMeetingDetailsByMeetingHashReducers?.data?.title}
						</div>
						<div className="flex flex-row justify-start items-center w-full">
							<div className="text-darker-gray uppercase font-semibold tracking-wider my-2">Availability</div>
							<div className="flex flex-row justify-start items-center mx-4">
								<div
									className={`w-[20px] h-[20px] border border-[#DADADA] mx-[1px] flex flex-row justify-center items-center "bg-[#048611]/0`}
									style={{ opacity: 10 }}
								>
									<div className="text-xs font-medium">0</div>
								</div>
								{availableInvitee?.map((invitee, i) => {
									return (
										<div
											key={i}
											className={`w-[20px] h-[20px] border border-[#DADADA] mx-[1px] flex flex-row justify-center items-center`}
											style={{ opacity: 10, backgroundColor: invitee.color }}
										>
											<div className="text-xs font-medium">{i + 1}</div>
										</div>
									)
								})
								}
							</div>
						</div>
						<div className="font-[InterRegular] text-lg text-primary">
							Tap on a block to set your availability
						</div>
					</>
					:
					<div className='font-[600] text-[1.6rem]'>
						Meeting scheduled
					</div>
				}
			</div>
			{/* <div className='mx-4 shadow-[0_12px_24px_0_rgba(106,83,253,0.30)]' style={{
				flex: 1,
				width: '100%',
				shadowColor: '#6A53FD',
				shadowOffset: {width: 0, height: 12},
				shadowOpacity: 0.3,
				shadowRadius: 13,
			}}> */}
			{calendarModal ?
				<AddToCalendarModal
					setIsPopUpOpenCancel={setCalendarModal}
					meetingData={getMeetingDetailsByMeetingHashReducers?.data}
					platform={platform}
				/>
				:
				null
			}
			<div className="flex flex-row justify-center items-center" style={{ zIndex: 12, width: '100%', backgroundColor: '#ffffff' }}>
				{dates?.length > 0 ?
					dates?.map((date, i) => {
						return (
							<div
								key={i}
								onClick={() => { setSelectedDate(date); dateIndex = i; }}
								className={dates?.length == 1 ? "w-full" : dates?.length == 2 ? "w-[50%]" : "w-[33%]"}
							>
								<div className={`bg-white py-2 w-full cursor-pointer ${i < dates?.length ? "border-[1px] border-white" : ""}`}>
									<div className={`flex flex-row justify-center items-center relative mt-2 ${selectedDate == date ? "bg-[#6FCD78]" : "bg-[#906DFC]"} py-2 ${i === 0 ? "rounded-l-full" : ""} ${i === dates?.length - 1 ? "rounded-r-full" : ""}`}>
										<p className={`${selectedDate == date ? "text-[#333333]" : "text-[#FFF]"} tracking-wider`}>{moment(date, 'DD/MM/YYYY').format('ddd MMM DD')}</p>
									</div>
								</div>
							</div>
						)
					})
					:
					null
				}
			</div>
			<div className='w-[100%] h-[60vh] p-5'>
				{!getMeetingDetailsByMeetingHashReducers?.loading ?
					getMeetingDetailsByMeetingHashReducers?.data?.status == "SCHEDULED" ?
						<div className='flex flex-col justify-center items-start px-4'>
							<div className='flex justify-between items-center w-full mb-[.8rem]'>
								<h1 className='text-[2rem] font-[600] text-[#906DFC]'>
									{getMeetingDetailsByMeetingHashReducers?.data?.title}
								</h1>
							</div>
							<div className='flex justify-between items-center w-full mb-[.8rem]'>
								<h1 className='text-[1.2rem] font-[600] '>
									Date
								</h1>
								<h1 className='text-[1.2rem] font-[400] '>
									{getMeetingDetailsByMeetingHashReducers?.data?.finalDate}
								</h1>
							</div>
							<div className='flex justify-between items-center w-full mb-[.8rem]'>
								<h1 className='text-[1.2rem] font-[600] '>
									{moment(getMeetingDetailsByMeetingHashReducers?.data?.startTime, "HH:mm:sss").format("hh:mm A")}
								</h1>
								<h1 className='text-[1.2rem] font-[400] '>
									"to"
								</h1>
								<h1 className='text-[1.2rem] font-[600] '>
									{moment(getMeetingDetailsByMeetingHashReducers?.data?.endTime, "HH:mm:sss").format("hh:mm A")}
								</h1>
							</div>
							<h1 className='text-[1.2rem] font-[600] flex justify-start items-center mb-[.5rem]'>
								<SlLocationPin color='#6842E5' className='mr-2 cursor-pointer'
									onClick={() => {
										window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${getMeetingDetailsByMeetingHashReducers?.data?.latitude},${getMeetingDetailsByMeetingHashReducers?.data?.longitude}`)
									}}
								/>
								{getMeetingDetailsByMeetingHashReducers?.data?.location}
							</h1>
							<h1 className='text-[1rem] font-[400]  mb-[.8rem]'>
								{getMeetingDetailsByMeetingHashReducers?.data?.vicinity}
							</h1>
							<div
								className='text-[1.4rem] text-[#FFFFFF] bg-[#906DFC] rounded-full py-2 px-5 mt-4 cursor-pointer'
								onClick={() => {
									registerInvitees({ "meetingHashCode": meetingHash })
								}}
							>
								Accept Meeting
							</div>
						</div>
						:
						renderInviteeTime()
					:
					<div className='w-full h-full flex justify-center items-center'>
						<ClipLoader size={20} color="#906DFC" />
					</div>
				}
			</div>

			{getMeetingDetailsByMeetingHashReducers?.data?.status != "SCHEDULED" ?
				<div className="flex justify-center items-center pt-[.8rem] pb-[1rem] bg-white absolute bottom-0 right-0 left-0 z-40">
					<PrimaryButton
						backgroundColor={"bg-white border-2 border-[#6A53FD]"}
						textColor={"text-black"}
						title={"Back"}
						onClick={() => { navigate('/dashboard', { replace: true }); setIsBackFromLocationScreen(false) }}
						width={"w-[30%]"}
					/>
					<PrimaryButton
						backgroundColor={"bg-primary"}
						title={"Next"}
						textColor={"text-white"}
						onClick={() => {
							setMultipleTimeSlotsHandler();
							setIsBackFromLocationScreen(false);
						}}
						width={"w-[60%]"}
						isDisabled={!nextButton}
					/>
				</div>
				:
				null
			}
			{/* </div> */}

		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		utilReducer: state.utilReducer,
		createMeetingReducers: state.createMeetingReducers,
		getMeetingDaysWithTimeSlotReducers: state.getMeetingDaysWithTimeSlotReducers,
		meetingDayReducers: state.meetingDayReducers,
		setMultipleTimeSlotsReducers: state.setMultipleTimeSlotsReducers,
		getMeetingDetailsByMeetingHashReducers: state.getMeetingDetailsByMeetingHashReducers,
		getMeetingDaysWithAllTimeSlotReducers: state.getMeetingDaysWithAllTimeSlotReducers,
		registerInviteesReducers: state.registerInviteesReducers,
	}
}

export default connect(mapStateToProps, {
	getMeetingDaysWithTimeSlot,
	getMeetingDaysWithTimeSlotReset,
	meetingDay,
	updateOrganiserSelectedTimeSlotArray,
	setMultipleTimeSlots,
	setMultipleTimeSlotsReset,
	getMeetingDetailsByMeetingHash,
	getMeetingDetailsByMeetingHashReset,
	getMeetingDaysWithAllTimeSlot,
	getMeetingDaysWithAllTimeSlotReset,
	registerInvitees,
	registerInviteesReset,
	setDashboardSelectedDate,
	setIsBackFromLocationScreen,
})(InviteeTimeSelectionPage)
