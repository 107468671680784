import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'
import {store} from "../configureStore";
// import {setJoiningScheduledMeet} from "./utilActions";
import {registerInvitees} from "./registerInviteesActions";

export const getMeetingDetailsByMeetingHash = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.GET_MEETING_DETAILS_BY_MEETING_HASH_REQUEST,
	});

	await Axios(
		"get",
		end_points.get_meeting_details_by_meeting_hash + payload.meetingHash,
		null,
		"application/json",
		true
	)
	.then((res) => {
		console.log(`${end_points.get_meeting_details_by_meeting_hash + payload.meetingHash} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.GET_MEETING_DETAILS_BY_MEETING_HASH_SUCCESS,
				payload: res?.data,
			});
			// if(res?.data?.response?.status === "SCHEDULED"){
			// 	console.log("modal open from App js")
			// 	// store.dispatch(setJoiningScheduledMeet("INITIATED"))
			// }else{
			// 	store.dispatch(registerInvitees({ "meetingHashCode": payload.meetingHash }));
			// }
			// store.dispatch(getMeetingDetailsByMeetingHashReset());
		}
	})
	.catch((error) => {
		console.log(`${end_points.get_meeting_details_by_meeting_hash + payload.meetingHash} Error response:`, error);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.GET_MEETING_DETAILS_BY_MEETING_HASH_FAILED,
				payload: error?.response?.data,
			});
		}
	})
};

export const getMeetingDetailsByMeetingHashReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.GET_MEETING_DETAILS_BY_MEETING_HASH_RESET,
	});
};

export const getMeetingDetailsByMeetingHashResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.GET_MEETING_DETAILS_BY_MEETING_HASH_RESET_ALL,
	});
};
