import React, {useEffect, useRef, useState} from 'react';
import { connect } from 'react-redux';
import { regionCoffeeShop } from '../../../store/actions/regionCoffeeShop';
import MapComponentImg from '../../../static/img/map-marker-blue.png';
import GoogleMapReact from 'google-map-react';

// import MapMarker from './MapMarker';
import moment from "moment";

const ASPECT_RATIO = 0.46208530805687204;
const LATITUDE_DELTA = 0.0922;
const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;
let cafeArray = [];

function FinalizeLocationMapView({
    getMeetingCenterLocationReducers,
    meetingId,
    finalEvent,
    selectedDate,
    myRegion,
    isWaitingForOthersLocation,
}) {
    const mapRef = useRef();
    const [region, setRegion] = useState({});
    const [coffeeShops, setCoffeeShops] = useState([]);
    const [taimebaseCoffeeShop, setTaimebaseCoffeeShop] = useState([])

    useEffect(()=>{
        // getCoordinate();
        // if(myRegion !== undefined){
        //     if(('latitude' in myRegion) &&  ('longitude' in myRegion)){
        //         setRegion(myRegion);
        //     }

        // }else 
        if(('latitude' in getMeetingCenterLocationReducers?.data) &&  ('longitude' in getMeetingCenterLocationReducers?.data)) {
            if (

                getMeetingCenterLocationReducers?.data?.latitude &&
                getMeetingCenterLocationReducers?.data?.longitude
            ) {
                setRegion({
                    latitude:
                    getMeetingCenterLocationReducers?.data?.latitude,
                    longitude:
                    getMeetingCenterLocationReducers?.data?.longitude,
                    latitudeDelta: LATITUDE_DELTA,
                    longitudeDelta: LONGITUDE_DELTA,
                });
                // setRegion({
                //     latitude: 22.6922,
                //     longitude: 75.8675,
                //     latitudeDelta: LATITUDE_DELTA,
                //     longitudeDelta: LONGITUDE_DELTA,
                // });
                // searchCoffeeShops(
                // 	props.organizerAndUserCoordinateUpdateReducer?.organizer?.latitude,
                // 	props.organizerAndUserCoordinateUpdateReducer?.organizer?.longitude,
                // );
            }
        }
    },[]);

    useEffect(() => {
        // console.log("before before fetchCoffeeShops ///////////////////")
        if(region !== undefined && Object.keys(region).length !== 0 && ('latitude' in region) &&  ('longitude' in region)) {
            // console.log("before fetchCoffeeShops ///////////////////")
            const fetchCoffeeShops = async () => {
                try {
                    const {latitude, longitude} = region;
                    searchCoffeeShops(latitude, longitude);
                    fetchCoffeeShopsBasedOnTimeRange(
                        latitude,
                        longitude,
                        selectedDate,
                        [
                            moment(finalEvent?.start).format("HH:mm").replace(/:/g, '').toString(),
                            moment(finalEvent?.end).format("HH:mm").replace(/:/g, '').toString()
                        ]
                    );
                    // console.log("after fetchCoffeeShops ///////////////////")
                } catch (error) {
                    console.log(error);
                }
            };
            fetchCoffeeShops();
        }
    }, [region]);

    const searchCoffeeShops = async (latitude, longitude) => {
        try {
            const API_KEY = "AIzaSyCSxcC8KD4LqOHxk6baZjaUx9Xqlwgqh_U";
            const location = `${latitude},${longitude}`;
            const radius = 2000; // in meters

            const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${location}&rankby=distance&keyword=${5000}&type=cafe&key=${API_KEY}`;

            const response = await fetch(url);
            const data = await response.json();
            // console.log("rankby=distance data :", data.results)
            if (data.status === 'OK') {
                setCoffeeShops(data.results);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const returnDayIndex = (day) =>{
        switch (day) {
            case 'Monday':
                return 0;
            case 'Tuesday':
                return 1;
            case 'Wednesday':
                return 2;
            case 'Thursday':
                return 3;
            case 'Friday':
                return 4;
            case 'Saturday':
                return 5;
            case 'Sunday':
                return 6;
            default:
                // console.log('invalid day name provided');
                break;
        }
    }

    const returnDayStringFromDate = (dateValue) =>{
        let date = new Date(dateValue);
        let day = date.toLocaleString('en-us', { weekday: 'long' });
        return day;
    }

    const fetchCoffeeShopsBasedOnTimeRange = async (latitude, longitude, date, timeRangeArray) => {
        try {
            const API_KEY = "AIzaSyCSxcC8KD4LqOHxk6baZjaUx9Xqlwgqh_U";
            const location = `${latitude},${longitude}`; // Example: '40.7128,-74.0060'
            const radius = 2000; // Distance in meters
            const timeRange = timeRangeArray; // Example: '0800-1700'
            let dayValue = returnDayStringFromDate(date);
            // console.log('dayValue:', dayValue.split(',')[0]);
            let indexOfDay = returnDayIndex(dayValue.split(',')[0]);
            // console.log('indexOfDay:', indexOfDay);
            const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${location}&radius=${radius}&type=cafe&key=${API_KEY}`;

            fetch(url)
                .then((response) => {
                    return response.json();
                })
                .then(result =>{
                    if(result?.results !== undefined && result?.results.length > 0){
                        // console.log('result?.results :', result?.results?.length);
                         let filteredMarkers = result.results.filter(
                            (place) => {
                                // console.log('place:', place.place_id);

                                const url2 = `https://maps.googleapis.com/maps/api/place/details/json?placeid=${place.place_id}&fields=opening_hours&key=${API_KEY}`;

                                fetch(url2)
                                    .then((response2) => {
                                        return response2.json();
                                    })
                                    .then((result2) => {
                                        if (
                                            result2?.result?.opening_hours?.periods !==
                                            undefined
                                        ) {
                                            console.log(
                                                // 'result2:',
                                                // result2.result,
                                                // 'start by function:',
                                                // result2.result.opening_hours.weekday_text[0],
                                                // 	.split(' ')[1]
                                                // 	.split(' – '),
                                                'start by period function:',
                                                result2?.result?.opening_hours?.periods[indexOfDay],
                                            );
                                            let openTime =
                                                result2?.result?.opening_hours?.periods[
                                                    indexOfDay
                                                ]?.open;
                                            let closeTime =
                                                result2?.result?.opening_hours?.periods[
                                                    indexOfDay
                                                ]?.close;
                                            console.log(
                                                'openTime:',
                                                openTime,
                                                ' closeTime:',
                                                closeTime,
                                            );

                                            // if (timeRange[1] === '0000') {
                                            //     if (
                                            //         indexOfDay + 1 === closeTime?.day &&
                                            //         timeRange[0] >= openTime?.time &&
                                            //         (timeRange[1] === closeTime?.time ||
                                            //             timeRange[1] <= closeTime?.time)
                                            //     ) {
                                            //         // setTaimebaseCoffeeShop((pre) => [...pre, place]);
                                            //         // return true;
                                            //         cafeArray.push(place);
                                            //     }
                                            //     // return false;
                                            // } else {
                                            if (
                                                timeRange[0] >= openTime?.time &&
                                                timeRange[1] <= closeTime?.time
                                            ) {
                                                cafeArray = [...cafeArray, place];
                                                setTaimebaseCoffeeShop((pre) => [
                                                    ...pre,
                                                    place,
                                                ]);
                                                return true;
                                            }
                                            return false;
                                            // }
                                        }
                                    });

                                // console.log('result:', result.results[0]);

                                // if (
                                // 	timeRange[0] <=
                                // 		result2.result.opening_hours.periods[0].open.time &&
                                // 	timeRange[1] >=
                                // 		result2.result.opening_hours.periods[0].close.time
                                // ) {
                                // 	return true;
                                // }

                                //  return false;
                            },
                        );

                        // console.log('filteredMarkers after filter:', filteredMarkers);
                        setTimeout(() => {
                            if (cafeArray?.length === 0) {
                                console.log(
                                    'taimebaseCoffeeShop 1234:',
                                    taimebaseCoffeeShop?.length,
                                );
                                // console.log('cafeArray 1234:', cafeArray?.length);
                                alert(
                                    'no cafe is open in the selected time please change the start and end time of cafe generaly cafes are open from 6AM to 12AM',
                                );
                            }
                        }, 500);
                    }else{
                        alert('no cafe found')
                    }

                });
                // console.log('filteredMarkers: first:', filteredMarkers?.length);




            // const result = await response.json();
            // console.log('timeRange[0]:', timeRange[0], 'timeRange[1]:', timeRange[1]);
            // if (result.status === 'OK') {

            // }



        } catch (error) {
            console.error('Error fetching coffee shops:', error);
        }
    };

    // console.log('taimebaseCoffeeShop:', taimebaseCoffeeShop.length);
    // console.log('taimebaseCoffeeShop:', taimebaseCoffeeShop);
    const renderMarkers = () => {
        if(isWaitingForOthersLocation){
            return ;
        }
        if(taimebaseCoffeeShop?.length > 0) {
            return taimebaseCoffeeShop.map((coffeeShop) => (
                <div></div>
                // <MapMarker
                //     key={coffeeShop.place_id}
                //     place_id={coffeeShop.place_id}
                //     lat={coffeeShop.geometry.location.lat}
                //     lng={coffeeShop.geometry.location.lng}
                //     name={coffeeShop.name}
                //     vicinity={coffeeShop.vicinity}
                //     MapMarkerRedImg={MapComponentImg.MapMarkerRedImg}
                //     MapMarkerGreenImg={MapComponentImg.MapMarkerGreenImg}
                //     isFinalizing={true}
                //     meetingId={meetingId}
                // />
            ));
        }else{
            // alert("No cafes are open in the provided time slots")
            // setTimeout(() => {
            //     console.log("taimebaseCoffeeShop :", taimebaseCoffeeShop)
            //     if(taimebaseCoffeeShop?.length > 0){
            //         return;
            //     }
            //     toast.show("No cafes are open in the provided time slots")
            // }, 10000)
        }
    };

    // Call fitToSuppliedMarkers() method on the MapView after markers get updated
    useEffect(() => {
        if (mapRef?.current) {
            // list of _id's must same that has been provided to the identifier props of the Marker
            // console.log("coffeeShops :", coffeeShops)
            mapRef?.current?.fitToSuppliedMarkers(taimebaseCoffeeShop?.map(({ _id }) => _id));
        }
    }, [taimebaseCoffeeShop]);

    return (
        <div style={{ height: '60vh', width: '80%' }} className='mx-auto my-4'>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyCSxcC8KD4LqOHxk6baZjaUx9Xqlwgqh_U" }}
                defaultCenter={{
                    lat: 22.7535661,
                    lng: 75.9141394
                }}
                defaultZoom={11}
            >
                {renderMarkers()}
            </GoogleMapReact>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log(
			'state.getLocationPreferencesReducers:',
			state.getLocationPreferencesReducers,
		);
    return {
        regionCoffeeShopReducer: state.regionCoffeeShopReducer,
        getLocationPreferencesReducers: state.getLocationPreferencesReducers,
        organizerAndUserCoordinateUpdateReducer: state.organizerAndUserCoordinateUpdateReducer,
        getMeetingCenterLocationReducers: state.getMeetingCenterLocationReducers,
    };
};

export default connect(mapStateToProps, {
    // regionCoffeeShop
})(FinalizeLocationMapView)
