import React from 'react';
import { IoChevronDown } from "react-icons/io5";
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { LocationPermissionImgs } from '../../static/constants/imgConstants';


function PermissionsGuide() {
    const navigate = useNavigate();
    
    function getBrowser() {
        let browser = null

        if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
            browser = 'Chrome' ;
        } else if (navigator.userAgent.indexOf("Edg") != -1) {
            browser = 'Chrome' ;
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            browser = 'Chrome' ;
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            browser = 'Safari' ;
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            browser = 'Chrome' ;
        } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
        {
            browser = 'Chrome' ;
        } else {
            browser = 'Chrome' ;
        }

        return browser;
    }

    return (
        <div className='permissions-guide h-full main-background'>
            <div className='pt-[8rem] px-8 flex w-full' onClick={() => navigate.goBack()}>
				<BiLeftArrowAlt size={24} color='#D74DFF'/>
			</div>
            <div className='flex flex-col items-start justify-start h-full px-8'>
                <div class="container mx-auto px-2">
                    <div class="rounded overflow-hidden">
                    {getBrowser() == 'Safari' ?
                        <>
                            <div class="group outline-none accordion-section" tabindex="1">
                                <div class="group bg-gray-900 flex justify-between py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                                    <div class="text-lg text-[#5F61F3] font-[600] group-focus:text-[#D74DFF] transition ease duration-500">
                                        Enable Location Permission for safari (iOS)
                                    </div>
                                    <div class="h-8 w-8 border border-gray-700 rounded-full items-center inline-flex justify-center transform transition ease duration-500 text-[#5F61F3] group-focus:text-[#D74DFF] group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                                        <IoChevronDown />
                                    </div>
                                </div>
                                <div class="group-focus:max-h-[60svh] max-h-0 bg-gray-800 group-focus:py-2 overflow-scroll ease duration-500">
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            1. First of all, open Settings.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            2. Open Search and enter Safari.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep2}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            3. Then, we open Safari.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep3}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            4. Then, we press the Location.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep4}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            5. Now we can enable locations with the Allow option.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="group outline-none accordion-section" tabindex="2">
                                <div class="group bg-gray-900 flex justify-between py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                                    <div class="text-lg text-[#5F61F3] font-[600] group-focus:text-[#D74DFF] transition ease duration-500">
                                        Enable Location Services for safari (iOS)
                                    </div>
                                    <div class="h-8 w-8 border border-gray-700 rounded-full items-center inline-flex justify-center transform transition ease duration-500 text-[#5F61F3] group-focus:text-[#D74DFF] group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                                        <IoChevronDown />
                                    </div>
                                </div>
                                <div class="group-focus:max-h-[54svh] max-h-0 bg-gray-800 group-focus:py-2 overflow-scroll ease duration-500">
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            1. Open the Settings app.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            2. Scroll down to Privacy & Security, click on Privacy & Security.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServices1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            3. Select Location Services.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServices2}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            4. Scroll down to Safari Websites and tap the icon.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServices3}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-10'>
                                        <div className='mb-2 font-[500]'>
                                            5. Now we can enable locations with the Allow Location Access.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServices4}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="group outline-none accordion-section" tabindex="3">
                                <div class="group bg-gray-900 flex justify-between py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                                    <div class="text-lg text-[#5F61F3] font-[600] group-focus:text-[#D74DFF] transition ease duration-500">
                                        Enable Location Permission for safari (macOS)
                                    </div>
                                    <div class="h-8 w-8 border border-gray-700 rounded-full items-center inline-flex justify-center transform transition ease duration-500 text-[#5F61F3] group-focus:text-[#D74DFF] group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                                        <IoChevronDown />
                                    </div>
                                </div>
                                <div class="group-focus:max-h-[54svh] max-h-0 bg-gray-800 group-focus:py-2 overflow-scroll ease duration-500">
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            1. At first open Safari.
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            2. Then tap on Safari.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStepMac1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            3. Next choose Preferences.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStepMac2}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            4. Click on Websites.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStepMac3}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            5. Now from the list pick Location.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStepMac4}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            6. Press on Deny.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStepMac5}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            7. In the end, select Allow.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStepMac6}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            8. Perfect! You have successfully Allowed Location Services on Safari.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStepMac7}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div class="group outline-none accordion-section" tabindex="4">
                                <div class="group bg-gray-900 flex justify-between py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                                    <div class="text-lg text-[#5F61F3] font-[600] group-focus:text-[#D74DFF] transition ease duration-500">
                                        Enable Location Permission for Chrome (iOS)
                                    </div>
                                    <div class="h-8 w-8 border border-gray-700 rounded-full items-center inline-flex justify-center transform transition ease duration-500 text-[#5F61F3] group-focus:text-[#D74DFF] group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                                        <IoChevronDown />
                                    </div>
                                </div>
                                <div class="group-focus:max-h-[60svh] max-h-0 bg-gray-800 group-focus:py-2 overflow-scroll ease duration-500">
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            1. First of all, open Settings.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            2. Open Search and enter Chrome.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep2}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            3. Then, we open Chrome.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServicesChrome}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            4. Then, we press the Location.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServicesChrome2}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mt-4 mb-2 font-[500]'>
                                            5. Now we can enable locations with the Allow option.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServicesChrome4}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="group outline-none accordion-section" tabindex="5">
                                <div class="group bg-gray-900 flex justify-between py-3 items-center text-gray-500 transition ease duration-500 cursor-pointer pr-10 relative">
                                    <div class="text-lg text-[#5F61F3] font-[600] group-focus:text-[#D74DFF] transition ease duration-500">
                                        Enable Location Services for Chrome (iOS)
                                    </div>
                                    <div class="h-8 w-8 border border-gray-700 rounded-full items-center inline-flex justify-center transform transition ease duration-500 text-[#5F61F3] group-focus:text-[#D74DFF] group-focus:-rotate-180 absolute top-0 right-0 mb-auto ml-auto mt-2 mr-2">
                                        <IoChevronDown />
                                    </div>
                                </div>
                                <div class="group-focus:max-h-[54svh] max-h-0 bg-gray-800 group-focus:py-2 overflow-scroll ease duration-500">
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            1. Open the Settings app.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationPermissionIosStep1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            2. Scroll down to Privacy & Security, click on Privacy & Security.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServices1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            3. Select Location Services.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServices2}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start'>
                                        <div className='mb-2 font-[500]'>
                                            4. Scroll down to Safari Websites and tap the icon.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServicesChrome1}
                                            />
                                        </div>
                                    </div>
                                    <div className='flex flex-col justify-start items-start mb-10'>
                                        <div className='mb-2 font-[500]'>
                                            5. Now we can enable locations with the Allow Location Access.
                                        </div>
                                        <div className='w-full'>
                                            <img 
                                                src={LocationPermissionImgs.LocationServicesChrome3}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PermissionsGuide
