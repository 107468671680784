export const baseUrl = 'https://app.ecco.zip/api/'; // prePROD url
export const baseUrlWebview = 'https://embed.ecco.zip'; // prePROD url

export const end_points = {
    generate_otp: 'registerUser/generateOtp/',
    check_phone_number: 'registerUser/checkPhoneNumber/',
    verify_otp: 'registerUser/verifyOtp/',
    register_user: 'registerUser/registration',
    login: 'auth/login',
    create_meeting_details: 'registerUser/createMeetingDetails',
    create_meeting: 'meeting/createMeeting',
    meeting_day: 'meeting/meetingDay/',
    set_time_slot: 'timeSlot/setTimeSlot',
    meeting_by_user_id: 'dashboard/meetingByUserId',
    meeting_by_meeting_date: 'dashboard/meetingByMeetingDate',
    create_location_details: 'location/create',
    delete_time_slot: 'timeSlot/delete/',
    update_time_slot: 'timeSlot/update',
    get_time_slot_by_id: 'timeSlot/timeSlotId/',
    get_meeting_details_by_meeting_id: 'meeting/meeting/',
    register_invitees: 'registerUser/registerInvitees/',
    get_time_slot_by_meeting_day_id: 'timeSlot/meetingDayId/',
    get_meeting_days_with_time_slot: 'meeting/meetingDaysWithTimeSlot/',
    set_invitee_location_preference: 'preference/setPreference',
    get_location_preference: 'location/locationPreferences/',
    finalize_meeting: 'meeting/finalizeMeeting',
    get_meeting_days_with_all_time_slot: 'meeting/meetingDayWithAllTimeSlots/',
    get_meeting_center_location: 'meeting/meetingCenterLocation/',
    set_multiple_time_slots: 'timeSlot/setMultipleTimeSlots',
    set_guest_pref: 'meeting/setGuestPref/',
    get_meeting_details_by_meeting_hash: 'meeting/meetingByMeetingHash/',
    generate_otp_for_forgot_pin: 'registerUser/generateOtpForForgotPin',
    verify_otp_for_forgot_pin: '/registerUser/verifyOtpForForgotPin',
    reset_pin: '/registerUser/resetPassword',
}
