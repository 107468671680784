export const actionTypes = {
	DEMO_ACTION: 'DEMO_ACTION',
	REGION_COFFEE_SHOP: 'REGION_COFFEE_SHOP',

	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_SESSION_ID: 'SET_SESSION_ID',
	SET_SIGNUP_CONTACT_NO: 'SET_SIGNUP_CONTACT_NO',
	SET_VERIFIED_MOB_USER_ID: 'SET_VERIFIED_MOB_USER_ID',
	SET_DASHBOARD_SELECTED_DATE: 'SET_DASHBOARD_SELECTED_DATE',
	SET_FIREBASE_TOKEN_ID: 'SET_FIREBASE_TOKEN_ID',
	SET_FORGET_PIN_SESSION_ID: 'SET_FORGET_PIN_SESSION_ID',
	SET_IS_BACK_FROM_LOCATION_SCREEN: 'SET_IS_BACK_FROM_LOCATION_SCREEN',
	SET_FINAL_DATA: 'SET_FINAL_DATA',
	RESET_FINAL_DATA: 'RESET_FINAL_DATA',
	SET_JOINING_SCHEDULED_MEET: 'SET_JOINING_SCHEDULED_MEET',
	USER_LOGOUT: 'USER_LOGOUT',
	UTILS_DATA_RESET: 'UTILS_DATA_RESET',

	SET_PROXIMITY: 'SET_PROXIMITY',
	RESET_PROXIMITY: 'RESET_PROXIMITY',

	SET_STARTING_LOCATION: 'SET_STARTING_LOCATION',
	RESET_STARTING_LOCATION: 'RESET_STARTING_LOCATION',

	GENERATE_OTP_REQUEST: 'GENERATE_OTP_REQUEST',
	GENERATE_OTP_SUCCESS: 'GENERATE_OTP_SUCCESS',
	GENERATE_OTP_FAILED: 'GENERATE_OTP_FAILED',
	GENERATE_OTP_RESET: 'GENERATE_OTP_RESET',
	GENERATE_OTP_RESET_ALL: 'GENERATE_OTP_RESET_ALL',

	CHECK_PHONE_NUMBER_REQUEST: 'CHECK_PHONE_NUMBER_REQUEST',
	CHECK_PHONE_NUMBER_SUCCESS: 'CHECK_PHONE_NUMBER_SUCCESS',
	CHECK_PHONE_NUMBER_FAILED: 'CHECK_PHONE_NUMBER_FAILED',
	CHECK_PHONE_NUMBER_RESET: 'CHECK_PHONE_NUMBER_RESET',
	CHECK_PHONE_NUMBER_RESET_ALL: 'CHECK_PHONE_NUMBER_RESET_ALL',
	
	GENERATE_OTP_FOR_FORGET_PIN_REQUEST: 'GENERATE_OTP_FOR_FORGET_PIN_REQUEST',
	GENERATE_OTP_FOR_FORGET_PIN_SUCCESS: 'GENERATE_OTP_FOR_FORGET_PIN_SUCCESS',
	GENERATE_OTP_FOR_FORGET_PIN_FAILED: 'GENERATE_OTP_FOR_FORGET_PIN_FAILED',
	GENERATE_OTP_FOR_FORGET_PIN_RESET: 'GENERATE_OTP_FOR_FORGET_PIN_RESET',
	GENERATE_OTP_FOR_FORGET_PIN_RESET_ALL: 'GENERATE_OTP_FOR_FORGET_PIN_RESET_ALL',

	VERIFY_OTP_FOR_FORGET_PIN_REQUEST: 'VERIFY_OTP_FOR_FORGET_PIN_REQUEST',
	VERIFY_OTP_FOR_FORGET_PIN_SUCCESS: 'VERIFY_OTP_FOR_FORGET_PIN_SUCCESS',
	VERIFY_OTP_FOR_FORGET_PIN_FAILED: 'VERIFY_OTP_FOR_FORGET_PIN_FAILED',
	VERIFY_OTP_FOR_FORGET_PIN_RESET: 'VERIFY_OTP_FOR_FORGET_PIN_RESET',
	VERIFY_OTP_FOR_FORGET_PIN_RESET_ALL: 'VERIFY_OTP_FOR_FORGET_PIN_RESET_ALL',

	VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
	VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
	VERIFY_OTP_FAILED: 'VERIFY_OTP_FAILED',
	VERIFY_OTP_RESET: 'VERIFY_OTP_RESET',
	VERIFY_OTP_RESET_ALL: 'VERIFY_OTP_RESET_ALL',

	REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
	REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
	REGISTER_USER_FAILED: 'REGISTER_USER_FAILED',
	REGISTER_USER_RESET: 'REGISTER_USER_RESET',
	REGISTER_USER_RESET_ALL: 'REGISTER_USER_RESET_ALL',

	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILED: 'LOGIN_FAILED',
	LOGIN_RESET: 'LOGIN_RESET',
	LOGIN_RESET_ALL: 'LOGIN_RESET_ALL',

	CREATE_MEETING_DETAILS_REQUEST: 'CREATE_MEETING_DETAILS_REQUEST',
	CREATE_MEETING_DETAILS_SUCCESS: 'CREATE_MEETING_DETAILS_SUCCESS',
	CREATE_MEETING_DETAILS_FAILED: 'CREATE_MEETING_DETAILS_FAILED',
	CREATE_MEETING_DETAILS_RESET: 'CREATE_MEETING_DETAILS_RESET',
	CREATE_MEETING_DETAILS_RESET_ALL: 'CREATE_MEETING_DETAILS_RESET_ALL',

	CREATE_MEETING_REQUEST: 'CREATE_MEETING_REQUEST',
	CREATE_MEETING_SUCCESS: 'CREATE_MEETING_SUCCESS',
	CREATE_MEETING_FAILED: 'CREATE_MEETING_FAILED',
	CREATE_MEETING_RESET: 'CREATE_MEETING_RESET',
	CREATE_MEETING_RESET_ALL: 'CREATE_MEETING_RESET_ALL',

	MEETING_DAY_REQUEST: 'MEETING_DAY_REQUEST',
	MEETING_DAY_SUCCESS: 'MEETING_DAY_SUCCESS',
	MEETING_DAY_FAILED: 'MEETING_DAY_FAILED',
	MEETING_DAY_RESET: 'MEETING_DAY_RESET',
	MEETING_DAY_RESET_ALL: 'MEETING_DAY_RESET_ALL',

	SET_TIME_SLOT_REQUEST: 'SET_TIME_SLOT_REQUEST',
	SET_TIME_SLOT_SUCCESS: 'SET_TIME_SLOT_SUCCESS',
	SET_TIME_SLOT_FAILED: 'SET_TIME_SLOT_FAILED',
	SET_TIME_SLOT_RESET: 'SET_TIME_SLOT_RESET',
	SET_TIME_SLOT_RESET_ALL: 'SET_TIME_SLOT_RESET_ALL',

	SET_MULTIPLE_TIME_SLOT_REQUEST: 'SET_MULTIPLE_TIME_SLOT_REQUEST',
	SET_MULTIPLE_TIME_SLOT_SUCCESS: 'SET_MULTIPLE_TIME_SLOT_SUCCESS',
	SET_MULTIPLE_TIME_SLOT_FAILED: 'SET_MULTIPLE_TIME_SLOT_FAILED',
	SET_MULTIPLE_TIME_SLOT_RESET: 'SET_MULTIPLE_TIME_SLOT_RESET',
	SET_MULTIPLE_TIME_SLOT_RESET_ALL: 'SET_MULTIPLE_TIME_SLOT_RESET_ALL',

	DELETE_TIME_SLOT_REQUEST: 'DELETE_TIME_SLOT_REQUEST',
	DELETE_TIME_SLOT_SUCCESS: 'DELETE_TIME_SLOT_SUCCESS',
	DELETE_TIME_SLOT_FAILED: 'DELETE_TIME_SLOT_FAILED',
	DELETE_TIME_SLOT_RESET: 'DELETE_TIME_SLOT_RESET',
	DELETE_TIME_SLOT_RESET_ALL: 'DELETE_TIME_SLOT_RESET_ALL',

	UPDATE_TIME_SLOT_REQUEST: 'UPDATE_TIME_SLOT_REQUEST',
	UPDATE_TIME_SLOT_SUCCESS: 'UPDATE_TIME_SLOT_SUCCESS',
	UPDATE_TIME_SLOT_FAILED: 'UPDATE_TIME_SLOT_FAILED',
	UPDATE_TIME_SLOT_RESET: 'UPDATE_TIME_SLOT_RESET',
	UPDATE_TIME_SLOT_RESET_ALL: 'UPDATE_TIME_SLOT_RESET_ALL',

	GET_TIME_SLOT_BY_ID_REQUEST: 'GET_TIME_SLOT_BY_ID_REQUEST',
	GET_TIME_SLOT_BY_ID_SUCCESS: 'GET_TIME_SLOT_BY_ID_SUCCESS',
	GET_TIME_SLOT_BY_ID_FAILED: 'GET_TIME_SLOT_BY_ID_FAILED',
	GET_TIME_SLOT_BY_ID_RESET: 'GET_TIME_SLOT_BY_ID_RESET',
	GET_TIME_SLOT_BY_ID_RESET_ALL: 'GET_TIME_SLOT_BY_ID_RESET_ALL',

	GET_MEETING_DETAILS_BY_MEETING_ID_REQUEST:
		'GET_MEETING_DETAILS_BY_MEETING_ID_REQUEST',
	GET_MEETING_DETAILS_BY_MEETING_ID_SUCCESS:
		'GET_MEETING_DETAILS_BY_MEETING_ID_SUCCESS',
	GET_MEETING_DETAILS_BY_MEETING_ID_FAILED:
		'GET_MEETING_DETAILS_BY_MEETING_ID_FAILED',
	GET_MEETING_DETAILS_BY_MEETING_ID_RESET:
		'GET_MEETING_DETAILS_BY_MEETING_ID_RESET',
	GET_MEETING_DETAILS_BY_MEETING_ID_RESET_ALL:
		'GET_MEETING_DETAILS_BY_MEETING_ID_RESET_ALL',

	MEETING_BY_USER_ID_REQUEST: 'MEETING_BY_USER_ID_REQUEST',
	MEETING_BY_USER_ID_SUCCESS: 'MEETING_BY_USER_ID_SUCCESS',
	MEETING_BY_USER_ID_FAILED: 'MEETING_BY_USER_ID_FAILED',
	MEETING_BY_USER_ID_RESET: 'MEETING_BY_USER_ID_RESET',
	MEETING_BY_USER_ID_RESET_ALL: 'MEETING_BY_USER_ID_RESET_ALL',

	MEETING_BY_MEETING_DATE_REQUEST: 'MEETING_BY_MEETING_DATE_REQUEST',
	MEETING_BY_MEETING_DATE_SUCCESS: 'MEETING_BY_MEETING_DATE_SUCCESS',
	MEETING_BY_MEETING_DATE_FAILED: 'MEETING_BY_MEETING_DATE_FAILED',
	MEETING_BY_MEETING_DATE_RESET: 'MEETING_BY_MEETING_DATE_RESET',
	MEETING_BY_MEETING_DATE_RESET_ALL: 'MEETING_BY_MEETING_DATE_RESET_ALL',

	CREATE_LOCATION_DETAILS_REQUEST: 'CREATE_LOCATION_DETAILS_REQUEST',
	CREATE_LOCATION_DETAILS_SUCCESS: 'CREATE_LOCATION_DETAILS_SUCCESS',
	CREATE_LOCATION_DETAILS_FAILED: 'CREATE_LOCATION_DETAILS_FAILED',
	CREATE_LOCATION_DETAILS_RESET: 'CREATE_LOCATION_DETAILS_RESET',
	CREATE_LOCATION_DETAILS_RESET_ALL: 'CREATE_LOCATION_DETAILS_RESET_ALL',

	REGISTER_INVITEE_REQUEST: 'REGISTER_INVITEE_REQUEST',
	REGISTER_INVITEE_SUCCESS: 'REGISTER_INVITEE_SUCCESS',
	REGISTER_INVITEE_FAILED: 'REGISTER_INVITEE_FAILED',
	REGISTER_INVITEE_RESET: 'REGISTER_INVITEE_RESET',
	REGISTER_INVITEE_RESET_ALL: 'REGISTER_INVITEE_RESET_ALL',
	
	RESET_PIN_REQUEST: 'RESET_PIN_REQUEST',
	RESET_PIN_SUCCESS: 'RESET_PIN_SUCCESS',
	RESET_PIN_FAILED: 'RESET_PIN_FAILED',
	RESET_PIN_RESET: 'RESET_PIN_RESET',
	RESET_PIN_RESET_ALL: 'RESET_PIN_RESET_ALL',

	GET_TIME_SLOT_BY_MEETING_DAY_ID_REQUEST:
		'GET_TIME_SLOT_BY_MEETING_DAY_ID_REQUEST',
	GET_TIME_SLOT_BY_MEETING_DAY_ID_SUCCESS:
		'GET_TIME_SLOT_BY_MEETING_DAY_ID_SUCCESS',
	GET_TIME_SLOT_BY_MEETING_DAY_ID_FAILED:
		'GET_TIME_SLOT_BY_MEETING_DAY_ID_FAILED',
	GET_TIME_SLOT_BY_MEETING_DAY_ID_RESET:
		'GET_TIME_SLOT_BY_MEETING_DAY_ID_RESET',
	GET_TIME_SLOT_BY_MEETING_DAY_ID_RESET_ALL:
		'GET_TIME_SLOT_BY_MEETING_DAY_ID_RESET_ALL',

	GET_MEETING_DAYS_WITH_TIME_SLOT_REQUEST:
		'GET_MEETING_DAYS_WITH_TIME_SLOT_REQUEST',
	GET_MEETING_DAYS_WITH_TIME_SLOT_SUCCESS:
		'GET_MEETING_DAYS_WITH_TIME_SLOT_SUCCESS',
	GET_MEETING_DAYS_WITH_TIME_SLOT_FAILED:
		'GET_MEETING_DAYS_WITH_TIME_SLOT_FAILED',
	GET_MEETING_DAYS_WITH_TIME_SLOT_RESET:
		'GET_MEETING_DAYS_WITH_TIME_SLOT_RESET',
	GET_MEETING_DAYS_WITH_TIME_SLOT_RESET_ALL:
		'GET_MEETING_DAYS_WITH_TIME_SLOT_RESET_ALL',

	SET_INVITEE_LOCATION_PREFERENCE_REQUEST:
		'SET_INVITEE_LOCATION_PREFERENCE_REQUEST',
	SET_INVITEE_LOCATION_PREFERENCE_SUCCESS:
		'SET_INVITEE_LOCATION_PREFERENCE_SUCCESS',
	SET_INVITEE_LOCATION_PREFERENCE_FAILED:
		'SET_INVITEE_LOCATION_PREFERENCE_FAILED',
	SET_INVITEE_LOCATION_PREFERENCE_RESET:
		'SET_INVITEE_LOCATION_PREFERENCE_RESET',
	SET_INVITEE_LOCATION_PREFERENCE_RESET_ALL:
		'SET_INVITEE_LOCATION_PREFERENCE_RESET_ALL',

	FINALIZE_MEETING_REQUEST: 'FINALIZE_MEETING_REQUEST',
	FINALIZE_MEETING_SUCCESS: 'FINALIZE_MEETING_SUCCESS',
	FINALIZE_MEETING_FAILED: 'FINALIZE_MEETING_FAILED',
	FINALIZE_MEETING_RESET: 'FINALIZE_MEETING_RESET',
	FINALIZE_MEETING_RESET_ALL: 'FINALIZE_MEETING_RESET_ALL',
	FINALALIZED_LOCATION_EVENT: 'FINALALIZED_LOCATION_EVENT',

	GET_LOCATION_PREFERENCE_REQUEST: 'GET_LOCATION_PREFERENCE_REQUEST',
	GET_LOCATION_PREFERENCE_SUCCESS: 'GET_LOCATION_PREFERENCE_SUCCESS',
	GET_LOCATION_PREFERENCE_FAILED: 'GET_LOCATION_PREFERENCE_FAILED',
	GET_LOCATION_PREFERENCE_RESET: 'GET_LOCATION_PREFERENCE_RESET',
	GET_LOCATION_PREFERENCE_RESET_ALL: 'GET_LOCATION_PREFERENCE_RESET_ALL',

	SET_GUEST_PREF_REQUEST: 'SET_GUEST_PREF_REQUEST',
	SET_GUEST_PREF_SUCCESS: 'SET_GUEST_PREF_SUCCESS',
	SET_GUEST_PREF_FAILED: 'SET_GUEST_PREF_FAILED',
	SET_GUEST_PREF_RESET: 'SET_GUEST_PREF_RESET',
	SET_GUEST_PREF_RESET_ALL: 'SET_GUEST_PREF_RESET_ALL',

	GET_MEETING_DETAILS_BY_MEETING_HASH_REQUEST: 'GET_MEETING_DETAILS_BY_MEETING_HASH_REQUEST',
	GET_MEETING_DETAILS_BY_MEETING_HASH_SUCCESS: 'GET_MEETING_DETAILS_BY_MEETING_HASH_SUCCESS',
	GET_MEETING_DETAILS_BY_MEETING_HASH_FAILED: 'GET_MEETING_DETAILS_BY_MEETING_HASH_FAILED',
	GET_MEETING_DETAILS_BY_MEETING_HASH_RESET: 'GET_MEETING_DETAILS_BY_MEETING_HASH_RESET',
	GET_MEETING_DETAILS_BY_MEETING_HASH_RESET_ALL: 'GET_MEETING_DETAILS_BY_MEETING_HASH_RESET_ALL',

	GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_REQUEST:
		'GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_REQUEST',
	GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_SUCCESS:
		'GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_SUCCESS',
	GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_FAILED:
		'GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_FAILED',
	GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_RESET:
		'GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_RESET',
	GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_RESET_ALL:
		'GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_RESET_ALL',
	ARRAY_OF_OBJECT_TO_GET_USER_TIME_SLOT: 'ARRAY_OF_OBJECT_TO_GET_USER_TIME_SLOT',
	OBJECT_TO_Add_USER_TIME_SLOT_TO_OBJECT_ARRAY: 'OBJECT_TO_Add_USER_TIME_SLOT_TO_OBJECT_ARRAY',

	ARRAY_OF_OBJECT_TO_GET_ORGAISER_TIME_SLOT: 'ARRAY_OF_OBJECT_TO_GET_ORGAISER_TIME_SLOT',
	OBJECT_TO_Add_ORGANISER_TIME_SLOT_TO_OBJECT_ARRAY: 'OBJECT_TO_Add_ORGANISER_TIME_SLOT_TO_OBJECT_ARRAY',

	GET_MEETING_CENTER_LOCATION_REQUEST: 'GET_MEETING_CENTER_LOCATION_REQUEST',
	GET_MEETING_CENTER_LOCATION_SUCCESS: 'GET_MEETING_CENTER_LOCATION_SUCCESS',
	GET_MEETING_CENTER_LOCATION_FAILED: 'GET_MEETING_CENTER_LOCATION_FAILED',
	GET_MEETING_CENTER_LOCATION_RESET: 'GET_MEETING_CENTER_LOCATION_RESET',
	GET_MEETING_CENTER_LOCATION_RESET_ALL: 'GET_MEETING_CENTER_LOCATION_RESET_ALL',

	ORGANIZER_CO_ORDINATES: 'ORGANIZER_CO_ORDINATES',
	USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH:
		'USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH',
	USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE:
		'USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE',
	USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE_ALL:
		'USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE_ALL',
	USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_RESET:
		'USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_RESET',
	INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH:
		'INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH',
	INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE:
		'INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE',

	STORED_HIGHLIGHTED_CELLS_DATA: 'STORED_HIGHLIGHTED_CELLS_DATA',
};
