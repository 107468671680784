import { actionTypes } from '../types'

export const setAccessToken = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_ACCESS_TOKEN,
        payload: payload
	});
};

export const setSessionId = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SESSION_ID,
        payload: payload
	});
};

export const setSignupContactNo = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_SIGNUP_CONTACT_NO,
        payload: payload
	});
};

export const setVerifiedMobUserId = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_VERIFIED_MOB_USER_ID,
        payload: payload
	});
};

export const setDashboardSelectedDate = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_DASHBOARD_SELECTED_DATE,
        payload: payload
	});
};

export const setFirebaseTokenId = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_FIREBASE_TOKEN_ID,
        payload: payload
	});
};

export const setForgotPinSessionId = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_FORGET_PIN_SESSION_ID,
        payload: payload
	});
};

export const setIsBackFromLocationScreen = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_IS_BACK_FROM_LOCATION_SCREEN,
        payload: payload
	});
};

export const setFinalData = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.SET_FINAL_DATA,
        payload: payload
	});
};

export const resetFinalData = (payload) => async (dispatch) => {
	dispatch({
		type: actionTypes.RESET_FINAL_DATA,
        payload: payload
	});
};

export const logout = () => async (dispatch) => {
	dispatch({
		type: actionTypes.USER_LOGOUT,
	});
};

export const resetUtilData = () => async (dispatch) => {
	dispatch({
		type: actionTypes.UTILS_DATA_RESET,
	});
};
