import React, { useEffect, useState } from 'react';
import TableDragSelect from './TableDragSelect';
import hours from './Hours';
import TableDragSelectWeb from "./TableDragSelectWeb";

function RenderDayTimeView(props) {
    const { type, currCreate, colWidth, changeCurrCreate } = props;
	const [curr, changeCurr] = useState({
		cells: [
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
		],
	});

	useEffect(() => {
		if (currCreate !== undefined){
			changeCurr(currCreate);

		}
	}, [currCreate]);

	function handleChange(cells) {
		if(cells !== undefined && cells !== null){
			changeCurr({cells});
			changeCurrCreate({cells})
		}
	}

	useEffect(() => {
		var elem = document.getElementById("startingPoint");
		if(elem !== null && elem !== undefined ){
			elem.scrollIntoView(true);
		}
	}, []);

	function getOS() {
		var userAgent = window.navigator.userAgent,
			platform =  window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod'],
			os = null;

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'WEB';
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = 'PHONE';
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = 'WEB';
		} else if (/Android/.test(userAgent)) {
			os = 'PHONE';
		} else if (/Linux/.test(platform)) {
			os = 'WEB';
		}

		return os;
	}

	return (
		<>
			<div className={colWidth}>
				{type === 'time' && (
					getOS() === "PHONE" ?
					<TableDragSelect
						value={curr.cells}
						onChange={handleChange}
						type={'time'}>
						<tr style={{ position: 'relative' }}>
							<td
								disabled
								style={{
									backgroundColor: '#FFF',
									position: 'sticky',
									top: '0px',
									zIndex: 20
								}}
							/>
						</tr>
						{hours.map((data, i) => {
							return (
								<tr
									key={data.id}
									style={{ height: '2rem', }}
									id={hours[i].time == '05:45 AM' ? 'startingPoint' : ''}>
									<td className='time-view' disabled style={{ fontSize: '6px', border: '1px solid #FFFFFF', position: 'relative', width: '70px', zIndex: 15 }}>
										<div
											style={{
												fontSize: '14px',
												position: 'absolute',
												top: '-9px',
												width: '70px',
												left: '50%',
												transform: "translateX(-50%)"
											}}>
											{hours[i].time}
										</div>
									</td>
								</tr>
							);
						})}
					</TableDragSelect> :
						<TableDragSelectWeb
							value={curr.cells}
							onChange={handleChange}
							type={'time'}>
							<tr style={{ position: 'relative' }}>
								<td
									disabled
									style={{
										backgroundColor: '#FFF',
										position: 'sticky',
										top: '0px',
										zIndex: 20
									}}
								/>
							</tr>
							{hours.map((data, i) => {
								return (
									<tr
									key={data.id}
									style={{ height: '2rem', }}
									id={hours[i].time == '05:45 AM' ? 'startingPoint' : ''}>
									<td className='time-view' disabled style={{ fontSize: '6px', border: '1px solid #FFFFFF', position: 'relative', width: '70px', zIndex: 15 }}>
										<div
											style={{
												fontSize: '14px',
												position: 'absolute',
												top: '-9px',
												width: '70px',
												left: '50%',
												transform: "translateX(-50%)"
											}}>
											{hours[i].time}
										</div>
									</td>
								</tr>
								);
							})}
						</TableDragSelectWeb>
				)}
				{type === 'day' && (
					getOS() === "PHONE" ?
					<TableDragSelect value={curr.cells} onChange={handleChange}>
						<tr style={{ position: 'relative' }}>
							<td
								disabled
								style={{
									fontSize: '12px',
									backgroundColor: '#5C6AA8',
									color: '#fff',
									padding: '1px 0',
									position: 'sticky',
									top: '0px',
								}}>
								My Availability
							</td>
						</tr>
						{/* 0 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 1 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 2 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 3 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 4 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 5 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 6 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 7 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 8 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 9 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 10 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 11 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 12 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 13 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 14 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 15 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 16 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 17 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 18 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 19 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 20 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 21 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 22 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 23 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 24 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 25 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 26 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 27 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 28 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 29 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 30 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 31 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 32 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 33 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 34 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 35 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 36 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 37 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 38 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 39 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 40 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 41 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 42 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 43 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 44 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 45 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 46 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 47 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 48 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 49 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 50 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 51 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 52 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 53 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 54 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 55 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 56 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 57 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 58 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 59 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 60 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 61 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 62 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 63 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 64 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 65 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 66 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 67 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 68 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 69 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 70 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 71 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 72 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 73 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 74 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 75 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 76 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 77 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 78 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 79 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 80 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 81 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 82 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 83 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 84 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 85 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 86 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 87 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 88 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 89 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 90 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 91 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 92 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 93 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 94 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 95 */}
						<tr>
							<td className='mon' style={{ borderBottom: '1px solid #333' }} />
						</tr>
					</TableDragSelect>
					:
					<TableDragSelectWeb value={curr.cells} onChange={handleChange}>
						<tr style={{ position: 'relative' }}>
							<td
								disabled
								style={{
									fontSize: '12px',
									backgroundColor: '#5C6AA8',
									color: '#fff',
									padding: '1px 0',
									position: 'sticky',
									top: '0px',
								}}>
								My Availability
							</td>
						</tr>
						{/* 0 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 1 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 2 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 3 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 4 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 5 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 6 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 7 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 8 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 9 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 10 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 11 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 12 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 13 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 14 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 15 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 16 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 17 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 18 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 19 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 20 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 21 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 22 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 23 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 24 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 25 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 26 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 27 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 28 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 29 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 30 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 31 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 32 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 33 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 34 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 35 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 36 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 37 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 38 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 39 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 40 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 41 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 42 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 43 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 44 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 45 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 46 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 47 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 48 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 49 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 50 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 51 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 52 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 53 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 54 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 55 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 56 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 57 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 58 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 59 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 60 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 61 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 62 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 63 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 64 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 65 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 66 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 67 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 68 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 69 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 70 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 71 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 72 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 73 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 74 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 75 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 76 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 77 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 78 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 79 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 80 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 81 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 82 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 83 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 84 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 85 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 86 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 87 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 88 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 89 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 90 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 91 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 92 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 93 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 94 */}
						<tr>
							<td className='mon' />
						</tr>
						{/* 95 */}
						<tr>
							<td className='mon' style={{ borderBottom: '1px solid #333' }} />
						</tr>
					</TableDragSelectWeb>
				)}

				{/* <TableDragSelect value={curr.cells} onChange={handleChange}>
					<tr>
						<td disabled>Monday</td>
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
					<tr>
						<td className='mon' />
					</tr>
				</TableDragSelect> */}

				{/* <button onClick={submit}>Submit</button> */}
			</div>
			{/* <button onClick={handleClick}>Reset</button> */}
		</>
	);
}

export default RenderDayTimeView
