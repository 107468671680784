import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'
import { setSignupContactNo } from './utilActions';

export const checkPhoneNumber = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.CHECK_PHONE_NUMBER_REQUEST,
	});

	await Axios(
		"get",
		end_points.check_phone_number + payload.phoneNo,
		null,
		"application/json",
		false
	)
	.then((res) => {
		console.log(`${end_points.check_phone_number + payload.phoneNo} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.CHECK_PHONE_NUMBER_SUCCESS,
				payload: res?.data,
			});
		}
	})
	.catch((error) => {
		console.log(`${end_points.check_phone_number + payload.phoneNo} Error response:`, error?.response?.data);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.CHECK_PHONE_NUMBER_FAILED,
				payload: error?.response?.data,
			});
		}
	})
};

export const checkPhoneNumberReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.CHECK_PHONE_NUMBER_RESET,
	});
};

export const checkPhoneNumberResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.CHECK_PHONE_NUMBER_RESET_ALL,
	});
};
