import { useState } from "react";
import {
    format,
    subMonths,
    addMonths,
    startOfWeek,
    addDays,
    isSameDay,
    lastDayOfWeek,
    getWeek,
    addWeeks,
    subWeeks
} from "date-fns";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import moment from "moment";
import { setDashboardSelectedDate } from "../../../store/actions/utilActions";
import { connect } from "react-redux";
import { useEffect } from "react";

const Calendar = ({ showDetailsHandle, setSelected, setDashboardSelectedDate, meetingByUserIdReducers }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
    const [selectedDate, setSelectedDate] = useState(new Date());

    const changeWeekHandle = (btnType) => {
        if (btnType === "prev") {
            setCurrentMonth(subWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
        }
        if (btnType === "next") {
            setCurrentMonth(addWeeks(currentMonth, 1));
            setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
        }
    };

    const onDateClickHandle = (day, dayStr) => {
        setSelectedDate(day);
        showDetailsHandle(dayStr);
        setDashboardSelectedDate({ date: moment(new Date(day)).format('DD/MM/YYYY').toString(), time: moment(new Date()) })
        setSelected(moment(new Date(day)).format('DD/MM/YYYY').toString())
    };

    const handleConvert = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    useEffect(() => {
        setDashboardSelectedDate({ date: moment(new Date()).format('DD/MM/YYYY').toString(), time: moment(new Date()) })
    }, []);

    const renderHeader = () => {
        const dateFormat = "MMMM yyyy";
        return (
            <div className="header row flex-middle">
                <div className="col col-start" onClick={() => changeWeekHandle("prev")}>
                    <div className="flex justify-start">
                        <BsChevronLeft color="#fff" />
                    </div>
                </div>
                <div className="col-2 col-center text-white">
                    <span className="capitalize">{format(currentMonth, dateFormat)}</span>
                    {/* <span>{currentMonth.toLocaleString('en-us',{month:'long', year:'numeric'})}</span> */}
                </div>
                <div className="col col-end" onClick={() => changeWeekHandle("next")}>
                    <div className="flex justify-end">
                        <BsChevronRight color="#fff" />
                    </div>
                </div>
            </div>
        );
    };
    const renderDays = () => {
        const dateFormat = "EEE";
        const days = [];
        let startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
        for (let i = 0; i < 7; i++) {
            days.push(
                <div className="col col-center text-[16px]" key={i}>
                    {format(addDays(startDate, i), dateFormat).substring(0, 1)}
                </div>
            );
        }
        return <div className="days row">{days}</div>;
    };
    const renderCells = () => {
        const startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
        const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
        const dateFormat = "d";
        const rows = [];
        let days = [];
        let day = startDate;
        let formattedDate = "";
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                var isMarkedDate = false;
                if (meetingByUserIdReducers?.data?.length > 0) {
                    meetingByUserIdReducers?.data.map((items) => {
                        if (items.meetingDate === handleConvert(day) && items.sum > 0) {
                            isMarkedDate = true
                        }
                    })
                }
                formattedDate = format(day, dateFormat);
                const cloneDay = day;
                days.push(
                    <div
                        className={`col cell ${isSameDay(day, selectedDate) ? "selected" : ""} flex justify-center items-center`}
                        key={day}
                        onClick={() => {
                            const dayStr = format(cloneDay, "ccc dd MMM yy");
                            onDateClickHandle(cloneDay, dayStr);
                        }}
                    >
                        <span className="number -mr-[6px] -mt-[2px]">{formattedDate}</span>
                        <span className="bg">{formattedDate}</span>
                        <span className="text-black ml-4 text-[50px]">{isMarkedDate && '.'}</span>
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div className="row" key={day}>
                    {days}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    };
    const renderFooter = () => {
        return (
            <div className="header row flex-middle">
            </div>
        );
    };
    return (
        <div className="calendar">
            {renderHeader()}
            {renderDays()}
            {renderCells()}
            {renderFooter()}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        meetingByUserIdReducers: state.meetingByUserIdReducers,
    }
}

export default connect(mapStateToProps, {
    setDashboardSelectedDate
})(Calendar);
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
