import { actionTypes } from '../types';
import moment from 'moment';

const initialState = {
    token: '',
    sessionId: '',
    signupContactNo: '',
    userId: 0,
    dashboardSelectedDate: moment(new Date()).format("DD/MM/YYYY"),
    dashboardSelectedDateUpdatedTime: '',
    firebaseTokenId: '',
    finalData: {},
    forgotPinSessionId: "",
    isBackFromLocationScreen: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_ACCESS_TOKEN:
			return {
                ...state,
                token: action.payload
            }
		case actionTypes.SET_SESSION_ID:
			return {
                ...state,
                sessionId: action.payload
            }
		case actionTypes.SET_SIGNUP_CONTACT_NO:
			return {
                ...state,
                signupContactNo: action.payload
            }
		case actionTypes.SET_VERIFIED_MOB_USER_ID:
			return {
                ...state,
                userId: action.payload
            }
        case actionTypes.SET_DASHBOARD_SELECTED_DATE:
			return {
                ...state,
                dashboardSelectedDate: action.payload.date,
                dashboardSelectedDateUpdatedTime: action.payload.time,
            }
        case actionTypes.SET_FIREBASE_TOKEN_ID:
			return {
                ...state,
                firebaseTokenId: action.payload
            }
        case actionTypes.SET_FORGET_PIN_SESSION_ID:
            return {
                ...state,
                forgotPinSessionId: action.payload
            }
        case actionTypes.SET_IS_BACK_FROM_LOCATION_SCREEN:
            return {
                ...state,
                isBackFromLocationScreen: action.payload
            }
        case actionTypes.SET_FINAL_DATA:
			return {
                ...state,
                finalData: action.payload
            }
        case actionTypes.RESET_FINAL_DATA:
			return {
                ...state,
                finalData: {}
            }
		case actionTypes.UTILS_DATA_RESET:
			return {
                ...state,
                token: '',
                sessionId: '',
                signupContactNo: '',
                userId: 0,
                dashboardSelectedDate: moment(new Date()).format("YYYY-MM-DD"),
                dashboardSelectedDateUpdatedTime: '',
                firebaseTokenId: '',
                forgotPinSessionId: "",
                isBackFromLocationScreen: false,
            }
		default:
			return state;
	}
};

