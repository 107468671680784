import React, { useEffect, useState, useCallback } from 'react'
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners'
import { BiLeftArrowAlt } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleMap, MarkerF, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { finalizeMeetingReset } from '../../store/actions/finalizeMeetingActions';
import { userPreferedCoffeeShopCoordinateReset } from '../../store/actions/organizerAndUserCoordinateUpdate';
import { createLocationDetails, createLocationDetailsReset } from '../../store/actions/createLocationDetailsActions';
import ShareDetailsModal from '../../components/main/ShareDetailsModal/ShareDetailsModal';
import PromptLocationPermissionInstructionModal from '../../components/main/PromptLocationPermissionInstructionModal/PromptLocationPermissionInstructionModal';
import { setIsBackFromLocationScreen } from '../../store/actions/utilActions';

function YourLocationPage({
    finalizeMeetingReset,
    createLocationDetails,
    finalizeMeetingReducers,
    createLocationDetailsReset,
    setIsBackFromLocationScreen,
    createLocationDetailsReducers,
    userPreferedCoffeeShopCoordinateReset,
    getMeetingDetailsByMeetingHashReducers,
}) {
    const navigate = useNavigate();
    const { meetingId } = useParams();
    const { userType } = useParams();

    const [isCanceling4, setIsCanceling4] = useState(false);
    const [isShareDetailsModalOpen, setIsShareDetailsModalOpen] = useState(false)
    const [isLocationPermissionModalOpen, setIsLocationPermissionModalOpen] = useState(false)
    const [isLocationWithoutPermission, setIsLocationWithoutPermission] = useState(false)

    const [userLat, setUserLat] = useState();
    const [userLong, setUserLong] = useState();
    const [marker, setMarker] = useState({ lat: 0, lng: 0 });
    const [markerWithoutPermission, setMarkerWithoutPermission] = useState({ lat: 30.2672, lng: -97.7431 });
    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);

    const onMapClick = (e) => {
        setIsLocationWithoutPermission(false)
        setMarker(
            {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        );
    };

    const onMapClickWithoutPermission = (e) => {
        setIsLocationWithoutPermission(true)
        setMarkerWithoutPermission(
            {
                lat: e.latLng.lat(),
                lng: e.latLng.lng()
            }
        );
    };

    const center = {
        lat: userLat,
        lng: userLong
    }

    const centerWithoutPermission = {
        lat: markerWithoutPermission.lat,
        lng: markerWithoutPermission.lng
    }

    const containerStyle = {
        width: '100%',
        height: '70vh'
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyCSxcC8KD4LqOHxk6baZjaUx9Xqlwgqh_U",
        libraries: ['places'],
    })

    const [permission, setPermission] = useState(null);

    useEffect(() => {
        if(permission !== "granted"){
            try{
                navigator.permissions.query({ name: "geolocation" }).then(result => {
                    setPermission(result.state);
                });
            }catch (e){
                console.log("exception in getting location")
                navigator.geolocation.getCurrentPosition(
                    (i)=>{console.log('success',i); setPermission("granted");},
                    (i)=>{console.log('failed',i); setPermission("denied");}
                )
            }
        }
    }, [permission]);

    useEffect(() => {
        if (permission !== "granted") {
            setIsLocationPermissionModalOpen(true);
        }
    }, []);


    if (permission === "granted") {
        // Do something with the user's location.
    } else if (permission === "prompt") {
        // Prompt the user to grant permission.
        // setIsLocationPermissionModalOpen(true);
    } else if (permission === "denied") {
        // Show instructions on how to enable location.
    }

    // alert("permission :"+permission+", "+"isLocationPermissionModalOpen :"+isLocationPermissionModalOpen+", "+"isLocationWithoutPermission :"+isLocationWithoutPermission)

    const onSuccess = (pos) => {
        if (localStorage.getItem("latitude") !== pos.coords.latitude || localStorage.getItem("longitude") !== pos.coords.longitude){
            // fire permission onchange event    // update local storage    console.log('success');
            setPermission("granted");
            setIsLocationPermissionModalOpen(false);
            setIsLocationWithoutPermission(false);
            setUserLat(pos.coords.latitude);
            setUserLong(pos.coords.longitude);
            setMarker(
                {
                    lat: pos.coords.latitude,
                    lng:  pos.coords.longitude
                }
            );
        }
    }

    const onError = (e) => {
        setIsLocationPermissionModalOpen(true);
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }, []);

    const onLoadMap = useCallback((map) => {
        setMap(map);
    }, []);

    const handleSetInviteeLocation = () => {

        console.log('====================================');
        console.log("isLocationWithoutPermission :", isLocationWithoutPermission);
        console.log("markerWithoutPermission :", markerWithoutPermission);
        console.log("marker :", marker);
        console.log('====================================');
        createLocationDetails({
            "meetingId": meetingId,
            "location": {
                "latitude": isLocationWithoutPermission ? markerWithoutPermission.lat : marker.lat,
                "longitude": isLocationWithoutPermission ? markerWithoutPermission.lng : marker.lng
            },
            "preferredLocations": []
        })
    }

    useEffect(() => {
        if (createLocationDetailsReducers.status === 200) {
            toast.success(createLocationDetailsReducers.message);
            if (userType == 'organizer') {
                setIsShareDetailsModalOpen(true)
            } else {
                navigate("/dashboard")
            }
            createLocationDetailsReset();
        } else if (createLocationDetailsReducers.status === 302) {
            toast.error(createLocationDetailsReducers.message);
            createLocationDetailsReset();
        } else if (createLocationDetailsReducers.status) {
            toast.error("Status: " + createLocationDetailsReducers.status + ",\n" + createLocationDetailsReducers.message);
            createLocationDetailsReset();
        }
    }, [createLocationDetailsReducers]);

    useEffect(() => {
        userPreferedCoffeeShopCoordinateReset();
    }, []);

    useEffect(() => {
        if(permission === "denied"){
            userPreferedCoffeeShopCoordinateReset();
        }
    }, [permission]);

    useEffect(() => {
        if (isCanceling4) {
            if (finalizeMeetingReducers.status === 200) {
                navigate("/dashboard")
                setIsCanceling4(false);
                finalizeMeetingReset();
            } else if (finalizeMeetingReducers.status === 302) {
                toast(finalizeMeetingReducers.message, { type: 'danger' });
                setIsCanceling4(false);
                finalizeMeetingReset();
            } else if (finalizeMeetingReducers.status) {
                toast("Status: " + finalizeMeetingReducers.status + ",\n" + finalizeMeetingReducers.message, { type: 'danger' });
                setIsCanceling4(false);
                finalizeMeetingReset();
            }
        }
    }, [finalizeMeetingReducers]);

    const onLoadAutocomplete = useCallback((autocomplete) => {
        setAutocomplete(autocomplete);
    }, []);

    const onPlaceChangedWithoutPermission = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                console.error('Invalid place');
                return;
            }
            setIsLocationWithoutPermission(true);
            setMarkerWithoutPermission(
                {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                }
            );
            // Handle places changed event - for instance, update map center or show the results.
            // Example:
            const { location } = place.geometry;
            const bounds = new window.google.maps.LatLngBounds();

            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(location);
            }
            // map.fitBounds(bounds);
            map.fitBounds(bounds);
            map.setCenter(location);
            map.setZoom(15); // Adjust the zoom level as needed
        }
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                console.error('Invalid place');
                return;
            }
            setIsLocationWithoutPermission(false);
            setMarker(
                {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                }
            );

            // Handle places changed event - for instance, update map center or show the results.
            // Example:
            const { location } = place.geometry;
            const bounds = new window.google.maps.LatLngBounds();

            if (place.geometry.viewport) {
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(location);
            }
            // map.fitBounds(bounds);
            map.fitBounds(bounds);
            map.setCenter(location);
            map.setZoom(15); // Adjust the zoom level as needed
        }
    };

    return (
        <div className='h-full main-background relative'>
            <div className='pt-28 px-8 flex w-full' onClick={() => navigate.goBack()}>
				<BiLeftArrowAlt size={24} color='#D74DFF'/>
			</div>
			<div className="px-8">
                <div className="flex justify-between align-center">
                    <div className="font-[InterRegular] text-4xl">
                        You are coming from?
                    </div>
                    <div className="text-xl">
                        {userType == 'organizer' ? "3/4" : ''}
                    </div>
                </div>
                <div className="font-[InterRegular] text-lg text-[#D74DFF] font-[600]">
                    {getMeetingDetailsByMeetingHashReducers?.data?.title}
                </div>
                <div className="font-[InterMedium]">
                    Tap on map to select coming from
                </div>
                <div className="font-[InterMedium] text-[#949494] text-sm">
                    (Map is showing your approximate location)
                </div>
            </div>
            <div style={isLocationPermissionModalOpen ? { width: '100%' } : { width: '90%' }} className='mx-auto my-4 rounded-[8px] z-30'>
                {isLocationPermissionModalOpen ?
                    <PromptLocationPermissionInstructionModal setIsLocationWithoutPermission={setIsLocationWithoutPermission} setIsLocationPermissionModalOpen={setIsLocationPermissionModalOpen}/>
                    : permission == "granted" && userLat && userLong && isLoaded ?
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={10}
                            onClick={onMapClick}
                            onLoad={onLoadMap}
                        >
                            <MarkerF
                                position={{
                                    lat: marker.lat,
                                    lng: marker.lng
                                }}
                            />
                            <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
                                <input
                                    type="text"
                                    placeholder="Search places..."
                                    style={{
                                        boxSizing: 'border-box',
                                        border: '1px solid transparent',
                                        height: '46px',
                                        padding: '0 12px',
                                        borderRadius: '3px',
                                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                        fontSize: '16px',
                                        outline: 'none',
                                        textOverflow: 'ellipses',
                                        position: 'absolute',
                                        top: '2px',
                                        left: '1px',
                                        right: '1px',
                                    }}
                                />
                            </Autocomplete>
                        </GoogleMap>
                    : isLoaded && centerWithoutPermission.lat && centerWithoutPermission.lng ?
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={centerWithoutPermission}
                            zoom={10}
                            onClick={onMapClickWithoutPermission}
                            onLoad={onLoadMap}
                        >
                            <MarkerF
                                position={{
                                    lat: markerWithoutPermission.lat,
                                    lng: markerWithoutPermission.lng
                                }}
                            />
                            <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChangedWithoutPermission}>
                                <input
                                    type="text"
                                    placeholder="Search places..."
                                    style={{
                                        boxSizing: 'border-box',
                                        border: '1px solid transparent',
                                        height: '46px',
                                        padding: '0 12px',
                                        borderRadius: '3px',
                                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                                        fontSize: '16px',
                                        outline: 'none',
                                        textOverflow: 'ellipses',
                                        position: 'absolute',
                                        top: '2px',
                                        left: '1px',
                                        right: '1px',
                                    }}
                                />
                            </Autocomplete>
                        </GoogleMap>
                        :
                    <div className='w-full h-full flex justify-center items-center'>
                        <ClipLoader size={20} color={'#00ff00'} />
                    </div>
                }
            </div>
            <div className="flex justify-center items-center py-[1.4rem] absolute bottom-[0rem] left-0 right-0 z-40">
                <PrimaryButton
                    backgroundColor={"bg-white border-2 border-[#6A53FD]"}
                    textColor={"text-black"}
                    title={"Back"}
                    onClick={() => {navigate(-1);setIsBackFromLocationScreen(true);}}
                    width={"w-[107px]"}
                />
                <PrimaryButton
                    backgroundColor={"bg-primary"}
                    title={"Next"}
                    textColor={"text-white"}
                    onClick={() => handleSetInviteeLocation()}
                    width={"w-[200px]"}
                />
            </div>
            {isShareDetailsModalOpen ?
                <ShareDetailsModal setIsShareDetailsModalOpen={setIsShareDetailsModalOpen} meetingId={meetingId} />
                :
                ""
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        createMeetingReducers: state.createMeetingReducers,
        getMeetingDaysWithTimeSlotReducers: state.getMeetingDaysWithTimeSlotReducers,
        setMultipleTimeSlotsReducers: state.setMultipleTimeSlotsReducers,
        setStartingLocationReducers: state.setStartingLocationReducers,
        organizerAndUserCoordinateUpdateReducer: state.organizerAndUserCoordinateUpdateReducer,
        finalizeMeetingReducers: state.organizerAndUserCoordinateUpdateReducer,
        createLocationDetailsReducers: state.createLocationDetailsReducers,
        getMeetingDetailsByMeetingHashReducers: state.getMeetingDetailsByMeetingHashReducers,
    }
}
export default connect(mapStateToProps, {
    userPreferedCoffeeShopCoordinateReset,
    createLocationDetails,
    finalizeMeetingReset,
    // setInviteeLocationPreference,
    // createLocationDetails,
    createLocationDetailsReset,
    setIsBackFromLocationScreen,
    // finalizeMeeting,
    // finalizeMeetingReset,
})(YourLocationPage)
