import React, { useEffect, useState } from 'react'
import OTPInput from 'react-otp-input';
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton';
import { login, loginReset, loginResetAll } from '../../store/actions/loginActions';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setAccessToken } from '../../store/actions/utilActions';
import { toast } from 'react-toastify';
import { generateOtpForForgotPin, generateOtpForForgotPinReset } from '../../store/actions/generateOtpForForgotPinActions';

function LoginPage({
    login,
    loginReducers,
    utilReducer,
    setAccessToken,
    loginReset,
    loginResetAll,
    generateOtpForForgotPin,
    generateOtpForForgotPinReset,
    generateOtpForForgotPinReducers
}) {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const { meetingHash } = useParams();
    const [phoneNumber, setPhoneNumber] = useState('');

    function getOS() {
        var userAgent = window.navigator.userAgent,
            platform =  window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Ios';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'Ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'WEB';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'WEB';
        }

        return os;
    }

    useEffect(() => {
        if ((getOS()+ "").toLowerCase() != "ios") {

            const link = `ecco-app://meeting/${meetingHash}`;
            const a = document.createElement("a");
            a.setAttribute('href', link);
            a.setAttribute('target', '_self');
            a.click();
        }
    }, [meetingHash])

    useEffect(() => {
        if (loginReducers?.status === 200) {
            setAccessToken(loginReducers?.data?.accessToken);
            loginReset()
            if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
                navigate("/join-meeting/"+meetingHash)
            }else{
                navigate("/dashboard", { replace: true })
            }
        }else if(loginReducers?.status === 302) {
            toast.error(loginReducers?.message);
            loginReset()
        }else if(loginReducers?.status === 401) {
            toast.error(loginReducers?.message);
            loginReset()
        }
    }, [loginReducers?.status]);

    useEffect(() => {
        if (generateOtpForForgotPinReducers?.status === 200) {
            if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
                navigate("/reset-pin/"+meetingHash)
            }else{
                navigate("/reset-pin/0")
            }
            generateOtpForForgotPinReset();
        }else if (generateOtpForForgotPinReducers?.status === 302) {
            generateOtpForForgotPinReset();
        }else if (generateOtpForForgotPinReducers?.status) {
            generateOtpForForgotPinReset();
        }
      }, [generateOtpForForgotPinReducers]);

    return (
        <div className='h-full main-background'>
            <div className="flex flex-col justify-between h-full p-7">
                <div className="text-11xl font-[InterSemiBold] text-black mt-24">
                    Hello.
                </div>
                <div className="flex-column">
                    <div className="">
                        <label htmlFor="mobile-number" className="text-gray font-[InterSemiBold]">
                            Mobile
                        </label>
                        <input
                            type="tel"
                            placeholder='Enter Your Mobile Number'
                            id='mobile-number'
                            className={`h-[25px] border-b-[1px] mt-1 ${phoneNumber.length > 10 ? "border-error-red" : "border-light-primary-alt"} text-darker-gray text-lg w-[100%]`}
                            // maxLength={10}
                            autoFocus
                            value={phoneNumber}
                            onChange={(e) => {
                                if((e.target.value).length <= 10){
                                    setPhoneNumber(() => e.target.value.replace(/[^0-9]/g, ""))
                                }
                            }}
                        />
                    </div>
                    <div className="mt-10">
                        <label htmlFor="otp" className="text-gray font-[InterSemiBold]">
                            Enter Your Pin
                        </label>
                    </div>
                    <div className="mt-1 -mx-[4%]">
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            inputStyle={{
                                borderBottomWidth: '2px',
                                borderColor: "#8981FD",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "28px",
                                outline: "none"
                            }}
                            // shouldAutoFocus={true}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                </div>
                <div
                    className="-mt-20"
                    onClick={() => {
                        if(phoneNumber.length !== 10){
                            toast.warn("Please enter your phone number.")
                            return;
                        }
                        generateOtpForForgotPin({ "phoneNo": phoneNumber })
                    }}
                >
                    <div className="text-InterBlack text-[#5F61F3] text-lg text-lg text-right cursor-pointer">
                        Forgot pin
                    </div>
                </div>
                <div className="">
                    <PrimaryButton
                        title={"Login"}
                        width={"w-[90%]"}
                        backgroundColor={"bg-primary"}
                        onClick={() => {
                            if(phoneNumber.length !== 10){
                                toast.warn("Please enter your phone number.")
                                return;
                            }
                            if(otp.length !== 4){
                                toast.warn("Pin must be 4 digit.")
                                return;
                            }
                            login({
                                "phoneNumber": phoneNumber ? phoneNumber : utilReducer?.signupContactNo,
                                "pin": otp,
                            })
                        }}
                        isLoading={loginReducers?.loading}
                    />
                    <div
                        className=''
                        onClick={() => {
                            setPhoneNumber('');
                            setOtp('');
                            meetingHash ? navigate('/signup/'+meetingHash) : navigate('/signup')
                        }}
                    >
                        <div className="flex justify-center items-center text-InterBlack text-dark-gray text-lg text-center mt-5">
                            Don't have an account? &nbsp;
                            <div className="text-InterBlack text-primary-alt text-lg mx-2 cursor-pointer">Signup</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        loginReducers: state.loginReducers,
        generateOtpForForgotPinReducers: state.generateOtpForForgotPinReducers,
    }
}

export default connect(mapStateToProps, {
    login,
    setAccessToken,
    loginReset,
    loginResetAll,
    generateOtpForForgotPin,
    generateOtpForForgotPinReset,
})(LoginPage)
