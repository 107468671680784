import React, { useEffect, useRef, useState } from 'react';
import RenderDayTimeView from './RenderDayTimeView';
import HighlightedDayTimeView from './HighlightedDayTimeView';
import { ColorRing } from 'react-loader-spinner';
import '../../../static/styles/main/TimeSelectionView/TimeSelectionView.css'
import { updateOrganiserSelectedTimeSlotArray } from '../../../store/actions/getMeetingDaysWithTimeSlotActions';
import { connect } from 'react-redux';

function InviteeTimeSelectionView({ 
	availableInvitee, 
	timeSlotListDataAll, 
	timeSlotList, 
	meetingDayId,
	updateOrganiserSelectedTimeSlotArray
}) {

	const [curr, changeCurr] = useState({
		cells: [
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
		],
	});
    const [currCreate, changeCurrCreate] = useState({
		cells: [
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
		],
	});
    const [user, changeUser] = useState({
        cells: [
            [''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
			[''],
        ],
    });
    let hours = [
		'00:00:00',
		'00:15:00',
		'00:30:00',
		'00:45:00',
		'01:00:00',
		'01:15:00',
		'01:30:00',
		'01:45:00',
		'02:00:00',
		'02:15:00',
		'02:30:00',
		'02:45:00',
		'03:00:00',
		'03:15:00',
		'03:30:00',
		'03:45:00',
		'04:00:00',
		'04:15:00',
		'04:30:00',
		'04:45:00',
		'05:00:00',
		'05:15:00',
		'05:30:00',
		'05:45:00',
		'06:00:00',
		'06:15:00',
		'06:30:00',
		'06:45:00',
		'07:00:00',
		'07:15:00',
		'07:30:00',
		'07:45:00',
		'08:00:00',
		'08:15:00',
		'08:30:00',
		'08:45:00',
		'09:00:00',
		'09:15:00',
		'09:30:00',
		'09:45:00',
		'10:00:00',
		'10:15:00',
		'10:30:00',
		'10:45:00',
		'11:00:00',
		'11:15:00',
		'11:30:00',
		'11:45:00',
		'12:00:00',
		'12:15:00',
		'12:30:00',
		'12:45:00',
		'13:00:00',
		'13:15:00',
		'13:30:00',
		'13:45:00',
		'14:00:00',
		'14:15:00',
		'14:30:00',
		'14:45:00',
		'15:00:00',
		'15:15:00',
		'15:30:00',
		'15:45:00',
		'16:00:00',
		'16:15:00',
		'16:30:00',
		'16:45:00',
		'17:00:00',
		'17:15:00',
		'17:30:00',
		'17:45:00',
		'18:00:00',
		'18:15:00',
		'18:30:00',
		'18:45:00',
		'19:00:00',
		'19:15:00',
		'19:30:00',
		'19:45:00',
		'20:00:00',
		'20:15:00',
		'20:30:00',
		'20:45:00',
		'21:00:00',
		'21:15:00',
		'21:30:00',
		'21:45:00',
		'22:00:00',
		'22:15:00',
		'22:30:00',
		'22:45:00',
		'23:00:00',
		'23:15:00',
		'23:30:00',
		'23:45:00',
		'23:59:59',
	];
    let hoursAlt = [
		'00:00:00',
		'00:15:00',
		'00:30:00',
		'00:45:00',
		'01:00:00',
		'01:15:00',
		'01:30:00',
		'01:45:00',
		'02:00:00',
		'02:15:00',
		'02:30:00',
		'02:45:00',
		'03:00:00',
		'03:15:00',
		'03:30:00',
		'03:45:00',
		'04:00:00',
		'04:15:00',
		'04:30:00',
		'04:45:00',
		'05:00:00',
		'05:15:00',
		'05:30:00',
		'05:45:00',
		'06:00:00',
		'06:15:00',
		'06:30:00',
		'06:45:00',
		'07:00:00',
		'07:15:00',
		'07:30:00',
		'07:45:00',
		'08:00:00',
		'08:15:00',
		'08:30:00',
		'08:45:00',
		'09:00:00',
		'09:15:00',
		'09:30:00',
		'09:45:00',
		'10:00:00',
		'10:15:00',
		'10:30:00',
		'10:45:00',
		'11:00:00',
		'11:15:00',
		'11:30:00',
		'11:45:00',
		'12:00:00',
		'12:15:00',
		'12:30:00',
		'12:45:00',
		'13:00:00',
		'13:15:00',
		'13:30:00',
		'13:45:00',
		'14:00:00',
		'14:15:00',
		'14:30:00',
		'14:45:00',
		'15:00:00',
		'15:15:00',
		'15:30:00',
		'15:45:00',
		'16:00:00',
		'16:15:00',
		'16:30:00',
		'16:45:00',
		'17:00:00',
		'17:15:00',
		'17:30:00',
		'17:45:00',
		'18:00:00',
		'18:15:00',
		'18:30:00',
		'18:45:00',
		'19:00:00',
		'19:15:00',
		'19:30:00',
		'19:45:00',
		'20:00:00',
		'20:15:00',
		'20:30:00',
		'20:45:00',
		'21:00:00',
		'21:15:00',
		'21:30:00',
		'21:45:00',
		'22:00:00',
		'22:15:00',
		'22:30:00',
		'22:45:00',
		'23:00:00',
		'23:15:00',
		'23:30:00',
		'23:45:00',
		'23:59:59',
	];

	const fetchStartAndEndIndex = (startTime, endTime, users) =>{
		const startIndex = hoursAlt.indexOf(startTime) + 1;
		const endIndex = hoursAlt.indexOf(endTime);
		let tempCurr = curr;
		let tempUser = user;
        for(let i = startIndex; i<=endIndex; i++){
			tempCurr.cells[i][0] = true;
			tempUser.cells[i][0] = users;
        }
		changeCurr(tempCurr);
		changeUser(tempUser);
	}

    const fetchStartAndEndIndex2 = (startTime, endTime) =>{
		const startIndex = hours.indexOf(startTime)+1;
		const endIndex = hours.indexOf(endTime);
		let tempCurrCreate = currCreate;
        for(let i = startIndex; i<=endIndex; i++){
			tempCurrCreate.cells[i][0] = true;
        }
		changeCurrCreate(tempCurrCreate);
	}

	function resetCurr() {
		for(let i = 0; i<curr.cells.length; i++){
			curr.cells[i][0] = false;
		}
	}

	function resetCurrCreate() {
		for(let i = 0; i<currCreate.cells.length; i++){
			currCreate.cells[i][0] = false;
		}
	}

    useEffect(() => {
		if(timeSlotListDataAll?.length > 0){
			resetCurr()
			timeSlotListDataAll?.map((data) => {
				if(data.users !== "null"){
					fetchStartAndEndIndex(data.startTime, data.endTime, data.users);
				}
			})
		}
    }, [timeSlotListDataAll]);


    useEffect(() => {
		if(timeSlotList?.length > 0){
			resetCurrCreate()
			timeSlotList?.map((data) => {
				fetchStartAndEndIndex2(data.startTime, data.endTime);
			})
		}
    }, [timeSlotList]);

	useEffect(() => {
        if(meetingDayId !== undefined){
        	let is_group = false;
            let startTime;
            let endTime;
            let tempTsData = [];
            let tempData = currCreate.cells;
            for (let i = 0; i < tempData?.length; i++) {
                if (tempData[i][0] === true) {
                    if (is_group === true) {
                        endTime = endTime + 1;
                    } else {
                        startTime = i;
                        endTime = i + 1;
                        is_group = true;
                    }
                } else {
                    if (is_group === true) {
                        is_group = false;
                        let tsObject = {
                            startTime: startTime === 1 ? '00:00:00' : hours[startTime - 1],
                            endTime: hours[endTime - 1],
                        };
                        tempTsData.push(tsObject);
                    } else {

                    }
                }
            }
            updateOrganiserSelectedTimeSlotArray(meetingDayId, tempTsData);
        }
    }, [currCreate, meetingDayId]);

	useEffect(() => {
		renderHighlightedDayTimeView();
	}, [curr, user]);

	const renderHighlightedDayTimeView = () => {
		return(
			<HighlightedDayTimeView
				type={'day'}
				curr={curr}
				user={user}
				setUser={changeUser}
				availableInvitee={availableInvitee}
				colWidth={"w-[35%]"}
			/>
		)
	}

    return (
        <div className='time-selection h-full overflow-y-scroll'>
            {curr !== null ? (
				<div style={{ display: 'flex' }} className='bg-white'>
					<RenderDayTimeView type={'time'} colWidth={"w-[28%]"}/>
                    {renderHighlightedDayTimeView()}
					<RenderDayTimeView
						currCreate={currCreate}
						changeCurrCreate={changeCurrCreate}
						colWidth={"w-[35%]"}
						type={'day'}
						date={'Me'}
					/>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<ColorRing
						visible={true}
						height='80'
						width='80'
						ariaLabel='blocks-loading'
						wrapperStyle={{}}
						wrapperClass='blocks-wrapper'
						colors={['#51E24F', '#51E24F', '#51E24F', '#51E24F', '#51E24F']}
					/>
				</div>
			)}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps, {
    updateOrganiserSelectedTimeSlotArray,
})(InviteeTimeSelectionView)
