import './App.css';
import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom"
import OnBoardingPage from './pages/OnBoardingPage/OnBoardingPage';
import OtpPage from './pages/OtpPage/OtpPage';
import RegisterNewUser from './pages/RegisterNewUser/RegisterNewUser';
import Dashboard from './pages/Dashboard/Dashboard';
import SelectAvailableTimesPage from './pages/SelectAvailableTimesPage/SelectAvailableTimesPage';
import YourLocationPage from './pages/YourLocationPage/YourLocationPage';
import SelectCafePage from './pages/SelectCafePage/SelectCafePage';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { persistor, store } from './store/configureStore';
import MeetingHandler from './pages/MeetingHandler/MeetingHandler';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import LoginPage from './pages/LoginPage/LoginPage';
import CreateMeetPartOne from './pages/CreateMeetPartOne CreateMeetPartOne/CreateMeetPartOne CreateMeetPartOne';
import WelcomePage from './pages/WelcomePage/WelcomePage';
import InviteeTimeSelectionPage from './pages/InviteeTimeSelectionPage/InviteeTimeSelectionPage';
import FinalizeTimeScreen from './pages/FinalizeTimeScreen/FinalizeTimeScreen';
import FinalizeLocationPage from './pages/FinalizeLocationPage/FinalizeLocationPage';
import ResetPin from './pages/ResetPin/ResetPin';
import { meetingByUserId } from './store/actions/meetingByUserIdActions';
import { history } from './_helpers/history';
import PermissionsGuide from './pages/PermissionsGuide/PermissionsGuide';


store.subscribe(listener)
let token;
function select(state) {
	return state.utilReducer.token;
}

function listener() {
	token = select(store.getState())
}

function App() {

  const [isWelcomePageOpen, setIsWelcomePageOpen] = useState(true);

    // init custom history object to allow navigation from
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

  useEffect(() => {
    const myTimeout = setTimeout(() => {
      setIsWelcomePageOpen(!isWelcomePageOpen);
    }, 3000);
    return () => {
      clearTimeout(myTimeout);
    }
  }, []);

  useEffect(() => {
    if(token){
      store.dispatch(meetingByUserId());
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="App flex justify-center items-center w-full">
          <div className='main-container h-full overflow-hidden'>
            <Routes>
              <Route exact path="/" element={isWelcomePageOpen ? <WelcomePage /> :token ? <Navigate to='/dashboard' /> : <OnBoardingPage />} />
              <Route exact path="/:meetingHash" element={isWelcomePageOpen ? <WelcomePage /> :token ? <Navigate to='/dashboard' /> : <OnBoardingPage />} />
              <Route exact path="/signup/:meetingHash" element={isWelcomePageOpen ? <WelcomePage /> :token ? <Navigate to='/dashboard' /> : <OnBoardingPage />} />
              <Route exact path="/otp-page/:meetingHash" element={token ? <Navigate to='/dashboard' /> : <OtpPage />} />
              <Route exact path="/login/:meetingHash" element={token ? <Navigate to='/dashboard' /> : <LoginPage />} />
              <Route exact path="/register-new-user/:meetingHash" element={token ? <Navigate to='/dashboard' /> : <RegisterNewUser />} />
              <Route exact path="/reset-pin/:meetingHash" element={<ResetPin />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/create-meet-part-one" element={<CreateMeetPartOne />} />
              <Route exact path="/create-meet-part-two/:meetingId" element={<SelectAvailableTimesPage />} />
              <Route exact path="/your-location/:meetingId/:userType" element={<YourLocationPage />} />
              <Route exact path="/select-cafe" element={<SelectCafePage />} />
              <Route exact path="/meet/:meetingHash" element={<MeetingHandler />} />
              <Route exact path="/meetingHandler/:meetingHash" element={<MeetingHandler />} />
              <Route exact path="/join-meeting/:meetingHash" element={<InviteeTimeSelectionPage />} />
              <Route exact path="/finalize-time/:meetingId" element={<FinalizeTimeScreen />} />
              <Route exact path="/finalize-location/:meetingId" element={<FinalizeLocationPage />} />
              <Route exact path="/permissions-guide" element={<PermissionsGuide />} />
            </Routes>
          </div>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
