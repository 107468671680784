import React, { useEffect, useRef, useState } from 'react';
import RenderDayTimeView from './RenderDayTimeView';
import HighlightedDayTimeView from './HighlightedDayTimeView';
import { ColorRing } from 'react-loader-spinner';
import '../../../static/styles/main/TimeSelectionView/TimeSelectionView.css'

function OrganizerTimeSelectionView({ currCreate, changeCurrCreate }) {

	console.log('====================================');
	console.log("currCreate :", currCreate);
	console.log('====================================');
	const [availableInvitee, setAvailableInvitee] = useState([]);
	const [date, setDate] = useState('');
	const [isRunOnce, setIsRunOnce] = useState(true);
	const [hideInvitySelection, setHideInvitySelection] = useState(true)
	const [isSingleCol, setIsSingleCol] = useState(true);

    return (
        <div className='time-selection h-full overflow-y-scroll'>
            {currCreate !== null ? (
				<div style={{ display: 'flex' }} className='bg-white'>
					<RenderDayTimeView type={'time'} colWidth={"w-[28%]"}/>
					<RenderDayTimeView
						currCreate={currCreate}
						changeCurrCreate={changeCurrCreate}
						hideInvitySelection={hideInvitySelection}
						colWidth={"w-[70%]"}
						type={'day'}
						date={'Me'}
					/>
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<ColorRing
						visible={true}
						height='80'
						width='80'
						ariaLabel='blocks-loading'
						wrapperStyle={{}}
						wrapperClass='blocks-wrapper'
						colors={['#51E24F', '#51E24F', '#51E24F', '#51E24F', '#51E24F']}
					/>
				</div>
			)}
        </div>
    )
}

export default OrganizerTimeSelectionView
