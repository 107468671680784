import React, { useEffect } from 'react';
import { createLocationDetails, createLocationDetailsReset } from '../../store/actions/createLocationDetailsActions';
import { getMeetingCenterLocation, getMeetingCenterLocationReset } from '../../store/actions/getMeetingCenterLocationActions';
import { getLocationPreferences, getLocationPreferencesReset } from '../../store/actions/getLocationPreferencesActions';
import { userPreferedCoffeeShopCoordinateRemoveAllArray } from '../../store/actions/organizerAndUserCoordinateUpdate';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import FinalizeLocationMapView from '../../components/main/FinalizeLocationMapView/FinalizeLocationMapView';
import MapTesting from '../DemoComponent/MapTesting';

function FinalizeLocationPage({
    createLocationDetails,
    createLocationDetailsReset,
    getMeetingCenterLocation,
    getMeetingCenterLocationReset,
    // userPreferedCoffeeShopCoordinateReset,
    getLocationPreferences,
    getLocationPreferencesReset,
    // setInviteeLocationPreference,
    userPreferedCoffeeShopCoordinateRemoveAllArray,
    utilReducer,
    createLocationDetailsReducers,
    organizerAndUserCoordinateUpdateReducer,
    getLocationPreferencesReducers,
    finalizeMeetingReducers,
    getMeetingCenterLocationReducers,
}) {
    const { meetingId } = useParams();
    
    useEffect(() => {
        if (getLocationPreferencesReducers.status === 200) {
            getLocationPreferencesReset();
        } else if (getLocationPreferencesReducers.status === 302) {
            toast.error(getLocationPreferencesReducers.message);
            getLocationPreferencesReset();
        } else if (getLocationPreferencesReducers.status) {
            toast.error("Status: " + getLocationPreferencesReducers.status + ",\n" + getLocationPreferencesReducers.message);
            getLocationPreferencesReset();
        }
    }, [getLocationPreferencesReducers]);

    useEffect(() => {
        if (getMeetingCenterLocationReducers.status === 200) {
            getMeetingCenterLocationReset();
        } else if (getMeetingCenterLocationReducers.status === 302) {
            toast.error(getMeetingCenterLocationReducers.message);
            getMeetingCenterLocationReset();
        } else if (getMeetingCenterLocationReducers.status) {
            toast.error("Status: " + getMeetingCenterLocationReducers.status + ",\n" + getMeetingCenterLocationReducers.message);
            getMeetingCenterLocationReset();
        }
    }, [getMeetingCenterLocationReducers]);

    useEffect(() => {
        if (createLocationDetailsReducers.status === 200) {
            // setFinalLocationPreferenecId(createLocationDetailsReducers?.data?.locationPreferenecId)
            // onOpen()
            createLocationDetailsReset();
        } else if (createLocationDetailsReducers.status === 302) {
            toast.error(createLocationDetailsReducers.message);
            createLocationDetailsReset();
        } else if (createLocationDetailsReducers.status) {
            toast.error("Status: " + createLocationDetailsReducers.status + ",\n" + createLocationDetailsReducers.message);
            createLocationDetailsReset();
        }
    }, [createLocationDetailsReducers]);

    useEffect(() => {
        // getLocationPreferences({ meetingId: meetingId })
        // getMeetingCenterLocation({ meetingId: meetingId })
        getMeetingCenterLocationReset()
        getMeetingCenterLocation({ meetingId: meetingId })
        userPreferedCoffeeShopCoordinateRemoveAllArray()
    }, []);



    return (
        <div className='h-full main-background'>
            <div className='h-full relative'>
                <div className="pt-28 px-8">
                    <div className="flex justify-between align-center">
                        <div className="font-[InterRegular] text-4xl">
                            Select Available Times
                        </div>
                        <div className="text-lg">
                            2/3
                        </div>
                    </div>
                    <div className="font-[InterRegular] text-lg">
                        Coffee Meet Austin
                    </div>
                </div>
                {/* <FinalizeLocationMapView /> */}
                <div className='mx-6'>
                    {/* <MapTesting />  */}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        createLocationDetailsReducers: state.createLocationDetailsReducers,
        organizerAndUserCoordinateUpdateReducer: state.organizerAndUserCoordinateUpdateReducer,
        getLocationPreferencesReducers: state.getLocationPreferencesReducers,
        finalizeMeetingReducers: state.finalizeMeetingReducers,
        getMeetingCenterLocationReducers: state.getMeetingCenterLocationReducers
    }
}

export default connect(mapStateToProps, {
    createLocationDetails,
    createLocationDetailsReset,
    getMeetingCenterLocation,
    getMeetingCenterLocationReset,
    // userPreferedCoffeeShopCoordinateReset,
    getLocationPreferences,
    getLocationPreferencesReset,
    // setInviteeLocationPreference,
    userPreferedCoffeeShopCoordinateRemoveAllArray,
})(FinalizeLocationPage)
