import { toast } from 'react-toastify';
import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'
import { logout } from './utilActions';
import { history } from '../../_helpers/history';
import localStorage from 'redux-persist/es/storage';


export const meetingByUserId = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.MEETING_BY_USER_ID_REQUEST,
	});

	await Axios(
		"get",
		end_points.meeting_by_user_id,
		null,
		"application/json",
		true
	)
	.then((res) => {
		console.log(`${end_points.meeting_by_user_id} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.MEETING_BY_USER_ID_SUCCESS,
				payload: res?.data,
			});
			if(res.data.status === 401){
				toast.warn("Session expired login again")
				dispatch(logout());
				localStorage.removeItem('persist:root');
        		history.navigate('/login');
			}
		}
	})
	.catch((error) => {
		console.log(`${end_points.meeting_by_user_id} Error response:`, error);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.MEETING_BY_USER_ID_FAILED,
				payload: error?.response?.data,
			});
			if(error.response.status === 401){
				toast.warn("Session expired login again")
				dispatch(logout());
				localStorage.removeItem('persist:root');
        		history.navigate('/login');
			}
		}
	})
};

export const meetingByUserIdReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.MEETING_BY_USER_ID_RESET,
	});
};

export const meetingByUserIdResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.MEETING_BY_USER_ID_RESET_ALL,
	});
};
