import React, {  useEffect, useRef, useState } from 'react';
//import TableDragSelect from "react-table-drag-select";
import TableDragSelect from './TableDragSelect';
//import "react-table-drag-select/style.css";
// import '../newstyles.css';

import hours from './Hours';
import TableDtagSelectDisableView from './TableDtagSelectDisableView';

function HighlightedDayTimeView(props) {
	
	const {
		type,
		curr,
		changeCurr,
		user,
		availableInvitee,
		colWidth,
		isFinalizingMeet,
		setFinalTimeData,
	} = props;

	function handleChange(cells) {
		changeCurr({ cells });
	}

	return (
		<>
			{curr && (
				<div className={colWidth}>
					{type === 'day' && (
						<TableDtagSelectDisableView
							value={curr.cells}
							users={user.cells}
							allUsers={availableInvitee}
							onChange={handleChange}
							isFinalizingMeet={isFinalizingMeet}
							setFinalTimeData={setFinalTimeData}>
							<tr style={{ position: "relative"}}>
								<td
									disabled
									style={{
										backgroundColor: '#5C6AA8',
										color: '#fff',
										fontSize: '12px',
										position: "sticky",
										top: "0px"
									}}>
									Other Invitees
								</td>
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
							<tr>
								<td disabled className='mon' />
							</tr>
						</TableDtagSelectDisableView>
					)}
				</div>
			)}
		</>
	);
}

export default HighlightedDayTimeView;
