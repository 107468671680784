import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { logout } from '../../store/actions/utilActions';

function MeetingHandler({ utilReducer, logout }) {
    const {meetingHash} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        // (utilReducer.token ?
        //     navigate('/join-meeting/'+meetingHash)
        //     :
        //     navigate('/'+meetingHash)
        // )

        // login every time before joining meeting
        if(meetingHash){
            logout();
            navigate('/'+meetingHash)
        }
    }, [meetingHash])

    return (
        <div></div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
    }
}

export default connect(mapStateToProps, {
    logout
})(MeetingHandler)
