import { actionTypes } from '../types';

export default (
	state = {
		organizer: {

		},
        preferredLocations: [],
        inviteePreferredLocations: [],
	},
	action,
) => {
	switch (action.type) {
		case actionTypes.ORGANIZER_CO_ORDINATES:
			return {
				...state,
				organizer: {
					addressComponents: action.payload.addressComponents,
					latitude: action.payload.latitude,
					longitude: action.payload.longitude,
				},
			};
		case actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH:

            let finalArray = [
							// ...state.preferredLocations,
							{
								business_status: "NA",
								location: {
								  latitude: action.payload.latitude,
								  longitude: action.payload.longitude
								},
								name: action.payload.name,
								photo_reference: "img",
								place_id: action.payload.placeId,
								rating: 0,
								user_ratings_total: 0,
								vicinity: action.payload.visinity
							}
						];
            // alert(JSON.stringify(finalArray));
			return {
				...state,
				preferredLocations: finalArray,
			};
			case actionTypes.INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH:

            let inviteeFinalArray = [
							{
								business_status: "NA",
								location: {
								  latitude: action.payload.latitude,
								  longitude: action.payload.longitude
								},
								name: action.payload.name,
								photo_reference: "img",
								place_id: action.payload.placeId,
								rating: 0,
								user_ratings_total: 0,
								vicinity: action.payload.visinity,
								locationPreferenecId: action.payload.locationPreferenecId
							}
						];
            // alert(JSON.stringify(finalArray));
			return {
				...state,
				inviteePreferredLocations: inviteeFinalArray,
			};
		case actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE:

            // alert(action?.payload?.latitude);

            let filtered2 = state.preferredLocations.filter(
							(item) => item.latitude !== action?.payload?.latitude,
						);
            // alert(JSON.stringify(filtered2));

			return {
				...state,
				preferredLocations: filtered2,
			};
		case actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE_ALL:

			return {
				preferredLocations: [],
			};
		case actionTypes.INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE:

			return {
				...state,
				inviteePreferredLocations: [],
			};
		case actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_RESET:
			return {
				organizer: {

				},
				preferredLocations: []
			};
		default:
			return state;
	}
};
