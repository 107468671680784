import React from 'react'
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton'
import { useNavigate } from 'react-router-dom';

function SelectCafePage() {
  const navigate = useNavigate();

    return (
        <div className='h-full main-background'>
            <div className="pt-28 px-8">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.248 26.7243C12.7849 27.1295 12.0809 27.0826 11.6757 26.6195L1.2757 14.7338C0.908101 14.3136 0.908101 13.6864 1.2757 13.2662L11.6757 1.38054C12.0809 0.917403 12.7849 0.870473 13.248 1.27572C13.7112 1.68096 13.7581 2.38492 13.3529 2.84806L4.56991 12.8857L25.8857 12.8857C26.5011 12.8857 27 13.3846 27 14C27 14.6154 26.5011 15.1143 25.8857 15.1143L4.56991 15.1143L13.3529 25.1519C13.7581 25.6151 13.7112 26.319 13.248 26.7243Z" fill="#D74DFF" stroke="#D74DFF" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="flex justify-between align-center">
                    <div className="">
                        Select Cafe
                    </div>
                    <div className="">
                        4/4
                    </div>
                </div>
                <div className="">
                    Coffee Meet Austin
                </div>
            </div>
            <div className="">
                Wait for guest location.
            </div>
            <div className="">

            </div>
            <div className="flex justify-center items-center">
                <PrimaryButton backgroundColor={"border-2 border-[#6A53FD]"} textColor={"text-black"} title={"Back"} onClick={() => navigate(-1)} width={"w-[107px]"} />
                <PrimaryButton backgroundColor={"bg-secondary"} title={"Save"} textColor={"text-white"} onClick={() => navigate("/")} width={"w-[200px]"} />
            </div>
        </div>
    )
}

export default SelectCafePage