import React, { useEffect, useState } from 'react'
import DatePicker from 'react-horizontal-datepicker'
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createMeeting } from '../../store/actions/createMeetingActions';
import moment from "moment";
import { meetingByUserId, meetingByUserIdReset, meetingByUserIdResetAll } from '../../store/actions/meetingByUserIdActions';
import { meetingByMeetingDate, meetingByMeetingDateReset, meetingByMeetingDateResetAll } from "../../store/actions/meetingByMeetingDateActions"
import { clock } from '../../static/constants/svgConstants';
import FinalizeMeetModal from '../../components/main/CancelMeetingMeetModal/CancelMeetingMeetModal';
import CancelMeetModal from '../../components/main/CancelMeetModal/CancelMeetModal';
import { ClipLoader } from 'react-spinners'
import { LuClock8 } from "react-icons/lu";
import { SlLocationPin } from "react-icons/sl";
import { VscCopy } from "react-icons/vsc";
import Calendar from '../../components/common/Calendar/Calendar';
import MeetingDetailsModal from '../../components/main/MeetingDetailsModal/MeetingDetailsModal';
import PromptDownloadAppModal from '../../components/main/PromptDownloadAppModal/PromptDownloadAppModal';
import { BsChevronDown } from 'react-icons/bs';
import { toast } from 'react-toastify';
import CancelMeetingMeetModal from '../../components/main/CancelMeetingMeetModal/CancelMeetingMeetModal';
import { IoReload } from "react-icons/io5";
import { MdOutlineShare } from "react-icons/md";
import { setIsBackFromLocationScreen } from '../../store/actions/utilActions';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import AddToCalendarModal from '../../components/main/AddToCalendarModal/AddToCalendarModal';

function Dashboard({
    createMeeting,
    createMeetingReducers,
    utilReducer,
    meetingByUserId,
    meetingByUserIdReducers,
    meetingByMeetingDate,
    meetingByMeetingDateReducers,
    meetingByMeetingDateReset,
    meetingByUserIdReset,
    meetingByUserIdResetAll,
    meetingByMeetingDateResetAll,
    setIsBackFromLocationScreen,
}) {

    const navigate = useNavigate();
    const [isPopUpOpen, setIsPopUpOpen] = useState(true);
    const [isPopUpOpenFinalizeMeet, setIsPopUpOpenFinalizeMeet] = useState(true);
    const [isPopUpOpenCancel, setIsPopUpOpenCancel] = useState(true);
    const [selectedDate, setSelectedDate] = useState(moment(new Date()).format('DD/MM/YYYY').toString());
    const [showDetails, setShowDetails] = useState(false);
    const [data, setData] = useState(null);
    const [isMeetingDetailsModalOpen, setIsMeetingDetailsModalOpen] = useState(false)
    const [meetDetailsModalData, setMeetDetailsModalData] = useState({});
    const [isPromptDownloadAppModal, setIsPromptDownloadAppModal] = useState(false)
    const [selectedMeeting, setSelectedMeeting] = useState({});

    const showDetailsHandle = (dayStr) => {
        setData(dayStr);
        setShowDetails(true);
    };

    // function renderCalender() {
    //     let data = {
    //         "title" : "hellow",
    //         "startDate" : "2024-03-05",
    //         "startTime" : "13:12:23"
    //     }
    //     return <AddToCalendarButton
    //     name={data.title}
    //     startDate={data.startDate}
    //     options={['Apple','Google','Yahoo','iCal']}
    //     timeZone="America/Los_Angeles"
    //     // startTime={data.startTime}
    //   ></AddToCalendarButton>
    // }

    function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
            dates.push(moment(new Date(date)).format('DD/MM/YYYY').toString());
            date.setDate(date.getDate() + 1);
        }
        return dates;
    }

    const handleCancelMeet = (meeting) => {
        setSelectedMeeting(meeting);
    }

    useEffect(() => {
        if (utilReducer.dashboardSelectedDate && utilReducer.token && !meetingByUserIdReducers.loading && meetingByUserIdReducers.status != 401) {
            meetingByUserIdResetAll();
            meetingByMeetingDateResetAll();
            meetingByUserId();
            meetingByMeetingDate({ "meetingDate": utilReducer.dashboardSelectedDate })
            return;
        }
    }, [utilReducer.dashboardSelectedDateUpdatedTime, utilReducer.token]);

    useEffect(() => {
        setIsBackFromLocationScreen(true);
    }, []);

    return (
        <div className='relative h-full flex flex-col overflow-y-scroll'>
            {isMeetingDetailsModalOpen ?
                <MeetingDetailsModal setIsMeetingDetailsModalOpen={setIsMeetingDetailsModalOpen} meetDetailsModalData={meetDetailsModalData} />
                :
                null
            }
            {isPromptDownloadAppModal ?
                <PromptDownloadAppModal setIsPromptDownloadAppModal={setIsPromptDownloadAppModal} />
                :
                null
            }
            {!isPopUpOpenCancel ?
                <CancelMeetingMeetModal setIsPopUpOpenFinalizeMeet={setIsPopUpOpenCancel} selectedMeeting={selectedMeeting} />
                :
                null
            }
            <div className='w-full secondary-background p-7'>
                <div className="mt-5 relative">
                    <div className="font-[InterRegular] text-4xl text-white mt-20">
                        My Meet
                    </div>
                    <div className="mt-6">
                        <Calendar showDetailsHandle={showDetailsHandle} setSelected={setSelectedDate} />
                    </div>
                </div>
            </div>
            <div className='flex flex-1 w-full max-h-[57%]'>
                <div className="flex flex-col justify-between items-center overflow-y-scroll w-full p-5">
                    <div className="flex items-center w-full">
                        <div className="font-[InterRegular] text-4xl mr-2">
                            My Meeting (Organizer)
                        </div>
                        <div className='' onClick={() => { meetingByUserId(); meetingByMeetingDate({ "meetingDate": selectedDate }) }}>
                            <IoReload color='#D74DFF' size='24' />
                        </div>
                    </div>
                    {!meetingByMeetingDateReducers?.loading ?
                        meetingByMeetingDateReducers?.data?.length > 0 && meetingByMeetingDateReducers?.data?.filter((meet) => { return meet.role === "Organizer" && meet.status !== "CANCELLED" && meet?.attendees !== 0 })?.length > 0 ?
                            meetingByMeetingDateReducers?.data?.filter((meet) => { return meet.role === "Organizer" && meet.status !== "CANCELLED" && meet?.attendees !== 0 })?.map((meeting) => {
                                return (
                                    <div className="flex-column justify-between items-center w-full mt-5" >
                                        <div className="flex justify-between items-center">
                                            <p className="font-[InterRegular] text-lg text-primary font-[600] tracking-wide cursor-pointer"
                                                onClick={() => {
                                                    if (meeting.status === "SCHEDULED") {
                                                        setIsPopUpOpenCancel(!isPopUpOpenCancel)
                                                        handleCancelMeet(meeting);
                                                    }
                                                    else {
                                                        return
                                                    }
                                                }}
                                            >{meeting.title}</p>
                                            <p className={`${meeting.status === "SCHEDULED" ? "text-[#3AB946]" : "text-[#FE7F7F]"} uppercase text-sm flex items-center`}>
                                                {meeting.status === "DRAFT" ? "Pending" : meeting.status}
                                                <MdOutlineShare color='#6842E5' className='ml-2 cursor-pointer' onClick={() => { navigator.clipboard.writeText(meeting.meetingURL); alert("meeting link is copied") }} />
                                            </p>
                                        </div>
                                        <p className="font-[InterRegular] text-sm flex items-center w-[60%] whitespace-nowrap mr-3 my-1">
                                            <LuClock8 color='#D74DFF' />
                                            {meeting.status === "DRAFT" ?
                                                <p className="text-sm ml-2">No. of attendees ({meeting.attendees ? meeting.attendees : '0'})</p>
                                                :
                                                <p className="text-sm ml-2">{meeting?.meetingDate ? moment(meeting?.meetingDate, "YYYY-MM-DD").format("ddd, DD MMM YYYY") : "-- : --"}</p>
                                            }
                                        </p>
                                        <p
                                            className="font-[InterRegular] flex items-start cursor-pointer"
                                            onClick={() => {
                                                if (meeting.status === "SCHEDULED") {
                                                    window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${meeting.latitude},${meeting.longitude}`)
                                                }
                                                else {
                                                    return
                                                }
                                            }}
                                        >
                                            <SlLocationPin color='#6842E5' className='mr-2 mt-1' />
                                            <p className='text-wrap w-[96%]'>
                                                {meeting.location ? meeting.location : 'Waiting'}
                                            </p>
                                        </p>
                                    </div>
                                )
                            })
                            :
                            <div className='text-center mt-2'>No Meet Today</div>
                        :
                        <div className='w-full h-[80px] flex justify-center items-center'>
                            <ClipLoader size={20} color={'#00ff00'} />
                        </div>
                    }
                    <div className="font-[InterRegular] w-full text-4xl mt-4">
                        Meeting (Invitee)
                    </div>
                    {!meetingByMeetingDateReducers?.loading ?
                        meetingByMeetingDateReducers?.data?.length > 0 && meetingByMeetingDateReducers?.data?.filter((meet) => { return meet.role === "Attendee" && meet.status !== "CANCELLED" && meet?.attendees !== 0 })?.length > 0 ?
                            meetingByMeetingDateReducers?.data?.filter((meet) => { return meet.role === "Attendee" && meet.status !== "CANCELLED" && meet?.attendees !== 0 })?.map((meeting) => {
                                return (
                                    <div className="flex-column justify-between items-center w-full mt-5 relative">
                                        <div className="flex justify-between items-center">
                                            <p
                                                className="font-[InterRegular] text-lg text-primary font-[600] tracking-wide cursor-pointer"
                                                onClick={() => {
                                                    setIsPopUpOpenCancel(!isPopUpOpenCancel)
                                                    handleCancelMeet(meeting);
                                                    // navigate('/join-meeting/kPtj6nVT')
                                                    // navigate('/your-location/2148/invitee')
                                                }}
                                            >{meeting.title}</p>

                                            <p className={`${meeting.status === "SCHEDULED" ? "text-[#3AB946]" : "text-[#FE7F7F]"} uppercase text-sm flex items-center`}>
                                                {meeting.status === "DRAFT" ? "Not Finalized" : meeting.status}
                                                <MdOutlineShare color='#6842E5' className='ml-2 cursor-pointer'
                                                    onClick={() => { navigator.clipboard.writeText(meeting.meetingURL); toast.success("meeting link is copied") }}
                                                />
                                            </p>
                                        </div>
                                        <p className="font-[InterRegular] text-sm flex items-center w-[60%] whitespace-nowrap mr-3 my-1">
                                            <LuClock8 color='#D74DFF' />
                                            {meeting.status === "DRAFT" ?
                                                <p className="text-sm ml-2">No. of attendees ({meeting.attendees ? meeting.attendees : '0'})</p>
                                                :
                                                <p className="text-sm ml-2">{meeting?.meetingDate ? moment(meeting?.meetingDate, "YYYY-MM-DD").format("ddd, DD MMM YYYY") : "-- : --"}</p>
                                            }
                                        </p>
                                        <p
                                            className="font-[InterRegular] flex items-start cursor-pointer"
                                            onClick={() => {
                                                if (meeting.status === "SCHEDULED") {
                                                    window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${meeting.latitude},${meeting.longitude}`)
                                                }
                                                else {
                                                    return
                                                }
                                            }}
                                        >
                                            <SlLocationPin color='#6842E5' className='mr-2 mt-1' />
                                            <p className='text-wrap w-[96%]'>
                                                {meeting.location ? meeting.location : 'Waiting'}
                                            </p>
                                        </p>
                                    </div>
                                )
                            })
                            :
                            <div className='text-center mt-2 mb-[10%]'>No Meet Today</div>
                        :
                        <div className='w-full h-[80px] flex justify-center items-center'>
                            <ClipLoader size={20} color={'#00ff00'} />
                        </div>
                    }
                </div>
                
            </div>
            <div className="my-4">
                <PrimaryButton
                    title={"Create New Meet"}
                    width={"w-[90%]"}
                    backgroundColor={"bg-secondary"}
                    onClick={() => {
                        // navigate("/create-meet-part-one")
                        setIsPromptDownloadAppModal(true)
                    }}
                />
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        createMeetingReducers: state.createMeetingReducers,
        meetingByUserIdReducers: state.meetingByUserIdReducers,
        meetingByMeetingDateReducers: state.meetingByMeetingDateReducers,
    }
}

export default connect(mapStateToProps, {
    createMeeting,
    meetingByUserId,
    meetingByMeetingDate,
    meetingByMeetingDateReset,
    meetingByUserIdReset,
    meetingByUserIdResetAll,
    meetingByMeetingDateResetAll,
    setIsBackFromLocationScreen,
})(Dashboard)