import { actionTypes } from '../types';

const initialState = {
	loading: false,
	status: '',
	message: '',
	data: [],
	emptyTimeSlot: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actionTypes.GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_SUCCESS:
			return {
				...state,
				loading: false,
				status: action?.payload?.status,
				message: action?.payload?.message,
				data: action?.payload?.response,
			};
		case actionTypes.GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_FAILED:
			return {
				...state,
				loading: false,
				status: action?.payload?.status,
				message: action?.payload?.message,
			};
		case actionTypes.GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_RESET:
			return {
				...state,
				loading: false,
				status: '',
				message: '',
			};
		case actionTypes.GET_MEETING_DAYS_WITH_ALL_TIME_SLOT_RESET_ALL:
			return {
				...state,
				loading: false,
				status: '',
				message: '',
				data: {},
				emptyTimeSlot: [],
			};
		case actionTypes.ARRAY_OF_OBJECT_TO_GET_USER_TIME_SLOT:
			return {
				...state,
				emptyTimeSlot: action?.payload,
			};
		case actionTypes.OBJECT_TO_Add_USER_TIME_SLOT_TO_OBJECT_ARRAY:
			console.log("action?.payload?.objectData :", action?.payload?.objectData)
			const index = state.emptyTimeSlot.findIndex(
				(meeting) => meeting.meetingDayId === action?.payload?.meetingDayId,
			);
            console.log('index:', index);
			// action?.payload?.objectData.map((item) => {
			// 	state.emptyTimeSlot[index]?.timeSlotList.push(item);
			// })
			state.emptyTimeSlot[index]["timeSlotList"] = action?.payload?.objectData
            console.log('state.emptyTimeSlot:', state.emptyTimeSlot);
			return {
				...state,
				emptyTimeSlot: state.emptyTimeSlot,
			};
		default:
			return state;
	}
};

