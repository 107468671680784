import React from 'react'

function CancelMeetModal({ setIsPopUpOpenCancel }) {
    return (
        <div className="space-y-4 rounded-[19px] md:flex md:space-y-0 md:space-x-4 absolute z-10 w-[30%]">
            <div className="relative w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-start justify-between p-4">
                        <div className="text-xl font-semibold text-gray-900 dark:text-light-primary-alt">
                            Coffee Meet Austin
                        </div>
                        <button type="button" onClick={() => setIsPopUpOpenCancel(true)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:rounded-lg text-sm w-5 h-2 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-6 flex justify-center items-center">
                        <svg className='' width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.41663 50.7502L29 4.8335L55.5833 50.7502H2.41663ZM29.2522 43.6814C29.7688 43.6814 30.1982 43.5067 30.5406 43.1573C30.8829 42.8078 31.0541 42.3749 31.0541 41.8583C31.0541 41.3418 30.8794 40.9123 30.53 40.57C30.1806 40.2276 29.7476 40.0564 29.2311 40.0564C28.7145 40.0564 28.285 40.2311 27.9427 40.5805C27.6003 40.93 27.4291 41.363 27.4291 41.8795C27.4291 42.396 27.6038 42.8255 27.9532 43.1679C28.3027 43.5102 28.7357 43.6814 29.2522 43.6814ZM27.4291 36.9752H31.0541V23.4418H27.4291V36.9752Z" fill="#F27686" />
                        </svg>
                        <div className="mx-4 font-[InterSemiBold]">
                            Do you really want to cancel<br/>
                            “Coffee Meet Austin”?
                        </div>
                    </div>
                    <div className="flex justify-between items-center p-6 space-x-2">
                        <button type="button" onClick={() => setIsPopUpOpenCancel(true)} className="text-primary bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-2xl text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Close</button>
                        <button type="button" onClick={() => setIsPopUpOpenCancel(true)} className="text-white text-lg bg-primary hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Cancel & Remove</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelMeetModal