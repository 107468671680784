import React from 'react'
import PrimaryButton from '../../common/PrimaryButton/PrimaryButton';
import {isMobile} from 'react-device-detect';
import { toast } from 'react-toastify';


function PromptDownloadAppModal({ setIsPromptDownloadAppModal }) {



    function getOS() {
        var userAgent = window.navigator.userAgent,
            platform =  window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'WEB';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'Ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'WEB';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'WEB';
        }

        return os;
    }

    return (
        <div className="space-y-4 rounded-[19px] md:flex md:space-y-0 md:space-x-4 absolute z-10 w-full top-0 bottom-0 p-4 bg-black bg-opacity-60">
            <div className="relative w-full max-w-2xl max-h-full mt-[50%]">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className='px-4 pt-5'>
                        <h3 className="text-xl font-semibold">
                            Download the App!
                        </h3>
                        <p>
                            Please download the app to enjoy the full experience.
                        </p>
                    </div>
                    <div className="flex justify-between items-center p-6 space-x-2">
                        <PrimaryButton
                            title={"Download App"}
                            width={"w-[60%]"}
                            backgroundColor={"bg-white text-secondary border-2 border-secondary"}
                            onClick={() => {
                                setIsPromptDownloadAppModal(false)
                                if(getOS() === "Android"){
                                    window.location.href = "https://play.google.com/store/apps/details?id=com.infominez.eccozip";
                                }else if(getOS() === "Ios"){
                                    window.location.href = "https://apps.apple.com/us/app/ecco-app/id6466419321";
                                }else if(getOS() === "WEB"){
                                    toast.info("Please use a mobile device  ")
                                }

                                // console.log(navigator);
                                // if (isAndroid) {
                                //     window.location.href = "https://play.google.com/store/apps/details?id=com.infominez.eccozip";
                                // }else if(isIOS) {
                                //     window.location.href = "https://apps.apple.com/app/capsigo/id1547746310";
                                // } else{
                                //     window.location.href = "https://capsigo.com";
                                // }
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromptDownloadAppModal
