import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
// import Calendar from 'react-multi-date-picker';
import "../../static/styles/main/ScheduleMeetPage/ScheduleMeetPage.css"
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { createMeeting, createMeetingReset } from '../../store/actions/createMeetingActions';
import { connect } from 'react-redux';
import moment from "moment";
import { toast } from 'react-toastify';
// import './CreateMeetPartOne.css';
import 'react-calendar/dist/Calendar.css'


let selectedCalDates = {};

function CreateMeetPartOne({
  createMeeting,
  createMeetingReset,
  createMeetingReducers
}) {
  // const [value, setValue] = useState(new Date());
  const navigate = useNavigate();
  const [meetingTitle, setMeetingTitle] = useState("");

  const [selectedDates, setSelectedDates] = useState([moment(new Date()).format('DD/MM/YYYY')]);
  const [count, setCount] = useState(0);

  const handleCreateMeet = () => {
    
  }
  
  // const handleChangeDate = (e) => {
  //   if(selectedDates.length < 2){
      // let datesCollection = [];\
      // console.log("selectedDates?.map((item) => { return item === e}) :", selectedDates?.map((item) => { return item === e}));
      /* if(selectedDates?.map((item) => { return item == e}).length === 1){
        setSelectedDates(selectedDates?.map((item) => { return item !== e}));
      }else{
        setSelectedDates([...selectedDates, e]);
      } */
      /* if(selectedDates?.map((item) => { return console.log("item",item);
    })){
      } */
    //   setSelectedDates([...selectedDates, e]);
    //   selectedDates?.map((item) => { })
    // }
    // else if(selectedDates.length === 3){

  // const handleChangeDate = () => {
  //   setSelectedDates.push()
  // }
  useEffect(() => {
    if (createMeetingReducers.status === 200) {
      toast(meetingTitle + " initiated successfully", { type: 'success' });
      selectedCalDates = {};
      navigate('/create-meet-part-two/'+createMeetingReducers?.data?.meeting?.meetingId)
      createMeetingReset();
    } else if (createMeetingReducers.status === 302) {
      toast(createMeetingReducers.message, { type: 'danger' });
      createMeetingReset();
    } else if (createMeetingReducers.status) {
      toast("Status: " + createMeetingReducers.status + ",\n" + createMeetingReducers.message, { type: 'danger' });
      createMeetingReset();
    }
  }, [createMeetingReducers]);

  console.log('createMeetingReducers', createMeetingReducers);

  return (
    <div className='h-full main-background'>
      <div className="pt-28 px-8">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M13.248 26.7243C12.7849 27.1295 12.0809 27.0826 11.6757 26.6195L1.2757 14.7338C0.908101 14.3136 0.908101 13.6864 1.2757 13.2662L11.6757 1.38054C12.0809 0.917403 12.7849 0.870473 13.248 1.27572C13.7112 1.68096 13.7581 2.38492 13.3529 2.84806L4.56991 12.8857L25.8857 12.8857C26.5011 12.8857 27 13.3846 27 14C27 14.6154 26.5011 15.1143 25.8857 15.1143L4.56991 15.1143L13.3529 25.1519C13.7581 25.6151 13.7112 26.319 13.248 26.7243Z" fill="#D74DFF" stroke="#D74DFF" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div className="flex justify-between align-center">
          <div className="font-[InterSemibold] text-4xl mt-2">
            Schedule Meet
          </div>
          <div className="">
            1/3
          </div>
        </div>
        <div className="mt-5">
          <label htmlFor="mobile-number" className="text-gray font-[InterSemiBold]">
            Meet-up Title  *
          </label>
          <input
            type="text"
            placeholder='Enter Meet Title'
            id='mobile-number'
            className="h-[25px] border-b-[1px] mt-1 border-light-primary-alt text-darker-gray text-lg w-[100%]"
            onChange={(e) => setMeetingTitle(e.target.value)}
          />
        </div>
        <div className="mt-10">
          <div className="font-[InterSemibold] text-lg">
            Select Available Date(s)
          </div>
          <div className="mt-5">
            <Calendar
              className="rounded-xl shadow-lg p-5 text-lg fort-[InterRegular]"
              // onChange={handleChangeDate()}
              onClickDay={day => {
                console.log('selected day', day);
                if (moment(day).isBefore(new Date(), 'day')) {
                  toast("Can't select past date", { type: 'warning' })
                  setSelectedDates([])
                  return;
                }
                setSelectedDates([moment(new Date(day)).format('DD/MM/YYYY').toString()])
                // if (Object.keys(selectedCalDates).length === 3 && !selectedCalDates[day]) {
                //   toast("Can't select more then 3 days", { type: 'warning' })
                //   return;
                // }
                // if (!selectedCalDates[day]) {
                //   selectedCalDates[day] = { selected: true, selectedColor: '#D74DFF' }
                //   setSelectedDates(Object.keys(selectedCalDates)?.map((item) => { return moment(new Date(item)).format("DD/MM/YYYY") }))
                // } else {
                //   delete selectedCalDates[day]
                //   setSelectedDates(Object.keys(selectedCalDates)?.map((item) => { return moment(new Date(item)).format("DD/MM/YYYY") }))
                // }
                setCount(count + 1);
              }}
            // defaultActiveStartDate={selectedDates}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-20">
        <PrimaryButton
          backgroundColor={"border-2 border-[#6A53FD]"}
          textColor={"text-black"}
          title={"Back"} 
          onClick={() => navigate(-1)}
          width={"w-[25%]"}
          isLoading={createMeetingReducers?.loading}
        />
        <PrimaryButton
          backgroundColor={"bg-primary"}
          title={"Next"}
          textColor={"text-white"}
          onClick={() => {
            if (meetingTitle.length < 3) {
              toast.warning("Please enter a valid meeting title")
              return;
            }
            createMeeting({
              "meetingDates": selectedDates,
              // "meetingDates": ["24/09/2023", "26/09/2023", "30/09/2023"],
              "meetingTitle": meetingTitle,
            })
          }}
          width={"w-[65%]"}
          isDisabled={selectedDates.length <= 0 || !meetingTitle}
          isLoading={createMeetingReducers?.loading}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    createMeetingReducers: state.createMeetingReducers,
  }
}

export default connect(mapStateToProps, {
  createMeeting,
  createMeetingReset
})(CreateMeetPartOne)