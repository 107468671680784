import React, { useEffect, useRef, useState } from 'react';
import RenderDayTimeView from './RenderDayTimeView';
import HighlightedDayTimeView from './HighlightedDayTimeView';
import { ColorRing } from 'react-loader-spinner';
import '../../../static/styles/main/TimeSelectionView/TimeSelectionView.css'
function FinalizeTimeSelectionView({ curr, user, setUser, availableInvitee, setAvailableInvitee, isFinalizingMeet, setFinalTimeData }) {

	const [date, setDate] = useState('');
	const [isSingleCol, setIsSingleCol] = useState(true);


    return (
        <div className='time-selection h-full overflow-y-scroll'>
            {curr !== null ? (
				<div style={{ display: 'flex' }} className='bg-white'>
					<RenderDayTimeView type={'time'} colWidth={"w-[28%]"}/>
                    {curr.length !== 0 && (
						<HighlightedDayTimeView
							type={'day'}
							curr={curr}
							// changeCurr={changeCurr}
							user={user}
							setUser={setUser}
							availableInvitee={availableInvitee}
							setAvailableInvitee={setAvailableInvitee}
							date={date}
							setDate={setDate}
							isSingleCol={isSingleCol}
    						colWidth={"w-[70%]"}
    						isFinalizingMeet={isFinalizingMeet}
    						setFinalTimeData={setFinalTimeData}
						/>
					)}
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						width: '100%',
						height: '100%',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<ColorRing
						visible={true}
						height='80'
						width='80'
						ariaLabel='blocks-loading'
						wrapperStyle={{}}
						wrapperClass='blocks-wrapper'
						colors={['#51E24F', '#51E24F', '#51E24F', '#51E24F', '#51E24F']}
					/>
				</div>
			)}
        </div>
    )
}

export default FinalizeTimeSelectionView
