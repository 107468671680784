import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'

export const setMultipleTimeSlots = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.SET_MULTIPLE_TIME_SLOT_REQUEST,
	});

	await Axios(
		"post",
		end_points.set_multiple_time_slots,
		payload,
		"application/json",
		true
	)
	.then((res) => {
		console.log(`${end_points.set_multiple_time_slots} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.SET_MULTIPLE_TIME_SLOT_SUCCESS,
				payload: res?.data,
			});
		}
	})
	.catch((error) => {
		console.log(`${end_points.set_multiple_time_slots} Error response:`, error);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.SET_MULTIPLE_TIME_SLOT_FAILED,
				payload: error?.response?.data,
			});
		}
	})
};

export const setMultipleTimeSlotsReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.SET_MULTIPLE_TIME_SLOT_RESET,
	});
};

export const setMultipleTimeSlotsResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.SET_MULTIPLE_TIME_SLOT_RESET_ALL,
	});
};
