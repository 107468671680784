import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton'
import { useNavigate, useParams } from 'react-router-dom';
import OTPInput from 'react-otp-input';
import { connect } from 'react-redux';
import { verifyOtpReset, verifyOtp } from '../../store/actions/verifyOtpActions';
import { toast } from 'react-toastify';
import { setSessionId } from '../../store/actions/utilActions';

function OtpPage({ verifyOtpReducers, verifyOtp, utilReducer, verifyOtpReset, setSessionId }) {
    const navigate = useNavigate();
    const { meetingHash } = useParams();
    const [otp, setOtp] = useState('');

    useEffect(() => {
        if (verifyOtpReducers.status === 200) {
            if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
                navigate("/register-new-user/"+meetingHash)
            }else{
                navigate("/register-new-user/"+0)
            }
            setSessionId()
            verifyOtpReset();
        }else if (verifyOtpReducers.status === 302) {
            toast(verifyOtpReducers.message, {
                type: 'danger'
            });
            verifyOtpReset();
        }else if (verifyOtpReducers.status) {
            toast("Status: " + verifyOtpReducers.status + ",\n" + verifyOtpReducers.message, {
                type: 'danger'
            });
            verifyOtpReset();
        }
    }, [verifyOtpReducers]);

    return (
        <div className='h-full main-background'>
            <div className="flex flex-col justify-between h-full p-7">
                <div className="text-11xl font-[InterSemiBold] text-black mt-24">
                    Hello.
                </div>
                <div className="flex-column">
                    <div className="">
                        <label htmlFor="otp" className="text-gray font-[InterSemiBold]">
                            OTP
                        </label>
                    </div>
                    <div className="mt-3">
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            // onChange={(e) => {console.log("onchange :", e);}}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            // containerStyle={{border:"none"}}
                            inputStyle={{
                                borderBottomWidth: '2px',
                                borderColor: "#8981FD",
                                margin: "0px 2%",
                                width: "14%",
                                fontFamily: "InterRegular",
                                fontSize: "32px",
                                outline: "none"
                            }}
                            shouldAutoFocus={true}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                </div>
                <div className="">
                    <PrimaryButton 
                        title={"Next"} 
                        width={"w-[90%]"} 
                        backgroundColor={"bg-primary"} 
                        onClick={(e) => {
                            verifyOtp({ "phoneNo": utilReducer?.signupContactNo, "otp": otp, "sessionId": utilReducer?.sessionId });
                        }} 
                        isDisabled={otp.length !== 6}
                        isLoading={verifyOtpReducers?.loading}
                    />
                    <div 
                        className='' 
                        onClick={() => { 
                            setOtp('')
                            meetingHash ? navigate('/login/'+meetingHash) : navigate('/login/') 
                        }}
                    >
                        <div className="flex justify-center items-center text-InterBlack text-dark-gray text-lg text-center mt-5">
                            Already have an account? &nbsp;
                            <div className="text-InterBlack text-primary-alt text-lg mx-2 cursor-pointer">Login</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        verifyOtpReducers: state.verifyOtpReducers,
    }
}

export default connect(mapStateToProps, {
    verifyOtp,
    verifyOtpReset,
    setSessionId
})(OtpPage)