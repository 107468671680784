import { actionTypes } from '../types';

const initialState = {
    loading: false,
    status: '',
    message: '',
    data: {}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GENERATE_OTP_FOR_FORGET_PIN_REQUEST:
			return {
                ...state,
                loading: true,
            }
		case actionTypes.GENERATE_OTP_FOR_FORGET_PIN_SUCCESS:
			return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
                data: action?.payload?.response,
            }
        case actionTypes.GENERATE_OTP_FOR_FORGET_PIN_FAILED:
            return {
                ...state,
                loading: false,
                status: action?.payload?.status,
                message: action?.payload?.message,
            }
		case actionTypes.GENERATE_OTP_FOR_FORGET_PIN_RESET:
			return {
                ...state,
                loading: false,
                status: '',
                message: '',
            }
		case actionTypes.GENERATE_OTP_FOR_FORGET_PIN_RESET_ALL:
			return {
                ...state,
                loading: false,
                status: '',
                message: '',
                data: {}
            }
		default:
			return state;
	}
};

