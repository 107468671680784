import React from 'react'
import { ClipLoader } from 'react-spinners'

function PrimaryButton({
    title, 
    backgroundColor, 
    onClick, 
    width, 
    textColor, 
    isDisabled, 
    isLoading,
    textSize
}) {
    return (
        <button 
            className={`h-[50px] ${isDisabled ? "bg-[#D7D4E9]" : backgroundColor} text-lg ${width} ${textSize} rounded-full mx-auto ${textColor ? textColor : "text-[#FFFFFF]"} flex flex-column justify-center items-center`}
            onClick={()=> {!isLoading && onClick()}}
            disabled={isDisabled}
        >
            {isLoading ? <ClipLoader size={20} color={textColor ? textColor : "#FFFFFF"}/> : title}
        </button>
    )
}

export default PrimaryButton