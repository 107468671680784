import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'

export const getMeetingDaysWithTimeSlot = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.GET_MEETING_DAYS_WITH_TIME_SLOT_REQUEST,
	});

	await Axios(
		"get",
		end_points.get_meeting_days_with_time_slot + payload.meetingId,
		null,
		"application/json",
		true
	)
	.then((res) => {
		console.log(`${end_points.get_meeting_days_with_time_slot + payload.meetingId} Success response:`, res?.data);
		let newArray = [];
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			
			dispatch({
				type: actionTypes.GET_MEETING_DAYS_WITH_TIME_SLOT_SUCCESS,
				payload: res?.data,
			});

			res?.data?.response.map((data) => {
				console.log('data:', data);
				let newObject = {
					meetingDayId: data.meetingDayId,
					timeSlotList: [],
				};
				newArray.push(newObject);
			});

			dispatch({
				type: actionTypes.ARRAY_OF_OBJECT_TO_GET_ORGAISER_TIME_SLOT,
				payload: newArray,
			});
		}
	})
	.catch((error) => {
		console.log(`${end_points.get_meeting_days_with_time_slot + payload.meetingId} Error response:`, error);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.GET_MEETING_DAYS_WITH_TIME_SLOT_FAILED,
				payload: error?.response?.data,
			});
		}
	})
};

export const getMeetingDaysWithTimeSlotReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.GET_MEETING_DAYS_WITH_TIME_SLOT_RESET,
	});
};

export const getMeetingDaysWithTimeSlotResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.GET_MEETING_DAYS_WITH_TIME_SLOT_RESET_ALL,
	});
};

export const updateOrganiserSelectedTimeSlotArray =
	(meetingDayId, objectData) => async (dispatch) => {
		console.log(
			'action meetingDayId:',
			meetingDayId,
			' action obj:',
			objectData,
		);
		dispatch({
			type: actionTypes.OBJECT_TO_Add_ORGANISER_TIME_SLOT_TO_OBJECT_ARRAY,
			payload: {
				meetingDayId: meetingDayId,
				objectData: objectData,
			},
		});
	};