import React, { useEffect } from 'react'
import { BsCheckCircle, BsShare } from 'react-icons/bs'
import { connect } from 'react-redux';
import { getMeetingDetailsByMeetingId } from '../../../store/actions/getMeetingDetailsByMeetingIdActions';
import PrimaryButton from '../../common/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';

function ShareDetailsModal({ 
    meetingId, 
    setIsShareDetailsModalOpen, 
    getMeetingDetailsByMeetingId, 
    getMeetingDetailsByMeetingIdReducers 
}) {
    const navigate = useNavigate();

    const [copiedTextMsg, setCopiedTextMsg] = React.useState(false);

    const copyToClipboard = async ( url ) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(url);
            setCopiedTextMsg(true)
            setTimeout(() => {
                setCopiedTextMsg(false)
            }, 3000)
        } else {
            document.execCommand("copy", true, url);
        }
    };

    useEffect(() => {
        getMeetingDetailsByMeetingId({ meetingId: meetingId })
    }, [])

    const openSms = async ( url ) => {
        if(navigator.userAgent.match(/Android/i)){
            window.open(`sms://1900/?body=encodeURIComponent(${url})`,'_blank')
        }else if(navigator.userAgent.match(/iPhone/i)){
            window.open(`sms://1900/&body=encodeURIComponent(${url})`,'_blank')
        }
    }

    return (
        <div className="space-y-4 rounded-[19px] md:flex md:space-y-0 md:space-x-4 absolute z-10 w-full top-0 bottom-0 p-4 bg-black/[.5]">
            <div className="relative w-full max-w-2xl max-h-full border border-[#eee] rounded-[20px] mt-32">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-start justify-between p-4">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-light-primary-alt">
                            Coffee Meet Austin
                        </h3>
                        <button type="button" onClick={() => {navigate('/');setIsShareDetailsModalOpen(false)}} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:rounded-lg text-sm w-5 h-2 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-6 space-y-6">
                        <div className="flex flex-row justify-start items-center w-full my-4">
                            {copiedTextMsg ?
                                <BsCheckCircle size={24} color="#D74DFF" />
                                :
                                <BsShare size={24} color="#D74DFF"/>
                            }
                            <p className="text-darker-gray text-xl mx-2 w-[300px] break-words">{getMeetingDetailsByMeetingIdReducers?.data?.url}</p>
                        </div>
                    </div>
                    <div className="flex justify-between items-center pb-6">
                        <PrimaryButton
                            backgroundColor={"border-2 border-[#6A53FD]"}
                            textColor={"text-black"}
                            title={"Text"} 
                            onClick={() => openSms(getMeetingDetailsByMeetingIdReducers?.data?.url)}
                            width={"w-[40%]"}
                        />
                        <PrimaryButton
                            backgroundColor={"border-2 border-[#6A53FD]"}
                            textColor={"text-black"}
                            title={copiedTextMsg ? "Copied" : "Copy link"} 
                            onClick={() => copyToClipboard(getMeetingDetailsByMeetingIdReducers?.data?.url)}
                            width={"w-[40%]"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        getMeetingDetailsByMeetingIdReducers: state.getMeetingDetailsByMeetingIdReducers,
    }
}

export default connect(mapStateToProps, {
    getMeetingDetailsByMeetingId
})(ShareDetailsModal)
