import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton';
import OTPInput from 'react-otp-input';
import { registerUser, registerUserReset } from '../../store/actions/registerUserActions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setAccessToken } from '../../store/actions/utilActions';

function RegisterNewUser({ registerUser, registerUserReset, registerUserReducers, utilReducer, setAccessToken }) {
    const navigate = useNavigate();
    const { meetingHash } = useParams();
    const [otp, setOtp] = useState('');
    const [confirmOtp, setConfirmOtp] = useState('');
    const [userName, setUserName] = useState('');

    const allowOnlyOneSpace = str => {
        return str.endsWith(" ") ? str.trim() + " " : str.trim();
    };

    useEffect(() => {
        if (registerUserReducers?.status === 200) {
            setAccessToken(registerUserReducers?.data?.accessToken);
            registerUserReset()
            if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
                navigate("/join-meeting/"+meetingHash)
            }else{
                navigate("/dashboard")
            }
        }else if(registerUserReducers?.status === 302) {  
            toast.error(registerUserReducers?.message);
            registerUserReset()
        }else if(registerUserReducers?.status === 401) {  
            toast.error(registerUserReducers?.message);
            registerUserReset()
        }
    }, [registerUserReducers?.status])

    useEffect(() => {
      if(otp.length === 4 && confirmOtp.length === 4 && otp !== confirmOtp ){
        toast.error("Pin mismatch");
      }
    }, [otp, confirmOtp])
    
    return (
        <div className='h-full main-background'>
            <div className="flex flex-col justify-between h-full p-7">
                <div className="text-11xl font-[InterSemiBold] text-black mt-24">
                    Hello.
                </div>
                <div className="flex-column">
                    <div className="mx-3">
                        <label htmlFor="mobile-number" className="text-gray font-[InterSemiBold]">
                            USER NAME
                        </label>
                        <input
                            type="text"
                            pattern="[A-Za-z0-9_]{1,32}"
                            placeholder='Enter User Name'
                            autoFocus="true"
                            value={userName}
                            className="h-[25px] border-b-[1px] mt-1 border-light-primary-alt text-darker-gray text-lg w-[100%]"
                            onChange={(e)=> setUserName(allowOnlyOneSpace(e.target.value))}
                        />
                    </div>
                    <div className="mt-10 mx-3">
                        <label htmlFor="otp" className="text-gray font-[InterSemiBold]">
                            SET PIN
                        </label>
                    </div>
                    <div className="mt-3">
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            // onChange={(e) => {console.log("onchange :", e);}}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            // containerStyle={{border:"none"}}
                            inputStyle={{
                                borderBottomWidth: '2px',
                                borderColor: "#8981FD",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "32px"
                            }}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                    <div className="mt-10 mx-3">
                        <label htmlFor="otp" className="text-gray font-[InterSemiBold]">
                            Re-Enter Pin
                        </label>
                    </div>
                    <div className="mt-3">
                        <OTPInput
                            value={confirmOtp}
                            onChange={setConfirmOtp}
                            // onChange={(e) => {console.log("onchange :", e);}}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            // containerStyle={{border:"none"}}
                            inputStyle={otp.length === 4 && confirmOtp.length === 4 && otp !== confirmOtp ? {
                                borderBottomWidth: '2px',
                                borderColor: "#FE7F7F",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "32px"
                            } : {
                                borderBottomWidth: '2px',
                                borderColor: "#8981FD",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "32px"
                            }}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <PrimaryButton 
                        title={"Next"} 
                        width={"w-[90%]"}
                        backgroundColor={"bg-secondary"} 
                        onClick={() => {
                            if(userName.trim().length < 3){
                                toast.warn("Please enter a valid user name");
                                return;
                            }else if(otp.length !== 4) {
                                toast.warn("Please enter a valid four digit PIN");
                                return;
                            }
                            registerUser({
                                "pin": otp,
                                "userName": userName,
                                "phoneNumber": utilReducer?.signupContactNo,
                            })
                        }} 
                        isDisabled={!userName || otp.length !== 4 || otp !== confirmOtp}
                        isLoading={registerUserReducers?.loading}
                    />
                </div>
                <div 
                    className='' 
                    onClick={() => {
                        setOtp('')
                        setConfirmOtp('')
                        setUserName('')
                        meetingHash ? navigate('/login/'+meetingHash) : navigate('/login/');
                    }}
                >
                    <div className="flex justify-center items-center text-InterBlack text-dark-gray text-lg text-center mt-5">
                        Already have an account? &nbsp;
                        <div className="text-InterBlack text-primary-alt text-lg mx-2 cursor-pointer">Login</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        registerUserReducers: state.registerUserReducers,
    }
}

export default connect(mapStateToProps, {
    registerUser,
    registerUserReset,
    setAccessToken
})(RegisterNewUser)