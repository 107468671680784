import React, { useEffect, useState } from 'react'
import PrimaryButton from "../../components/common/PrimaryButton/PrimaryButton"
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { generateOtp, generateOtpReset } from '../../store/actions/generateOtpActions.js';
import { checkPhoneNumber, checkPhoneNumberReset } from '../../store/actions/checkPhoneNumberActions.js';
import { toast } from 'react-toastify';
import { setSignupContactNo, setSessionId } from '../../store/actions/utilActions';

function OnBoardingPage({
  generateOtp,
  generateOtpReset,
  generateOtpReducers,
  checkPhoneNumber,
  checkPhoneNumberReset,
  checkPhoneNumberReducers,
  setSignupContactNo,
  setSessionId
}) {
  const navigate = useNavigate();
  const { meetingHash } = useParams();
  const [phoneNumber, setPhoneNumber] = useState('');

  function getOS() {
    var userAgent = window.navigator.userAgent,
        platform =  window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Ios';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'Ios';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'WEB';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (/Linux/.test(platform)) {
      os = 'WEB';
    }

    return os;
  }

  useEffect(() => {
    if ((getOS()+ "").toLowerCase() != "ios") {

      const link = `ecco-app://meeting/${meetingHash}`;
      const a = document.createElement("a");
      a.setAttribute('href', link);
      a.setAttribute('target', '_self');
      a.click();
    }
  }, [meetingHash])


  useEffect(() => {
    if (generateOtpReducers?.status === 200) {
      if (generateOtpReducers?.data?.isRegistered === true){
        toast.warn("Phone number already registered, please login.")
      }else {
        toast.success(generateOtpReducers?.message);
        setSessionId(generateOtpReducers?.data?.sessionId)
        if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
          navigate("/otp-page/"+meetingHash);
        }else{
          navigate("/otp-page/"+"0");
        }
      }
      generateOtpReset();
    }else if(generateOtpReducers?.status === 302) {
      toast(generateOtpReducers?.message);
      generateOtpReset();
    }else if(generateOtpReducers?.status) {
      toast("Status: "+generateOtpReducers?.status+",\n"+generateOtpReducers?.message);
      generateOtpReset();
    }
  }, [generateOtpReducers?.status]);

  useEffect(() => {
    if (checkPhoneNumberReducers?.status === 200) {
      if (checkPhoneNumberReducers?.data?.isRegistered === true){
        toast.warn("Phone number already registered, please login.")
      }else {
        if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
          navigate("/register-new-user/"+meetingHash)
        }else{
          navigate("/register-new-user/"+0)
        }
      }
      checkPhoneNumberReset();
    }else if(checkPhoneNumberReducers?.status) {
      if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
        navigate("/register-new-user/"+meetingHash)
      }else{
        navigate("/register-new-user/"+0)
      }
      checkPhoneNumberReset();
    }
  }, [checkPhoneNumberReducers?.status]);

  return (
    <div className='h-full main-background'>
      <div className="flex flex-col justify-between h-full p-7">
        <div className="text-11xl font-[InterSemiBold] text-black mt-24">
          Hello.
        </div>
        <div className="-mt-[6rem]">
          <label htmlFor="mobile-number" className="text-gray font-[InterSemiBold]">
            Mobile
          </label>
          <input
            type="tel"
            placeholder='Enter Your Mobile Number'
            id='mobile-number'
            className={`h-[25px] border-b-[1px] mt-1 ${phoneNumber.length > 10 ? "border-error-red" : "border-light-primary-alt"} text-darker-gray text-lg w-[100%]`}
            // maxLength={10}
            value={phoneNumber}
            onChange={(e) => {
              if((e.target.value).length <= 10){
                setPhoneNumber(() => e.target.value.replace(/[^0-9]/g, ""))
              }
            }}
          />
        </div>
        <div className="">
          <PrimaryButton
            title={"Next"}
            width={"w-[90%]"}
            backgroundColor={"bg-primary"}
            onClick={() => {
              if(phoneNumber.length !== 10) {
                toast.warn("Please enter a valid mobile Number");
                return;
              }
              setSignupContactNo(phoneNumber);
              checkPhoneNumber({ phoneNo: phoneNumber })
            }}
          />
          <div
            className=''
            onClick={() => {
              setPhoneNumber('');
              meetingHash ? navigate('/login/'+meetingHash) : navigate('/login/')
            }}
          >
            <div className="flex justify-center items-center text-InterBlack text-dark-gray text-lg text-center mt-5">
              Already have an account? &nbsp;
              <div className="text-InterBlack text-primary-alt text-lg mx-2 cursor-pointer">Login</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    generateOtpReducers: state.generateOtpReducers,
    checkPhoneNumberReducers: state.checkPhoneNumberReducers,
  }
}

export default connect(mapStateToProps, {
  generateOtp,
  generateOtpReset,
  checkPhoneNumber,
  checkPhoneNumberReset,
  setSignupContactNo,
  setSessionId,
})(OnBoardingPage)
