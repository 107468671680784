import React from 'react'
import { connect } from 'react-redux';
import { verifyOtpForForgotPin, verifyOtpForForgotPinReset } from '../../store/actions/verifyOtpForForgotPinActions';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import OTPInput from 'react-otp-input';
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton';
import { useNavigate, useParams } from 'react-router-dom';

function ResetPin({
    utilReducer,
    verifyOtpForForgotPin,
    verifyOtpForForgotPinReset,
    verifyOtpForForgotPinReducers,
    setAccessToken
}) {
    const navigate = useNavigate();
    const { meetingHash } = useParams();

    const [otp, setOtp] = useState('');
    const [value, setValue] = useState('');
    const [confirmValue, setConfirmValue] = useState('');
    
    useEffect(() => {
        if(value.length === 4  && confirmValue.length === 4){
            if(value !== confirmValue) toast.error("Pin Mismatch", { type: 'warning' })
        }
    }, [confirmValue, value])

    useEffect(() => {
        if (verifyOtpForForgotPinReducers.status === 200) {
            toast.success(verifyOtpForForgotPinReducers.message, { type: 'success' });
            if(meetingHash != 0 && meetingHash !== undefined && meetingHash !== null){
                navigate("/login/"+meetingHash)
            }else{
                navigate("/login")
            }
            // navigation.navigate('VerifyMobileOtpScreen', {isRegistered: true, signupContactNo: utilReducer.signupContactNo})
            verifyOtpForForgotPinReset();
        }else if (verifyOtpForForgotPinReducers.status === 302) {
            toast.error(verifyOtpForForgotPinReducers.message);
            verifyOtpForForgotPinReset();
        }else if (verifyOtpForForgotPinReducers.status) {
            toast.error("Status: "+verifyOtpForForgotPinReducers.status+",\n"+verifyOtpForForgotPinReducers.message);
            verifyOtpForForgotPinReset();
        }
    }, [verifyOtpForForgotPinReducers]);

    return (
        <div className='h-full main-background'>
            <div className="flex flex-col justify-between h-full p-9">
                <div className="text-11xl font-[InterSemiBold] text-black mt-24">
                    Reset pin
                </div>
                <div className="flex-column">
                    <div className="mt-10 mx-3">
                        <label htmlFor="otp" className="text-gray font-[InterSemiBold]">
                            OTP
                        </label>
                    </div>
                    <div className="mt-3">
                        <OTPInput
                            value={otp}
                            onChange={setOtp}
                            // onChange={(e) => {console.log("onchange :", e);}}
                            numInputs={6}
                            renderSeparator={<span></span>}
                            // containerStyle={{border:"none"}}
                            inputStyle={{
                                borderBottomWidth: '2px',
                                borderColor: "#8981FD",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "32px"
                            }}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                    <div className="mt-10 mx-3">
                        <label htmlFor="otp" className="text-gray font-[InterSemiBold]">
                            SET PIN
                        </label>
                    </div>
                    <div className="mt-3">
                        <OTPInput
                            value={value}
                            onChange={setValue}
                            // onChange={(e) => {console.log("onchange :", e);}}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            // containerStyle={{border:"none"}}
                            inputStyle={{
                                borderBottomWidth: '2px',
                                borderColor: "#8981FD",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "32px"
                            }}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                    <div className="mt-10 mx-3">
                        <label htmlFor="otp" className="text-gray font-[InterSemiBold]">
                            Re-Enter Pin
                        </label>
                    </div>
                    <div className="mt-3">
                        <OTPInput
                            value={confirmValue}
                            onChange={setConfirmValue}
                            // onChange={(e) => {console.log("onchange :", e);}}
                            numInputs={4}
                            renderSeparator={<span></span>}
                            // containerStyle={{border:"none"}}
                            inputStyle={value.length === 4 && confirmValue.length === 4 && value !== confirmValue ? {
                                borderBottomWidth: '2px',
                                borderColor: "#FE7F7F",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "32px"
                            } : {
                                borderBottomWidth: '2px',
                                borderColor: "#8981FD",
                                margin: "0px 4%",
                                width: '20%',
                                fontFamily: "InterRegular",
                                fontSize: "32px"
                            }}
                            inputType={"number"}
                            renderInput={(props) => <input className='' {...props} />}
                        />
                    </div>
                </div>
                <div className="mt-5">
                    <PrimaryButton 
                        title={"Next"} 
                        width={"w-[90%]"}
                        backgroundColor={"bg-secondary"} 
                        onClick={() => {
                            if(otp.length !== 6) {
                                toast.show("Invalid OTP");
                            }else if(value.length !== 4){
                                toast.show("Please enter a valid four digit PIN");
                            }else{
                                verifyOtpForForgotPin({
                                    "sessionId" : utilReducer?.forgotPinSessionId,
                                    "otp" : otp,
                                    "pin" : value
                                })
                            }
                        }} 
                        isDisabled={!otp || value.length !== 4 || value !== confirmValue}
                        isLoading={verifyOtpForForgotPinReducers?.loading}
                    />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        utilReducer: state.utilReducer,
        verifyOtpForForgotPinReducers: state.verifyOtpForForgotPinReducers,
    }
}

export default connect(mapStateToProps, {
    verifyOtpForForgotPin,
    verifyOtpForForgotPinReset,
})(ResetPin)
