



import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../components/common/PrimaryButton/PrimaryButton'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setMultipleTimeSlots, setMultipleTimeSlotsReset } from '../../store/actions/setMultipleTimeSlots';
import { connect } from 'react-redux';
import { meetingDay, meetingDayReset } from '../../store/actions/meetingDayActions';
import { getMeetingDaysWithTimeSlot, getMeetingDaysWithTimeSlotReset, updateOrganiserSelectedTimeSlotArray } from '../../store/actions/getMeetingDaysWithTimeSlotActions';
import { toast } from 'react-toastify';
import OrganizerTimeSelectionView from '../../components/main/TimeSelectionView/OrganizerTimeSelectionView';
import moment from 'moment';

function SelectAvailableTimesPage({
    meetTitle, 
    isAttendee, 
    proximity, 
    selectedDates, 
    currentMeetingDayId, 
    getMeetingDaysWithTimeSlotReducers, 
    getMeetingDaysWithTimeSlot, 
    getMeetingDaysWithTimeSlotReset,
    updateOrganiserSelectedTimeSlotArray,
    meetingDay,
    meetingDayReducers,
    setMultipleTimeSlots,
    setMultipleTimeSlotsReset,
    setMultipleTimeSlotsReducers
}) {
    const navigate = useNavigate();
    const { meetingId } = useParams();

    const [selectedColumn, setSelectedColumn] = useState("");
    const [dates, setDates] = useState([])
    const [datesData, setDatesData] = useState([])
    const [selectedDate, setSelectedDate] = useState(dates[0]);
    const [dateIndex, setDateIndex] = useState(1);
    const [nextButton,setNextButton] = useState(false);
    const [isRunOnce, setIsRunOnce] = useState(false);
    const [currCreate, changeCurrCreate] = useState({
		cells: [
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
			[false],
		],
	});

    let hours = [
		'00:15:00',
		'00:30:00',
		'00:45:00',
		'01:00:00',
		'01:15:00',
		'01:30:00',
		'01:45:00',
		'02:00:00',
		'02:15:00',
		'02:30:00',
		'02:45:00',
		'03:00:00',
		'03:15:00',
		'03:30:00',
		'03:45:00',
		'04:00:00',
		'04:15:00',
		'04:30:00',
		'04:45:00',
		'05:00:00',
		'05:15:00',
		'05:30:00',
		'05:45:00',
		'06:00:00',
		'06:15:00',
		'06:30:00',
		'06:45:00',
		'07:00:00',
		'07:15:00',
		'07:30:00',
		'07:45:00',
		'08:00:00',
		'08:15:00',
		'08:30:00',
		'08:45:00',
		'09:00:00',
		'09:15:00',
		'09:30:00',
		'09:45:00',
		'10:00:00',
		'10:15:00',
		'10:30:00',
		'10:45:00',
		'11:00:00',
		'11:15:00',
		'11:30:00',
		'11:45:00',
		'12:00:00',
		'12:15:00',
		'12:30:00',
		'12:45:00',
		'13:00:00',
		'13:15:00',
		'13:30:00',
		'13:45:00',
		'14:00:00',
		'14:15:00',
		'14:30:00',
		'14:45:00',
		'15:00:00',
		'15:15:00',
		'15:30:00',
		'15:45:00',
		'16:00:00',
		'16:15:00',
		'16:30:00',
		'16:45:00',
		'17:00:00',
		'17:15:00',
		'17:30:00',
		'17:45:00',
		'18:00:00',
		'18:15:00',
		'18:30:00',
		'18:45:00',
		'19:00:00',
		'19:15:00',
		'19:30:00',
		'19:45:00',
		'20:00:00',
		'20:15:00',
		'20:30:00',
		'20:45:00',
		'21:00:00',
		'21:15:00',
		'21:30:00',
		'21:45:00',
		'22:00:00',
		'22:15:00',
		'22:30:00',
		'22:45:00',
		'23:00:00',
		'23:15:00',
		'23:30:00',
		'23:45:00',
	];

    const setMultipleTimeSlotsHandler = () => {
        setIsRunOnce(true)
        getMeetingDaysWithTimeSlotReducers?.emptyTimeSlot?.map((item, i) => {
            if(item?.timeSlotList?.length > 0) {
                console.log("setting time slots:", item)
                setMultipleTimeSlots(item);
            }
        })
        // alert(
        //
        //     JSON.stringify(getMeetingDaysWithAllTimeSlotReducers?.emptyTimeSlot),
        //
        // );
    }

    useEffect(() => {
        if(datesData[dateIndex-1]?.meetingDayId !== undefined){
        let is_group = false;
            let startTime;
            let endTime;
            // setCurrentDayTimeSlot([]);
            let tempTsData = [];
            let tempData = currCreate.cells;
            console.log('====================================');
            console.log("tempTsData1 :", tempData);
            console.log('====================================');

            for (let i = 0; i < tempData?.length; i++) {
                // console.log(" item :"+ tempData[i], "i :"+ i)
                if (tempData[i][0] === true) {
                    if (is_group === true) {
                        // console.log("case 1")
                        endTime = endTime + 1;
                    } else {
                        // console.log("case 2")
                        startTime = i;
                        endTime = i + 1;
                        is_group = true;
                    }
                } else {
                    if (is_group === true) {
                        // console.log("case 3")
                        is_group = false;
                        console.log('Start time :', startTime, 'endTime :', endTime);
                        console.log(
                            'Start time :',
                            startTime === 1 ? '00:00:00' : hours[startTime - 2],
                            'endTime :',
                            hours[endTime - 2],
                        );
                        let tsObject = {
                            startTime: startTime === 1 ? '00:00:00' : hours[startTime - 2],
                            endTime: hours[endTime - 2],
                        };
                        console.log('tsObject :', tsObject);
                        tempTsData.push(tsObject);
                        // setCurrentDayTimeSlot((currentDayTimeSlot) => [
                        //     ...currentDayTimeSlot,
                        //     tsObject,
                        // ]);
                    } else {
                        // console.log("case 4")
                        // console.log("nothing to do :", i)
                    }
                }
            }
            console.log('====================================');
            console.log("datesData[dateIndex] :", datesData[dateIndex-1]?.meetingDayId);
            console.log("tempTsData :", tempTsData);
            console.log('====================================');

            // updateOrganiserSelectedTimeSlotArray(currentMeetingDayId, tempTsData);
            updateOrganiserSelectedTimeSlotArray(datesData[dateIndex-1]?.meetingDayId, tempTsData);
        }
    }, [currCreate, datesData]);

    useEffect(() => {
        meetingDay({ meetingId: meetingId });
        getMeetingDaysWithTimeSlot({ meetingId: meetingId });
    }, []);
    
    useEffect(() => {
        if (meetingDayReducers.status === 200) {
            setDates(meetingDayReducers?.data?.map((day) => { return day.meetingDate }))
            setDatesData(meetingDayReducers?.data)
            setSelectedDate(meetingDayReducers?.data?.map((day) => { return day.meetingDate })[0])
            meetingDayReset();
        }else if (meetingDayReducers.status === 302) {
            toast.error(meetingDayReducers.message);
            meetingDayReset();
        }else if (meetingDayReducers.status) {
            toast.error("Status: "+meetingDayReducers.status+",\n"+meetingDayReducers.message);
            meetingDayReset();
        }
    }, [meetingDayReducers]);

    // console.log('meetingDayReducers:', meetingDayReducers)

    useEffect(() => {
        if (getMeetingDaysWithTimeSlotReducers.status === 200) {
            getMeetingDaysWithTimeSlotReset();
        }else if (getMeetingDaysWithTimeSlotReducers.status === 302) {
            toast.error(getMeetingDaysWithTimeSlotReducers.message);
            getMeetingDaysWithTimeSlotReset();
        }else if (getMeetingDaysWithTimeSlotReducers.status) {
            toast.error("Status: "+getMeetingDaysWithTimeSlotReducers.status+",\n"+getMeetingDaysWithTimeSlotReducers.message);
            getMeetingDaysWithTimeSlotReset();
        }
    }, [getMeetingDaysWithTimeSlotReducers]);

    
    useEffect(()=>{
        getMeetingDaysWithTimeSlotReducers?.emptyTimeSlot?.map((item, i) => {
            if(item?.timeSlotList?.length > 0) {
                console.log("setting time slots:", item)
               setNextButton(true)
            }
        })
    },[getMeetingDaysWithTimeSlotReducers]);

    useEffect(() => {
        if (setMultipleTimeSlotsReducers.status === 302) {
            setIsRunOnce(false)
            toast.error(setMultipleTimeSlotsReducers.message,);
            setMultipleTimeSlotsReset();
        }else if (setMultipleTimeSlotsReducers.status === 201) {
            if(isRunOnce){
                toast.success(setMultipleTimeSlotsReducers.message);
                console.log("navigating to CreateMeetStepFourScreen")
                navigate('/your-location/'+meetingId+'/organizer')
                setIsRunOnce(false)
            }
            setMultipleTimeSlotsReset();
            // }else if (setMultipleTimeSlotsReducers.status) {
            //     toast.error("Status: "+setMultipleTimeSlotsReducers.status+",\n"+setMultipleTimeSlotsReducers.message, { type: 'danger' });
            //     setMultipleTimeSlotsReset();
        }
    }, [setMultipleTimeSlotsReducers]);
    
    // console.log('====================================');
    // console.log("getMeetingDaysWithTimeSlotReducers :", getMeetingDaysWithTimeSlotReducers);
    // console.log("datesData[dateIndex] :", nextButton);
    // console.log("datesData[dateIndex] :", dateIndex);
    // console.log("datesData[dateIndex] :", datesData);
    // console.log("datesData[dateIndex] :", datesData[dateIndex-1]?.meetingDayId);
    // console.log('====================================');

    return (
        <div className='h-full main-background'>
            <div className="pt-28 px-8">
                <div className="flex justify-between align-center">
                    <div className="font-[InterRegular] text-4xl">
                        Select Available Times
                    </div>
                    <div className="text-lg">
                        2/3
                    </div>
                </div>
                <div className="font-[InterRegular] text-lg">
                    Coffee Meet Austin
                </div>
            </div>
            <div className="flex flex-row justify-center items-center px-4" style={{zIndex: 12, width: '100%', backgroundColor: '#ffffff'}}>
                {dates?.length > 0 ?
                    dates?.map((date, i) => {
                        return (
                            <div
                                key={i}
                                onClick={() => {
                                    console.log("date :", date)
                                    setSelectedDate(date);
                                    setDateIndex(dates.indexOf(date));
                                }}
                                className={dates?.length == 1 ? "w-full" :dates?.length == 2 ? "w-[50%]" : "w-[33%]" }
                            >
                                <div
                                    className={`bg-white py-2 w-full ${i < dates?.length ? "border-[1px] border-white" : ""}`}
                                >
                                    <div
                                        className={`flex flex-row justify-center items-center relative   mt-2 bg-[#906DFC] py-2 ${i === 0 ? "rounded-l-full" : ""} ${i === dates?.length - 1 ? "rounded-r-full" : ""}`}
                                        style={{
                                            shadowOpacity: 1,
                                            shadowColor: '#000',
                                            // shadowRadius: 24,
                                            // elevation: 6,
                                        }}
                                    >
                                        <p
                                            className="text-white tracking-wider">{moment(date, 'DD/MM/YYYY').format('ddd')}, </p>
                                        <p
                                            className="text-white tracking-wider">{moment(date, 'DD/MM/YYYY').format('MMM DD')}</p>
                                        {selectedDate == date ? <div
                                            className="w-[54px] h-[2px] bg-[#fff] mb-[1px] absolute rounded-full"
                                            style={{bottom: 0,}}></div> : ""}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    null
                }
            </div>
            <div className='w-[100%] h-[60%] p-5'>
                <OrganizerTimeSelectionView 
                    currCreate={currCreate}
                    changeCurrCreate={changeCurrCreate}
                />
            </div>
            <div className="flex justify-center items-center">
                <PrimaryButton
                    backgroundColor={"border-2 border-[#6A53FD]"}
                    textColor={"text-black"} title={"Back"}
                    onClick={() => navigate(-1)}
                    width={"w-[107px]"}
                />
                <PrimaryButton
                    backgroundColor={"bg-primary"}
                    title={"Next"}
                    textColor={"text-white"}
                    onClick={() => {
                        setMultipleTimeSlotsHandler()
                    }}
                    width={"w-[200px]"}
                    isDisabled={!nextButton}
                />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        createMeetingReducers: state.createMeetingReducers,
        getMeetingDaysWithTimeSlotReducers: state.getMeetingDaysWithTimeSlotReducers,
        setMultipleTimeSlotsReducers: state.setMultipleTimeSlotsReducers,
        meetingDayReducers: state.meetingDayReducers,
        setMultipleTimeSlotsReducers: state.setMultipleTimeSlotsReducers,
    }
}

export default connect(mapStateToProps, {
    setMultipleTimeSlots,
    getMeetingDaysWithTimeSlot,
    getMeetingDaysWithTimeSlotReset,
    meetingDay,
    setMultipleTimeSlotsReset,
    updateOrganiserSelectedTimeSlotArray,
    setMultipleTimeSlots,
    setMultipleTimeSlotsReset
})(SelectAvailableTimesPage)