import { actionTypes } from '../types';

const initialState = {
	highledCalls: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.STORED_HIGHLIGHTED_CELLS_DATA:
			return {
				...state,
				highledCalls: action?.payload,
			};
		default:
			return state;
	}
};
