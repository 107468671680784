import { actionTypes } from '../types';

export default (state = 'val', action) => {
	switch (action.type) {
		case actionTypes.DEMO_ACTION:
			return action.payload;
		default:
			return state;
	}
};
