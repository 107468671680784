export const LocationPermissionImgs = {
    LocationPermissionIosStep1: require("../img/location-permission-1.jpeg"),
    LocationPermissionIosStep2: require("../img/location-permission-2.jpeg"),
    LocationPermissionIosStep3: require("../img/location-permission-3.jpeg"),
    LocationPermissionIosStep4: require("../img/location-permission-4.jpeg"),
    LocationPermissionIosStep5: require("../img/location-permission-5.jpeg"),
    LocationPermissionIosStepMac1: require("../img/location-permission-mac-1.jpg"),
    LocationPermissionIosStepMac2: require("../img/location-permission-mac-2.jpg"),
    LocationPermissionIosStepMac3: require("../img/location-permission-mac-3.jpg"),
    LocationPermissionIosStepMac4: require("../img/location-permission-mac-4.jpg"),
    LocationPermissionIosStepMac5: require("../img/location-permission-mac-5.jpg"),
    LocationPermissionIosStepMac6: require("../img/location-permission-mac-6.jpg"),
    LocationPermissionIosStepMac7: require("../img/location-permission-mac-7.jpg"),
    LocationServices1: require("../img/location-services-1.jpg"),
    LocationServices2: require("../img/location-services-2.jpg"),
    LocationServices3: require("../img/location-services-3.jpg"),
    LocationServices4: require("../img/location-services-4.jpg"),
    LocationServicesChrome: require("../img/location-services-chrome.jpeg"),
    LocationServicesChrome1: require("../img/location-services-chrome-1.jpg"),
    LocationServicesChrome2: require("../img/location-services-chrome-2.jpg"),
    LocationServicesChrome3: require("../img/location-services-chrome-3.jpeg"),
    LocationServicesChrome4: require("../img/location-services-chrome-4.jpeg"),
}