import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers';


export const middlewares = [ReduxThunk];

export const createStoreWithMiddleware = applyMiddleware(...middlewares)(
	createStore,
);

export const store = createStoreWithMiddleware(rootReducer);

export const persistor = persistStore(store);
