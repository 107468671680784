import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from "redux-persist/lib/storage";


import demoActionReducer from './demoActionReducer';
import regionCoffeeShopReducer from './regionCoffeeShopReducer';
import generateOtpReducers from './generateOtpReducers';
import utilReducer from './utilReducer';
import verifyOtpReducers from './verifyOtpReducers';
import registerUserReducers from './registerUserReducers';
import createMeetingDetailsReducers from './createMeetingDetailsReducers';
import loginReducers from './loginReducers';
import createMeetingReducers from './createMeetingReducers';
import meetingDayReducers from './meetingDayReducers';
import setTimeSlotReducers from './setTimeSlotReducers';
import meetingByUserIdReducers from './meetingByUserIdReducers';
import meetingByMeetingDateReducers from './meetingByMeetingDateReducers';
import organizerAndUserCoordinateUpdateReducer from './organizerAndUserCoordinateUpdateReducer';
import createLocationDetailsReducers from './createLocationDetailsReducers';
import deleteTimeSlotReducers from './deleteTimeSlotReducers';
import updateTimeSlotReducers from './updateTimeSlotReducers';
import getMeetingDetailsByMeetingIdReducers from './getMeetingDetailsByMeetingIdReducers';
import getTimeSlotByIdReducers from './getTimeSlotByIdReducers';
import registerInviteesReducers from './registerInviteesReducers';
import getTimeSlotByMeetingDayIdReducers from './getTimeSlotByMeetingDayIdReducers';
import getMeetingDaysWithTimeSlotReducers from './getMeetingDaysWithTimeSlotReducers';
import setInviteeLocationPreferenceReducers from './setInviteeLocationPreferenceReducers';
import getLocationPreferencesReducers from './getLocationPreferencesReducers';
import finalizeMeetingReducers from './finalizeMeetingReducers';
import setProximityReducers from './setProximityReducers';
import getMeetingDaysWithAllTimeSlotReducers from './getMeetingDaysWithAllTimeSlotReducers';
import setStartingLocationReducers from "./setStartingLocationReducers";
import getMeetingCenterLocationReducers from './getMeetingCenterLocationReducers';
import storedHighlightedCellsDataReducer from './storedHighlightedCellsDataReducer';
import setMultipleTimeSlotsReducers from "./setMultipleTimeSlotsReducers";
import getMeetingDetailsByMeetingHashReducers from './getMeetingDetailsByMeetingHashReducers';
import generateOtpForForgotPinReducers from './generateOtpForForgotPinReducers';
import verifyOtpForForgotPinReducers from './verifyOtpForForgotPinReducers';
import resetPinReducers from './resetPinReducers';
import checkPhoneNumberReducers from './checkPhoneNumberReducers';

const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		'demoActionReducer',
		'utilReducer',
		'regionCoffeeShopReducer',
		'organizerAndUserCoordinateUpdateReducer',
		'storedHighlightedCellsDataReducer',
		'getMeetingDetailsByMeetingHashReducers',
	],
};

const appReducer = combineReducers({
	demoActionReducer: demoActionReducer,
	regionCoffeeShopReducer: regionCoffeeShopReducer,
	generateOtpReducers: generateOtpReducers,
	checkPhoneNumberReducers: checkPhoneNumberReducers,
	verifyOtpReducers: verifyOtpReducers,
	registerUserReducers: registerUserReducers,
	createMeetingDetailsReducers: createMeetingDetailsReducers,
	loginReducers: loginReducers,
	createMeetingReducers: createMeetingReducers,
	meetingDayReducers: meetingDayReducers,
	setTimeSlotReducers: setTimeSlotReducers,
	meetingByUserIdReducers: meetingByUserIdReducers,
	meetingByMeetingDateReducers: meetingByMeetingDateReducers,
	createLocationDetailsReducers: createLocationDetailsReducers,
	organizerAndUserCoordinateUpdateReducer: organizerAndUserCoordinateUpdateReducer,
	deleteTimeSlotReducers: deleteTimeSlotReducers,
	updateTimeSlotReducers: updateTimeSlotReducers,
	getTimeSlotByIdReducers: getTimeSlotByIdReducers,
	getMeetingDetailsByMeetingIdReducers: getMeetingDetailsByMeetingIdReducers,
	registerInviteesReducers: registerInviteesReducers,
	getTimeSlotByMeetingDayIdReducers: getTimeSlotByMeetingDayIdReducers,
	getMeetingDaysWithTimeSlotReducers: getMeetingDaysWithTimeSlotReducers,
	setInviteeLocationPreferenceReducers: setInviteeLocationPreferenceReducers,
	getLocationPreferencesReducers: getLocationPreferencesReducers,
	finalizeMeetingReducers: finalizeMeetingReducers,
	setProximityReducers: setProximityReducers,
	setStartingLocationReducers: setStartingLocationReducers,
	getMeetingDaysWithAllTimeSlotReducers: getMeetingDaysWithAllTimeSlotReducers,
	getMeetingCenterLocationReducers: getMeetingCenterLocationReducers,
	utilReducer: utilReducer,
	storedHighlightedCellsDataReducer: storedHighlightedCellsDataReducer,
	setMultipleTimeSlotsReducers: setMultipleTimeSlotsReducers,
	getMeetingDetailsByMeetingHashReducers: getMeetingDetailsByMeetingHashReducers,
	generateOtpForForgotPinReducers: generateOtpForForgotPinReducers,
	verifyOtpForForgotPinReducers: verifyOtpForForgotPinReducers,
	resetPinReducers: resetPinReducers,
});

const rootReducer = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default  persistReducer(persistConfig, rootReducer);;
