import { actionTypes } from '../types';

const initialState = {
    proximity: '',
}

export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PROXIMITY:
			return {
                ...state,
                proximity: action.payload
            }
		case actionTypes.RESET_PROXIMITY:
			return {
                ...state,
                proximity: ''
            }
		default:
			return state;
	}
};

