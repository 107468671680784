let hours = [
    {
        "id": 1,
        "toggled": false,
        "time": "12:00 AM"
    },
    {
        "id": 2,
        "toggled": false,
        "time": "12:15 AM"
    },
    {
        "id": 3,
        "toggled": false,
        "time": "12:30 AM"
    },
    {
        "id": 4,
        "toggled": false,
        "time": "12:45 AM"
    },
    {
        "id": 5,
        "toggled": false,
        "time": "01:00 AM"
    },
    {
        "id": 6,
        "toggled": false,
        "time": "01:15 AM"
    },
    {
        "id": 7,
        "toggled": false,
        "time": "01:30 AM"
    },
    {
        "id": 8,
        "toggled": false,
        "time": "01:45 AM"
    },
    {
        "id": 9,
        "toggled": false,
        "time": "02:00 AM"
    },
    {
        "id": 10,
        "toggled": false,
        "time": "02:15 AM"
    },
    {
        "id": 11,
        "toggled": false,
        "time": "02:30 AM"
    },
    {
        "id": 12,
        "toggled": false,
        "time": "02:45 AM"
    },
    {
        "id": 13,
        "toggled": false,
        "time": "03:00 AM"
    },
    {
        "id": 14,
        "toggled": false,
        "time": "03:15 AM"
    },
    {
        "id": 15,
        "toggled": false,
        "time": "03:30 AM"
    },
    {
        "id": 16,
        "toggled": false,
        "time": "03:45 AM"
    },
    {
        "id": 17,
        "toggled": false,
        "time": "04:00 AM"
    },
    {
        "id": 18,
        "toggled": false,
        "time": "04:15 AM"
    },
    {
        "id": 19,
        "toggled": false,
        "time": "04:30 AM"
    },
    {
        "id": 20,
        "toggled": false,
        "time": "04:45 AM"
    },
    {
        "id": 21,
        "toggled": false,
        "time": "05:00 AM"
    },
    {
        "id": 22,
        "toggled": false,
        "time": "05:15 AM"
    },
    {
        "id": 23,
        "toggled": false,
        "time": "05:30 AM"
    },
    {
        "id": 24,
        "toggled": false,
        "time": "05:45 AM"
    },
    {
        "id": 25,
        "toggled": false,
        "time": "06:00 AM"
    },
    {
        "id": 26,
        "toggled": false,
        "time": "06:15 AM"
    },
    {
        "id": 27,
        "toggled": false,
        "time": "06:30 AM"
    },
    {
        "id": 28,
        "toggled": false,
        "time": "06:45 AM"
    },
    {
        "id": 29,
        "toggled": false,
        "time": "07:00 AM"
    },
    {
        "id": 30,
        "toggled": false,
        "time": "07:15 AM"
    },
    {
        "id": 31,
        "toggled": false,
        "time": "07:30 AM"
    },
    {
        "id": 32,
        "toggled": false,
        "time": "07:45 AM"
    },
    {
        "id": 33,
        "toggled": false,
        "time": "08:00 AM"
    },
    {
        "id": 34,
        "toggled": false,
        "time": "08:15 AM"
    },
    {
        "id": 35,
        "toggled": false,
        "time": "08:30 AM"
    },
    {
        "id": 36,
        "toggled": false,
        "time": "08:45 AM"
    },
    {
        "id": 37,
        "toggled": false,
        "time": "09:00 AM"
    },
    {
        "id": 38,
        "toggled": false,
        "time": "09:15 AM"
    },
    {
        "id": 39,
        "toggled": false,
        "time": "09:30 AM"
    },
    {
        "id": 40,
        "toggled": false,
        "time": "09:45 AM"
    },
    {
        "id": 41,
        "toggled": false,
        "time": "10:00 AM"
    },
    {
        "id": 42,
        "toggled": false,
        "time": "10:15 AM"
    },
    {
        "id": 43,
        "toggled": false,
        "time": "10:30 AM"
    },
    {
        "id": 44,
        "toggled": false,
        "time": "10:45 AM"
    },
    {
        "id": 45,
        "toggled": false,
        "time": "11:00 AM"
    },
    {
        "id": 46,
        "toggled": false,
        "time": "11:15 AM"
    },
    {
        "id": 47,
        "toggled": false,
        "time": "11:30 AM"
    },
    {
        "id": 48,
        "toggled": false,
        "time": "11:45 AM"
    },
    {
        "id": 49,
        "toggled": false,
        "time": "12:00 PM"
    },
    {
        "id": 50,
        "toggled": false,
        "time": "12:15 PM"
    },
    {
        "id": 51,
        "toggled": false,
        "time": "12:30 PM"
    },
    {
        "id": 52,
        "toggled": false,
        "time": "12:45 PM"
    },
    {
        "id": 53,
        "toggled": false,
        "time": "01:00 PM"
    },
    {
        "id": 54,
        "toggled": false,
        "time": "01:15 PM"
    },
    {
        "id": 55,
        "toggled": false,
        "time": "01:30 PM"
    },
    {
        "id": 56,
        "toggled": false,
        "time": "01:45 PM"
    },
    {
        "id": 57,
        "toggled": false,
        "time": "02:00 PM"
    },
    {
        "id": 58,
        "toggled": false,
        "time": "02:15 PM"
    },
    {
        "id": 59,
        "toggled": false,
        "time": "02:30 PM"
    },
    {
        "id": 60,
        "toggled": false,
        "time": "02:45 PM"
    },
    {
        "id": 61,
        "toggled": false,
        "time": "03:00 PM"
    },
    {
        "id": 62,
        "toggled": false,
        "time": "03:15 PM"
    },
    {
        "id": 63,
        "toggled": false,
        "time": "03:30 PM"
    },
    {
        "id": 64,
        "toggled": false,
        "time": "03:45 PM"
    },
    {
        "id": 65,
        "toggled": false,
        "time": "04:00 PM"
    },
    {
        "id": 66,
        "toggled": false,
        "time": "04:15 PM"
    },
    {
        "id": 67,
        "toggled": false,
        "time": "04:30 PM"
    },
    {
        "id": 68,
        "toggled": false,
        "time": "04:45 PM"
    },
    {
        "id": 69,
        "toggled": false,
        "time": "05:00 PM"
    },
    {
        "id": 70,
        "toggled": false,
        "time": "05:15 PM"
    },
    {
        "id": 71,
        "toggled": false,
        "time": "05:30 PM"
    },
    {
        "id": 72,
        "toggled": false,
        "time": "05:45 PM"
    },
    {
        "id": 73,
        "toggled": false,
        "time": "06:00 PM"
    },
    {
        "id": 74,
        "toggled": false,
        "time": "06:15 PM"
    },
    {
        "id": 75,
        "toggled": false,
        "time": "06:30 PM"
    },
    {
        "id": 76,
        "toggled": false,
        "time": "06:45 PM"
    },
    {
        "id": 77,
        "toggled": false,
        "time": "07:00 PM"
    },
    {
        "id": 78,
        "toggled": false,
        "time": "07:15 PM"
    },
    {
        "id": 79,
        "toggled": false,
        "time": "07:30 PM"
    },
    {
        "id": 80,
        "toggled": false,
        "time": "07:45 PM"
    },
    {
        "id": 81,
        "toggled": false,
        "time": "08:00 PM"
    },
    {
        "id": 82,
        "toggled": false,
        "time": "08:15 PM"
    },
    {
        "id": 83,
        "toggled": false,
        "time": "08:30 PM"
    },
    {
        "id": 84,
        "toggled": false,
        "time": "08:45 PM"
    },
    {
        "id": 85,
        "toggled": false,
        "time": "09:00 PM"
    },
    {
        "id": 86,
        "toggled": false,
        "time": "09:15 PM"
    },
    {
        "id": 87,
        "toggled": false,
        "time": "09:30 PM"
    },
    {
        "id": 88,
        "toggled": false,
        "time": "09:45 PM"
    },
    {
        "id": 89,
        "toggled": false,
        "time": "10:00 PM"
    },
    {
        "id": 90,
        "toggled": false,
        "time": "10:15 PM"
    },
    {
        "id": 91,
        "toggled": false,
        "time": "10:30 PM"
    },
    {
        "id": 92,
        "toggled": false,
        "time": "10:45 PM"
    },
    {
        "id": 93,
        "toggled": false,
        "time": "11:00 PM"
    },
    {
        "id": 94,
        "toggled": false,
        "time": "11:15 PM"
    },
    {
        "id": 95,
        "toggled": false,
        "time": "11:30 PM"
    },
    {
        "id": 96,
        "toggled": false,
        "time": "11:45 PM"
    },
    {
        "id": 97,
        "toggled": false,
        "time": ""
    }
];

// let hours = [
//   "12:00",
//   "01:00",
//   "02:00",
//   "03:00",
//   "04:00",
//   "05:00",
//   "06:00",
//   "07:00",
//   "08:00",
//   "09:00",
//   "10:00",
//   "11:00",
//   "12:00",
//   "13:00",
//   "14:00",
//   "15:00",
//   "16:00",
//   "17:00",
//   "18:00",
//   "19:00",
//   "20:00",
//   "21:00",
//   "22:00",
//   "23:00",
//   "24:00"
// ];

export default hours;