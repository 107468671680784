import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'

export const verifyOtpForForgotPin = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.VERIFY_OTP_FOR_FORGET_PIN_REQUEST,
	});

	await Axios(
		"post",
		end_points.verify_otp_for_forgot_pin,
		payload,
		"application/json",
		true
	)
	.then((res) => {
		console.log(`${end_points.verify_otp_for_forgot_pin} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.VERIFY_OTP_FOR_FORGET_PIN_SUCCESS,
				payload: res?.data,
			});
		}
	})
	.catch((error) => {
		console.log(`${end_points.verify_otp_for_forgot_pin} Error response:`, error?.response?.data);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.VERIFY_OTP_FOR_FORGET_PIN_FAILED,
				payload: error?.response?.data,
			});
		}
	})
};

export const verifyOtpForForgotPinReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.VERIFY_OTP_FOR_FORGET_PIN_RESET,
	});
};

export const verifyOtpForForgotPinResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.VERIFY_OTP_FOR_FORGET_PIN_RESET_ALL,
	});
};
