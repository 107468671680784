import React from 'react'
import PrimaryButton from '../../common/PrimaryButton/PrimaryButton';

function PromptLocationPermissionInstructionModal({ setIsLocationWithoutPermission, setIsLocationPermissionModalOpen }) {

    function getOS() {
        var userAgent = window.navigator.userAgent,
            platform =  window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
			os = 'macWEB';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'Ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'WEB';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'WEB';
        }

        return os;
    }

    return (
        <div className="space-y-4 rounded-[19px] md:flex md:space-y-0 md:space-x-4 absolute z-50 w-full top-0 bottom-0 p-4 bg-black bg-opacity-60">
            <div className="relative w-full max-w-2xl max-h-full mt-[50%]">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className='px-4 pt-5'>
                        <h3 className="text-xl font-semibold">
                            Allow access to your location!
                        </h3>
                        <p className='my-2'>
                            Please allow access to your location to schedule meetup. We need to access your location from browser.{getOS() == "Ios" && "Use this guide to allow location"}
                        </p>
                    </div>
                    <div className="flex justify-between items-center px-6 pt-6 pb-2 space-x-2">
                        <PrimaryButton
                            title={"Skip"}
                            width={getOS() == 'macWEB' || getOS() == 'Ios' ? "w-[48%]" : "w-[98%]"}
                            backgroundColor={"bg-white text-secondary border-2 border-secondary"}
                            onClick={() => {
                                setIsLocationWithoutPermission(true)
                                setIsLocationPermissionModalOpen(false)
                            }}
                        />
                        {getOS() == 'macWEB' || getOS() == 'Ios' &&
                            <PrimaryButton
                                title={"Guide"}
                                width={"w-[48%]"}
                                backgroundColor={"bg-white text-secondary border-2 border-secondary"}
                                onClick={() => {
                                    window.open('https://ecco-app.com/permissions-guide', "_blank")
                                }}
                            />
                        }
                    </div>
                    <div className="flex justify-between items-center px-6 pt-2 pb-6 space-x-2">
                        <PrimaryButton
                            title={"Reload"}
                            width={"w-[98%]"}
                            backgroundColor={"bg-primary border-2 "}
                            onClick={() => {
                                window.location.reload(true)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromptLocationPermissionInstructionModal
