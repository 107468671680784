import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'

export const verifyOtp = (payload) => async (dispatch) => {
	// initiate api call and loader
	dispatch({
		type: actionTypes.VERIFY_OTP_REQUEST,
	});

	await Axios(
		"get",
		end_points.verify_otp + payload.phoneNo + "/" + payload.otp + "/" + payload.sessionId ,
		null,
		"application/json",
		false
	)
	.then((res) => {
		console.log(`${end_points.verify_otp + payload.phoneNo + "/" + payload.otp + "/" + payload.sessionId} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.VERIFY_OTP_SUCCESS,
				payload: res?.data,
			});
		}
	})
	.catch((error) => {
		console.log(`${end_points.verify_otp + payload.phoneNo + "/" + payload.otp + "/" + payload.sessionId} Error response:`, error?.response?.data);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.VERIFY_OTP_FAILED,
				payload: error?.response?.data,
			});
		}
	})
};

export const verifyOtpReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.VERIFY_OTP_RESET,
	});
};

export const verifyOtpResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.VERIFY_OTP_RESET_ALL,
	});
};
