import { actionTypes } from '../types';

export const organizerCoordinateStored =
	(addressComponents, latitude, longitude) => async (dispatch) => {
		dispatch({
			type: actionTypes.ORGANIZER_CO_ORDINATES,
			payload: {
				addressComponents: addressComponents,
				latitude: latitude,
				longitude: longitude,
			},
		});
	};

export const userPreferedCoffeeShopCoordinatePushArray =
	(name, visinity, latitude, longitude, place_id, locationPreferenecId, preference, preferenceId) => async (dispatch) => {
		dispatch({
			type: actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH,
			payload: {
				name: name,
				visinity: visinity,
				latitude: latitude,
				longitude: longitude,
				placeId: place_id,
			},
		});
	};

export const inviteePreferedCoffeeShopCoordinatePushArray =
	(name, visinity, latitude, longitude, place_id, locationPreferenecId, preference, preferenceId) => async (dispatch) => {
		dispatch({
			type: actionTypes.INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_PUSH,
			payload: {
				name: name,
				visinity: visinity,
				latitude: latitude,
				longitude: longitude,
				placeId: place_id,
				locationPreferenecId: locationPreferenecId,
			},
		});
	};

export const userPreferedCoffeeShopCoordinateRemoveArray =
	( latitude) => async (dispatch) => {

		dispatch({
			type: actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE,
			payload: {
				latitude: latitude,
			},
		});
	};

export const userPreferedCoffeeShopCoordinateRemoveAllArray =
	( ) => async (dispatch) => {

		dispatch({
			type: actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE_ALL,
		});
	};

export const inviteePreferedCoffeeShopCoordinateRemoveArray =
	( ) => async (dispatch) => {

		dispatch({
			type: actionTypes.INVITEE_PREFERED_COFFEE_SHOP_CO_ORDINATE_REMOVE,
		});
	};

export const userPreferedCoffeeShopCoordinateReset =
	() => async (dispatch) => {

		dispatch({
			type: actionTypes.USER_PREFERED_COFFEE_SHOP_CO_ORDINATE_RESET,
		});
	};

