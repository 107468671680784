import * as React from 'react';
import { end_points } from '../constant';
import { Axios } from '../services';
import { actionTypes } from '../types'
import organizerAndUserCoordinateUpdateReducer from '../reducers/organizerAndUserCoordinateUpdateReducer';

export const navigationRef = React.createRef();

export const registerInvitees = (payload) => async (dispatch) => {
	// alert(JSON.stringify(payload))
	// initiate api call and loader
	dispatch({
		type: actionTypes.REGISTER_INVITEE_REQUEST,
	});

	await Axios(
		"get",
		end_points.register_invitees + payload?.meetingHashCode,
		// end_points.register_invitees + payload?.meetingHashCode+`/${payload?.latitude}/${payload?.longitude}`,
		// end_points.register_invitees + payload.meetingHashCode,
		null,
		"application/json",
		true
	)
	.then((res) => {
		console.log(`HELLO ${end_points.register_invitees + payload.meetingHashCode} Success response:`, res?.data);
		if(res.data){
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.REGISTER_INVITEE_SUCCESS,
				payload: res?.data,
			});
			// if(res?.data?.status === 200){
			// 	navigationRef.current?.navigate("InviteeTimeSelectionScreen", { meetingId: res?.data?.response?.meetingId, meetTitle: res?.data?.response?.title, isAttendee: true })
			// }
		}
		// alert(JSON.stringify(res))
	})
	.catch((error) => {
		// alert(JSON.stringify(error))
		console.log(`${end_points.register_invitees + payload.meetingHashCode} Error response:`, error?.response?.data);
		if (error.response) {
			// stop loading  and success API response dispatching to reducer
			dispatch({
				type: actionTypes.REGISTER_INVITEE_FAILED,
				payload: error?.response?.data,
			});
		}

	})
};

export const registerInviteesReset = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.REGISTER_INVITEE_RESET,
	});
};

export const registerInviteesResetAll = () => async (dispatch) => {
	// reducer state resetting to initial state
	dispatch({
		type: actionTypes.REGISTER_INVITEE_RESET_ALL,
	});
};
