import { AddToCalendarButton } from 'add-to-calendar-button-react'
import React from 'react'
import { useNavigate } from 'react-router-dom';

function AddToCalendarModal({ setIsPopUpOpenCancel, meetingData, platform }) {

    const navigate = useNavigate();


    const renderCalender = () => {

        // console.log("meetingData", meetingData);
        return (
            <button onClick={() => navigate("/dashboard")}>
                <AddToCalendarButton
                    name={meetingData?.title}
                    startDate={meetingData?.meetingDate}
                    options={platform === "Android" || platform === "WEB" ? 'Google' : platform === "Ios" ? ['Apple', 'Google'] : 'Google'}
                    timeZone="America/Chicago"
                    startTime={meetingData?.startTime?.split(":")[0] + ":" + meetingData?.startTime?.split(":")[1]}
                    endTime={meetingData?.endTime?.split(":")[0] + ":" + meetingData?.endTime?.split(":")[1]}
                    endDate={meetingData?.finalDate}
                    created={() => navigate("dashboard")}
                ></AddToCalendarButton>
            </button>
        )
    }


    return (
        <div className="rounded-[19px] md:flex md:space-y-0 md:space-x-4 absolute z-10 w-full top-0 bottom-0 p-4 bg-black bg-opacity-60">
            <div className="relative w-full max-w-2xl max-h-full mt-[40%]">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-start justify-between p-4">
                        <div className="text-xl font-semibold text-gray-900 dark:text-light-primary-alt">
                            Do you want to add this event to your calendar?
                        </div>
                        <button type="button" onClick={() => { setIsPopUpOpenCancel(false); navigate("/dashboard") }} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:rounded-lg text-sm w-5 h-2 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="flex justify-between items-center p-6 space-x-2">
                        <button type="button" onClick={() => { setIsPopUpOpenCancel(false); navigate("/dashboard") }} className="text-primary bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-2xl text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Close</button>
                        {renderCalender()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddToCalendarModal